import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DISPLAY_COLUMN } from '@features-candidat/projet-creation/component/projet-info-depenses/projet-info-depenses-synthese/depenses-projet-table/table-depenses.constant';
import {
  ConfirmModalComponent,
  DepensesData,
  DisplayColumn,
  LotLightModel,
  NoteDepensesProjet,
  SubscriptionDestroyerComponent,
  TableCell,
} from '@shared-ui';
import { take } from 'rxjs/operators';
import { ProjetInfoDepensesSharedService } from '../../projet-info-depenses-shared.service';

@Component({
  selector: 'pxl-depenses-projet-table',
  templateUrl: './depenses-projet-table.component.html',
  styleUrls: ['./depenses-projet-table.component.scss'],
})
export class DepensesProjetTableComponent extends SubscriptionDestroyerComponent implements OnInit {
  @Input() set tachesByLots(lotsLights: LotLightModel[]) {
    this.tachesByLotsChange(lotsLights);
    this.dataTable = { lotsLights: this.tacheByLots, columns: this.columns, updated: this.deleteUpdate };
    this.deleteUpdate = false;
  }

  @Input() set showOngletTab(value: boolean) {
    this.applyFilter(value ? this.filter[0] : '');
    this.showOngletOrListTab(value);
  }

  @Input() readOnly: boolean;

  @Output() lotsTachesChanges: EventEmitter<{
    isSideNav: boolean | undefined;
    lots: LotLightModel[];
  }> = new EventEmitter<{
    isSideNav: boolean | undefined;
    lots: LotLightModel[];
  }>();

  columns: DisplayColumn[];
  checkboxList: DisplayColumn[] = [];
  disColumns!: string[];
  isFullScreen = false;
  docElement: HTMLElement;
  filter: string[] = [];
  selected: string[] = [];
  afficherOnglets: boolean;

  private lotUpdated = false;
  private filterColumnsMap: Map<string, any[]> = new Map();
  public projetId: string;
  public filterEnable = false;

  public filterActive: string[] = [];
  public tachesForm: FormGroup;
  public tacheByLots: LotLightModel[] = [];
  public spans = [];
  public newCommentaire: { index: number; columnName: string; depensesData: DepensesData<number | undefined> };
  public dataTable: { lotsLights: LotLightModel[]; columns: DisplayColumn[]; updated: boolean };
  public deleteUpdate = false;

  constructor(
    private fb: FormBuilder,
    public matDialog: MatDialog,
    public sharedService: ProjetInfoDepensesSharedService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.projetId = this.route.snapshot.parent?.params.id;
    this.updateCommentaire();
    this.getIsSyntheseFormValid();

    this.sharedService.getCommentaireToSave().subscribe(
      data =>
        (this.newCommentaire = {
          index: data?.index,
          columnName: data?.columnName,
          depensesData: data?.depenseData,
        })
    );
  }

  private tachesByLotsChange(lotsLights: LotLightModel[]): void {
    this.filterEnable = false;
    if (this.selected.length === 0 || this.lotUpdated) {
      this.tacheByLots = lotsLights;
      if (!this.lotUpdated) {
        this.initColumns();
      }
      this.initDataSource();
      this.getIsSyntheseFormValid();
    }

    if (this.lotUpdated) {
      this.hideLotByDisplayType(false);
    }
    this.lotUpdated = false;
  }

  private showOngletOrListTab(value: boolean): void {
    this.afficherOnglets = value;
    this.displayColumns();
    this.hideLotByDisplayType(!value);
    if (value) {
      // true onglet
      this.filterEnable = true;
    } else {
      // liste
      this.filterEnable = false;
      this.filterActive = [];
      this.filterColumnsMap.clear();
      this.onFilterChange({ columnKey: 'lot', filterValue: [] });
    }
  }

  private initColumns(): void {
    this.columns = [...DISPLAY_COLUMN];
    this.tacheByLots[0]?.ec.map((etape, index) => this.addEcColumns(index));
  }

  private initDataSource(): void {
    this.tachesForm = this.fb.group({ taches: this.fb.array([]) });
    this.tacheByLots.forEach(tache => this.feedFormArrays(tache));
    this.docElement = document.documentElement;
    this.setFilters();
  }

  private feedFormArrays(tache: LotLightModel): void {
    const row = { ...tache, ...tache.ec, structureName: tache.structureName };
    delete row['ec'];
    const form = this.tachesForm
      .get('taches')
      .value.filter(
        (f: { lot: number; indexTache: string; structureName: string }) =>
          f.lot === tache.lot.value && f.indexTache === tache.indexTache.value && f.structureName === tache.structureName.value
      );

    if (form.length === 0) {
      (<FormArray>this.tachesForm.get('taches')).push(this.fb.group(row));
    }
  }

  private addEcColumns(index: number): number {
    return this.columns.push({
      def: '' + index,
      label: 'EC ' + (index + 1),
      hide: false,
      mandatory: false,
      width: '160px',
      calculated: true,
    });
  }

  onChange(isSideNav?: boolean): void {
    this.lotsTachesChanges.emit({ isSideNav: isSideNav, lots: this.tachesForm.get('taches').value });
  }

  toggleForm(): void {
    this.checkboxList.length ? this.hideCheckboxes() : this.showCheckboxes();
  }

  showCheckboxes(): void {
    this.displayFilterCheckboxes(this.afficherOnglets);
  }

  hideCheckboxes(): void {
    this.checkboxList = [];
  }

  hideColumn(event: { checked: boolean }, item: string) {
    this.columns.forEach(element => {
      if (element.def === item) {
        element.hide = event.checked;
      }
    });
    this.displayColumns();
  }

  displayFilterCheckboxes(afficherOnglets: boolean): void {
    if (afficherOnglets) {
      this.checkboxList = this.columns.filter(displayCol => displayCol.def !== 'lot');
      this.columns.find(displayCol => displayCol.def === 'lot').hide = true;
    } else {
      this.checkboxList = this.columns;
      this.columns.find(displayCol => displayCol.def === 'lot').hide = false;
    }
  }

  displayColumns(): void {
    this.disColumns = this.columns.filter(cd => !cd.hide).map(cd => cd.def);
  }

  setFilters(): void {
    this.tacheByLots?.forEach((element: LotLightModel) => {
      this.filter.push(String(element.lot.value));
    });
    this.filter = Array.from(new Set(this.filter));
  }

  hideLotByDisplayType(afficherOnglets: boolean): void {
    this.columns.forEach(displayCol => {
      if (displayCol.def === 'lot') {
        displayCol.hide = afficherOnglets;
        if (!afficherOnglets) {
          this.disColumns = this.disColumns.filter(cd => cd !== 'lot');
        } else if (!this.disColumns.includes('lot')) {
          this.disColumns.unshift('lot');
        }
      }
    });
  }

  toggleFullScreen(): void {
    const elem = document.getElementsByClassName('table-container')[0] as HTMLElement;
    if (!this.isFullScreen) {
      elem.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }

  applyFilter(lot: string): void {
    this.filterEnable = true;
    this.selected = [lot];
    this.onFilterChange({ columnKey: 'lot', filterValue: [lot] });
  }

  isActive(lot: string): boolean {
    return this.selected.includes(lot);
  }

  onDeleteElement(lot: string): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: 'Suppression du lot ' + lot,
        description: `<p>En confirmant l'action, ce lot va être supprimée. Confirmez vous l'action ?</p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      const lotIndex = this.filter.indexOf(lot);
      if (lotIndex !== -1) {
        this.filter.splice(lotIndex, 1);
      }

      this.applyFilter(this.filter[0]);
      const taches = this.tachesForm.get('taches').value.filter((tache: LotLightModel) => tache.lot.value !== Number(lot));
      this.lotUpdated = true;
      this.lotsTachesChanges.emit({ isSideNav: true, lots: taches });
    });
    this.deleteUpdate = true;
  }

  saveLot(lot: string): void {
    this.selected.push(lot);
  }

  onFilterChange($event: { columnKey: string; filterValue: string[] }) {
    this.filterColumnsMap.set($event.columnKey, $event.filterValue);
    this.filterEnable = $event.filterValue.length !== 0;
    this.filterColumnsMap.forEach((value, key) => {
      this.filterActive.push(key);
      this.filterActive = Array.from(new Set(this.filterActive));
      if (value.length === 0) {
        this.filterActive = this.filterActive.filter(filter => filter !== key);
      }
    });
  }

  openSideNav(event: { columnName: string; index: number; editable: boolean; defNote: string }): void {
    if (event.defNote.includes('Description')) {
      this.sharedService.setIsShowing(true);
      const data = this.tachesForm?.get('taches')?.value[event.index][event.columnName];
      this.sharedService.setCommentaireToDisplay({
        depenseData: {
          value: data?.value,
          note: data?.metaData,
        },
        columnName: event.columnName,
        index: event.index,
      });
    } else {
      this.sharedService.setIsShowing(false);
    }
  }

  updateCommentaire(): void {
    this.sharedService.getCommentaireToSave().subscribe({
      next: toSave => {
        if (toSave) {
          this.updateFormArray(toSave);
        }
      },
    });
  }

  private updateFormArray(toSave: {
    index: number;
    depenseData: { note: NoteDepensesProjet | undefined; value: number };
    columnName: string;
  }) {
    (this.tachesForm?.controls?.taches as FormArray).controls[toSave.index]
      .get(toSave.columnName)
      ?.setValue(new TableCell(toSave.depenseData.value, false, false, false, false, toSave.depenseData.note));
    this.lotUpdated = false;
    this.onChange(true);
  }

  private getIsSyntheseFormValid(): void {
    this.sharedService
      .getIsSyntheseFormValid()
      .pipe(take(1))
      .subscribe((valid: boolean) => {
        if (valid) {
          this.tachesForm?.disable();
        } else {
          this.tachesForm?.enable();
        }
      });
  }

  lotChange($event: { isSideNav: boolean | undefined; lots: LotLightModel[] }) {
    this.lotsTachesChanges.emit($event);
  }

  lotName(lot: string) {
    return this.tacheByLots.find(tache => tache.lot.value === Number(lot)).lotName.value;
  }
}
