export enum EnumPermissionUtilisateur {
  PROJET_WRITE,

  PROGRAMME_ADD,
  PROGRAMME_UPDATE,
  PROGRAMME_DELETE,
  AAP_ADD,
  AAP_UPDATE,
  AAP_PROJET_APERCU_VALIDER,
  AAP_PROJET_APERCU_REJETER,

  PROJET_INFO_UPSERT,
  PROJET_INFO_UPLOAD_DOCUMENT,
  PROJET_CONSORT_ADD_STRUCT,
  PROJET_CONSORT_UPDATE_STRUCT,
  PROJET_CONSORT_DELETE_STRUCT,
  PROJET_CONSORT_STRUCT_ADD_CONTACT,
  PROJET_CONSORT_STRUCT_UPDATE_CONTACT,
  PROJET_CONSORT_STRUCT_DELETE_CONTACT,

  PROJET_DELETE_DOCUMENT,
  AAP_ADD_FILE,
  AAP_RECORD,
  AAP_ADD_AUTRESDATES,

  PROJET_EVALUATION_WRITE,
  PROJET_EVALUATION_WRITE_ALL,
  PROJET_EVALUATION_READ,
  PROJET_EVALUATION_READ_ALL,

  PROJET_AUDITION_WRITE,
  PROJET_AUDITION_WRITE_ALL,
  PROJET_AUDITION_READ,
  PROJET_AUDITION_READ_ALL,

  PROJET_DEMANDECORRECTION_WRITE,
  PROJET_DEMANDECORRECTION_READ,
  PROJET_DEMANDE_COMPLEMENT_WRITE,
  PROJET_DEMANDE_COMPLEMENT_READ,

  WKF_DEPOT,
  WKF_PREDEPOT,
  EXTRACTION_PROJET_PARTENAIRE,

  PROJET_VERROUILLAGE_READ,
  PROJET_VERROUILLAGE_WRITE,

  PROJET_ELIGIBILITE_READ,
  PROJET_ELIGIBILITE_WRITE,

  PROJET_CONTRACTUALISATION_READ,
  PROJET_CONTRACTUALISATION_WRITE,

  PROJET_INSTRUCTION_WRITE,
  PROJET_INSTRUCTION_READ,
  PROJET_INSTRUCTION_READ_ALL,

  STRUCTURE_WRITE_ALL,
  STRUCTURE_WRITE,
  FEATURE_FLIPPING,

  PXL_QLIK_READ,
  PROJET_GLOBAL_SEARCH = 'PROJET_GLOBAL_SEARCH',
}
