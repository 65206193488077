export enum EnumValidation {
  VALIDE = 'Validé' as any,
  INVALIDE = 'Invalidé' as any,
  NON_VALIDE = 'Non validé' as any,
  IGNORER = 'Ignoré' as any,
  VERROUILLAGE_A_FAIRE = 'A faire' as any,
  VERROUILLAGE_FAIT = 'Fait' as any,
  A_FAIRE = 'A faire' as any,
  // FIXME: This is a typo, but it's used in the codebase and database, used invisible character to differentiate
  FAIT = 'Faite‎' as any,
  FAITE = 'Faite' as any,
}
