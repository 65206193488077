import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { EvaluateurService } from '@services-candidat/evaluateur.service';
import { ProjetService } from '@services-candidat/projet.service';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import { Aap, EnumProjetEtape, EnumProjetStatut, Projet, ShowToastrService } from '@shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pxl-projet-list',
  templateUrl: './projet-list.component.html',
  styleUrls: ['./projet-list.component.scss'],
})
export class ProjetListComponent implements OnInit, OnDestroy {
  projets: Projet[] = [];
  prochaineReleves = new Map<string, string>();
  today: string;
  subscriptions: Subscription[] = [];
  aaps: Aap[];

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public projetService: ProjetService,
    private aapService: AapService,
    public datepipe: DatePipe,
    private showToastrService: ShowToastrService,
    private evaluateurService: EvaluateurService,
    public sharedFunction: SharedFunction
  ) {
    this.today = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  }

  ngOnInit(): void {
    this.recupereProjets();
    this.getAaps();
  }

  goToProjetParcours(): void {
    this.router.navigate(['projet-parcours']);
  }

  /*
   * Récupère la liste des projets en se servant du service
   * */
  recupereProjets(): void {
    this.subscriptions.push(
      this.projetService.getProjets().subscribe({
        next: (projets: HttpResponse<Projet[]>) => {
          this.projets = projets.body;
          if (this.projets?.length !== 0) {
            this.projets.forEach(projet => {
              this.calculProchaineReleve(projet);
            });
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      })
    );
  }

  getAaps(): void {
    this.subscriptions.push(
      this.aapService.getAap().subscribe(response => {
        if (response) {
          this.aaps = response.body;
        }
      })
    );
  }

  /**
   * Redirige vers la page evaluateur
   */
  redirectEvaluateur(): void {
    this.subscriptions.push(this.evaluateurService.goToEvaluateurPage());
  }

  /*
   * Cette méthode permet de calculer la prochaine relève de chaque projet cad
   * la futur date la plus proche de la date d'aujourd'hui
   *  */
  calculProchaineReleve(projet: Projet): void {
    // classement des dates par ordre croissant
    const datesAutres = projet.aap.dateAutres;
    datesAutres.sort((b, a) => {
      return (new Date(b.valeur) as any) - (new Date(a.valeur) as any);
    });
    if (datesAutres.length !== 0) {
      if (
        this.datepipe.transform(datesAutres[datesAutres.length - 1].valeur, 'yyyy-MM-dd') <
        this.datepipe.transform(new Date(), 'yyyy-MM-dd')
      ) {
        this.prochaineReleves.set(projet.id, this.datepipe.transform(datesAutres[datesAutres.length - 1].valeur, 'dd/MM/yyyy'));
      }
      // recupération de la furture date la plus proche
      else {
        for (const date of datesAutres) {
          if (this.datepipe.transform(new Date(date.valeur), 'yyyy-MM-dd') >= this.today) {
            this.prochaineReleves.set(projet.id, this.datepipe.transform(new Date(date.valeur), 'dd/MM/yyyy'));
            break;
          }
        }
      }
    } else {
      this.prochaineReleves.set(projet.id, '-');
    }
  }

  /*
   * Envoi sur la page des informations du projet - projet aperçu
   * */
  onGoToProjetApercu(projetId: string): void {
    this.router.navigate(['projet-creation/' + projetId + '/projet-apercu']);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }

  protected readonly EnumProjetEtape = EnumProjetEtape;
  protected readonly EnumProjetStatut = EnumProjetStatut;
}
