import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { ProjetInfoDomainesComponent } from '@features-candidat/projet-creation/component/projet-info-domaines/projet-info-domaines.component';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [ProjetInfoDomainesComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
})
export class ProjetInfoDomainesModule {}
