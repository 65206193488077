import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnumTypeDocument } from '@shared-ui';
import { UploadDocModalDataModel } from './model/upload-doc-modal-data.model';
import { UploadDocModalResultModel } from './model/upload-doc-modal-result.model';
import { UploadDocModalDocumentModel } from './model/upload-doc-modal-document.model';

@Component({
  selector: 'lib-select-upload-doc',
  templateUrl: './select-upload-doc.component.html',
  styleUrls: ['./select-upload-doc.component.scss'],
})
export class SelectUploadDocComponent {
  private static readonly VIDEO_EXTENSIONS = '.mp4, .wmv, .mov, .flv, .avi, .3gp';
  private static readonly DOC_EXTENSIONS = '.pdf, .xls, .xlsx, .doc, .docx, .ppt, .pptx';
  private static readonly CONNAISSANCE_CLIENT_EXTENSIONS = '.pdf, .gif, .png, .jpg, .jpeg, .bmp, .tif, .tiff, .msg';

  documentSelected: UploadDocModalDocumentModel | null = null;
  accept: string | undefined;

  constructor(
    public dialogRef: MatDialogRef<SelectUploadDocComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: UploadDocModalDataModel
  ) {}

  onSelected() {
    if (this.documentSelected?.connaissanceClient) {
      this.accept = SelectUploadDocComponent.CONNAISSANCE_CLIENT_EXTENSIONS;
    } else if (this.documentSelected?.mediaTypes?.includes(EnumTypeDocument.MEDIA_VIDEO)) {
      this.accept = SelectUploadDocComponent.VIDEO_EXTENSIONS;
    } else {
      this.accept = SelectUploadDocComponent.DOC_EXTENSIONS;
    }
  }

  onUpload(target: EventTarget | null | undefined): void {
    const files = (<HTMLInputElement>target)?.files;
    if (this.documentSelected && files) {
      const result: UploadDocModalResultModel = {
        document: this.documentSelected,
        files: files,
      };
      this.dialogRef.close(result);
    }
  }
}
