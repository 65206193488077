import {
  Aap,
  Contact,
  DocumentAapModel,
  DocumentProjet,
  EnumAapStatut,
  EnumMotifNonRattachement,
  EnumPermissionUtilisateur,
  EnumProjetEtape,
  EnumProjetStatut,
  EnumProjetTag,
  EnumRoleContact,
  EnumRoleStructure,
  EnumScope,
  EnumTypeWorkflow,
  MembreEquipe,
  Projet,
  Structure,
  Utilisateur,
} from '@shared-ui';
import { NgxPermissionsObject } from 'ngx-permissions';


export class SharedFunction {
  getProjectEtapeName(projet: Projet): EnumProjetEtape {
    if (projet?.etapes) {
      return projet.etapes[projet.etapes.length - 1]?.nom;
    }
    return null;
  }

  isUserInStructureCandidatContacts(projet: Projet, utilisateur: Utilisateur): boolean {
    return projet.structures
      .find(struct => struct.role === EnumRoleStructure.CANDIDAT)
      ?.contacts?.some(contact => !contact.inactif && contact.matricule === utilisateur.matricule);
  }

  isStructureNotMandantaireAndHasUserInContacts(structure: Structure, utilisateur: Utilisateur): boolean {
    const contactMatch = structure?.contacts.filter(contact => !contact.inactif && contact.matricule === utilisateur.matricule);
    return contactMatch?.length > 0 && structure.role !== EnumRoleStructure.MANDATAIRE;
  }

  isUserMandataire(projetId: string, utilisateur: Utilisateur): boolean {
    return this.isUserHasRole(projetId, utilisateur, 'MANDATAIRE');
  }

  isUserHasRole(projetId: string, utilisateur: Utilisateur, role: string): boolean {
    return utilisateur.permissions.some(permission => permission.idProjet === projetId && permission.role === role);
  }

  /**
   * Vérifie si le projet même (section projet) peut être modifiée : doit être dans un état modifiable ou à corriger
   * @param aap AAP lié au projet
   * @param projet Projet qui va être modifié
   * @returns Boolean
   */
  isProjectUpdatable(aap: Aap, projet: Projet): boolean {
    return this.isProjectInUpdatableState(aap, projet) || projet.tag === EnumProjetTag[EnumProjetTag.A_CORRIGER.toString()];
  }

  /**
   * Vérifie si le projet en entier (projet + structure) est dans un état où il peut être modifié par un utilisateur (aap non cloturé et projet en cours)
   * @param aap AAP lié au projet
   * @param projet Projet qui va être modifié
   * @returns Boolean
   */
  isProjectInUpdatableState(aap: Aap, projet: Projet): boolean {
    return (
      (aap.statut === EnumAapStatut.OUVERT || this.isProjectDepotInAapFermeWKF1(aap, projet)) &&
      projet.statut === EnumProjetStatut.EN_COURS &&
      ![EnumProjetEtape.INSTRUCTION, EnumProjetEtape.CONTRACTUALISATION].includes(this.getProjectEtapeName(projet))
    );
  }

  isProjectDepotInAapFermeWKF1(aap: Aap, projet: Projet): boolean {
    if (aap == null || projet?.etapes == null) {
      return false;
    }

    return (
      projet.etapes[projet.etapes.length - 1].nom === EnumProjetEtape.DEPOT &&
      projet.etapes[projet.etapes.length - 1].statut === EnumProjetStatut.EN_COURS &&
      aap.statut === EnumAapStatut.FERME &&
      aap.typeWKF === EnumTypeWorkflow.WKF1
    );
  }

  isProjectUpdatableByUser(aap: Aap, projet: Projet, userPermissions: NgxPermissionsObject): boolean {
    return this.isProjectUpdatable(aap, projet) && userPermissions?.PROJET_WRITE != null;
  }

  isProjetCanBeModifiedAfterDeposited(aap: Aap, projet: Projet): boolean {
    return aap.statut === EnumAapStatut.OUVERT && projet.statut === EnumProjetStatut.ENVOYE;
  }

  /**
   * Determine si l'utilisateur a le droit de modifier une structure
   * @param structure Structure à modifier
   * @param utilisateur utilisateur qui veut modifier la structure
   * @param userPermissions permissions de l'utilisateur
   * @param projet projet lié à la structure
   * @returns Boolean
   */
  checkConsortiumPermission(
    structure: Structure,
    utilisateur: Utilisateur,
    userPermissions: NgxPermissionsObject,
    projet?: Projet
  ): boolean {
    if (structure.tag === EnumProjetTag[EnumProjetTag.A_CORRIGER.toString()]) {
      return this.checkConsortiumPermissionCorrection(structure, utilisateur, projet);
    }

    if (structure?.closed) {
      return false;
    }

    if (userPermissions?.STRUCTURE_WRITE_ALL) {
      return true;
    }

    if (userPermissions?.STRUCTURE_WRITE) {
      return structure?.contacts.some(contact => !contact.inactif && contact.matricule === utilisateur.matricule);
    }

    return false;
  }

  checkConsortiumPermissionCorrection(structure: Structure, utilisateur: Utilisateur, projet?: Projet): boolean {
    const isMandataire = utilisateur.permissions.find(
      permission => permission.idProjet === projet.id && permission.role === EnumRoleStructure.MANDATAIRE
    );
    return isMandataire != null || structure?.contacts?.some(contact => !contact.inactif && contact.matricule === utilisateur.matricule);
  }

  isStructureUpdatableByUser(
    aap: Aap,
    projet: Projet,
    structure: Structure,
    utilisateur: Utilisateur,
    userPermissions: NgxPermissionsObject
  ): boolean {
    return (
      (this.isProjectInUpdatableState(aap, projet) || structure?.tag === EnumProjetTag[EnumProjetTag.A_CORRIGER.toString()]) &&
      this.checkConsortiumPermission(structure, utilisateur, userPermissions, projet)
    );
  }

  /**
   *
   * Vérifie si le responsable projet des contacts de la structure est le même que le responsable projet déclarer dans les membres d'équipe
   */
  checkEquipeAndContactHasValidResponsable(structure: Structure): boolean {
    if (!structure.contacts?.length || !structure.equipe?.length) {
      return true;
    }
    const responsableContact = structure.contacts.find(
      contact => !contact.inactif && contact.roles?.indexOf(EnumRoleContact[EnumRoleContact.RESPONSABLE_PROJET.toString()]) !== -1
    );
    const responsableEquipe = structure.equipe.find(e => !!e.identite?.responsableProjet);
    if (!responsableContact || !responsableEquipe) {
      return true;
    }
    return (
      responsableContact.nom?.toLocaleLowerCase() === responsableEquipe.identite?.nom?.toLocaleLowerCase() &&
      responsableContact.genre?.toLocaleLowerCase() === responsableEquipe.identite?.genre?.toLocaleLowerCase() &&
      responsableContact.prenom?.toLocaleLowerCase() === responsableEquipe.identite?.prenom?.toLocaleLowerCase()
    );
  }

  /* Vérifie si les structures sont complètes
   * */
  checkIncompleteCompanies(structures: Structure[], projet: Projet, aap: Aap): Structure[] {
    return structures.filter(structure => {
      return this.checkIncompleteCompany(structure, projet, aap);
    });
  }

  /* Vérifie si la structure est incomplète
   * */
  checkIncompleteCompany(structure: Structure, projet: Projet, aap: Aap): boolean {
    if (structure.closed) {
      return false;
    }

    if (structure.role !== EnumRoleStructure.MANDATAIRE) {
      if (
        (this.getProjectEtapeName(projet) === EnumProjetEtape.PRE_DEPOT &&
          !aap.budgetEstime &&
          structure.budgetPreDepot.besoin !== false &&
          !structure.budgetPreDepot.montant) ||
        (this.getProjectEtapeName(projet) === EnumProjetEtape.DEPOT &&
          structure.budgetDepot.besoin !== false &&
          !structure.budgetDepot.montant)
      ) {
        return true;
      }
    }

    if (structure.raisonSiret !== EnumMotifNonRattachement.EN_COURS_DE_CREATION && (!structure.adresse || !structure.adresse?.cp)) {
      return true;
    }

    if (!structure?.lieuRD?.raisonSocial && structure.role !== EnumRoleStructure.MANDATAIRE) {
      return true;
    }

    return this.checkIncompleteContacts(structure, aap);
  }

  checkIncompleteContacts(structure: Structure, aap: Aap): boolean {
    if (structure.role === EnumRoleStructure.MANDATAIRE) {
      return this.checkIncompleteContactsMandataire(structure);
    } else {
      if (
        aap.equipePresent &&
        aap.equipeObligatoire &&
        (!this.checkExistanceResponsableProjetEquipe(structure) ||
          !this.checkEquipeAndContactHasValidResponsable(structure) ||
          !this.checkAllMembreEquipeValid(structure.equipe))
      ) {
        return true;
      }
      return this.checkMissingRlRpRa(structure);
    }
  }

  checkMissingRlRpRa(structure: Structure): boolean {
    let rl = false;
    let rp = false;
    let ra = false;
    return !structure.contacts?.some(contact => {
      if (!this.isContactValid(contact)) {
        return false;
      }
      if (contact.roles?.length > 0) {
        contact.roles.forEach(role => {
          if (role === EnumRoleContact[EnumRoleContact.REPRESENTANT_LEGAL.toString()] && contact.paysNaissance) {
            rl = true;
          }
        });

        if (contact.roles.indexOf(EnumRoleContact[EnumRoleContact.RESPONSABLE_PROJET.toString()]) !== -1) {
          rp = true;
        }

        if (contact.roles.indexOf(EnumRoleContact[EnumRoleContact.RESPONSABLE_ADMINISTRATIF.toString()]) !== -1) {
          ra = true;
        }
      }
      return rl && rp && ra;
    });
  }

  checkIncompleteContactsMandataire(structure: Structure): boolean {
    if (structure.contacts?.length > 0) {
      for (const contact of structure.contacts) {
        if (!this.isContactValid(contact)) {
          return true;
        }
      }
    } else {
      return true;
    }

    // return false si contact complet
    return false;
  }

  /* Vérifie si le contact est complet
   * */
  isContactValid(contact: Contact): boolean {
    return Boolean(contact.nom && contact.prenom && contact.telephone && contact.email && contact.genre);
  }

  checkExistanceResponsableProjetEquipe(structure: Structure): boolean {
    return structure.equipe?.findIndex(e => !!e.identite?.responsableProjet) > -1;
  }

  checkAllMembreEquipeValid(equipe: MembreEquipe[]): boolean {
    return !!equipe?.every(value => this.isMembreEquipeValid(value));
  }

  /*
   * renvoie true si le membre d'équipe est complet
   * */
  isMembreEquipeValid(membre: MembreEquipe): boolean {
    return Boolean(
      membre.identite?.nom &&
        membre.identite?.prenom &&
        membre.identite?.genre &&
        membre.identite?.dateNaissance &&
        membre.infosProjet?.roleEquipe &&
        membre.formation?.niveauFormation &&
        membre.idDocumentCV
    );
  }

  getDocStructureAap(aap: Aap, structure: Structure, projet: Projet): DocumentAapModel[] {
    return aap.documentAaps?.filter(document => {
      if (
        document.etapes.includes(this.getProjectEtapeName(projet)) &&
        document.typePartenaires?.includes(projet.partenaireType) &&
        document.scope === EnumScope.STRUCTURE
      ) {
        const roleMatching = document.roleStructures.filter(role => {
          return structure.role === role;
        });
        const typeStructMatching = document.typeStructures.filter(type => {
          return structure.typeStructure === type;
        });

        if (roleMatching.length > 0 && typeStructMatching.length > 0) {
          return document;
        }
      }
    });
  }

  getDocActioStructureAap(aap: Aap, structure: Structure, projet: Projet): DocumentAapModel[] {
    return aap.documentAaps?.filter(document => {
      if (
        document.etapes.includes(this.getProjectEtapeName(projet)) &&
        document.typePartenaires?.includes(projet.partenaireType) &&
        document.scope === EnumScope.STRUCTURE_ACTIONARIAL
      ) {
        const roleMatching = document.roleStructures.filter(role => {
          return structure.role === role;
        });
        const typeStructMatching = document.typeStructures.filter(type => {
          return structure.typeStructure === type;
        });

        if (roleMatching.length > 0 && typeStructMatching.length > 0) {
          return document;
        }
      }
    });
  }

  getDocumentName(document: DocumentProjet): string {
    if (document.nom == null) {
      return 'Masqué';
    } else {
      return document.nom;
    }
  }

  isDemandeRectificationExist(structure: Structure): boolean {
    return structure && structure.adresse?.demandeRectification != null;
  }

  isDemandeRectificationTreated(structure: Structure): boolean {
    return structure && structure.adresse?.demandeRectification && structure.adresse.demandeRectification.demandeTraitee === true;
  }

  /**
   * Fonction déterminant si l'utilisateur a les permissions voulues sur le projet passé en paramètre
   * @param user Utilisateur
   * @param permissions array de permissions à retrouver chez l'utilisateur
   * @param projetId Id du projet concerné
   * @returns boolean indiquant si l'utilisateur a les permissions voulues sur le projet
   */
  userHasOneOfPermissionsInProject(user: Utilisateur, permissions: EnumPermissionUtilisateur[], projetId: string): boolean {
    return (
      user?.permissions.find(perm => {
        return (
          perm?.idProjet === projetId &&
          perm?.privileges.find(privilege => permissions.includes((EnumPermissionUtilisateur as any)[privilege.nomPrivilege.toString()])) !=
            null
        );
      }) != null
    );
  }
}
