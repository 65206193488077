import { Country } from '@angular-material-extensions/select-country';
import { EnumCirconstanceExceptionnelle } from '../enums/enum.circonstance-exceptionnelle';
import { EnumOperationFinanceeObjet } from '../enums/enum.operation-financee-objet';
import { EnumProduitFinancement } from '../enums/enum.produit-financement';
import { FicheFlaminemInterface } from '../interfaces/fiche-flaminem.interface';
import { DocumentConnaissanceClientRequest } from './document-connaissance-client-request.model';
import { EnumStatutFicheFlaminem } from "../enums/enum.statut-fiche-flaminem";

export class FicheFlaminem implements FicheFlaminemInterface {
  idKLF: string;
  commentaire: string;
  statut: EnumStatutFicheFlaminem;
  reference: string;
  numeroDossierCommercial: string;
  produits: EnumProduitFinancement[] = [];
  operations: EnumOperationFinanceeObjet[] = [];
  paysOperation: string;
  paysOperationCountry?: Country;
  montantAide: number;
  circonstancesExceptionnelles: EnumCirconstanceExceptionnelle[];
  circonstancesExceptionnellesValue: EnumCirconstanceExceptionnelle;
  documents: DocumentConnaissanceClientRequest[] = [];
}
