import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  @Input() title!: string;
  @Input() subTitle?: string;
  @Input() goBackUrl?: string;
  @Input() goBackLabel?: string;

  constructor(private router: Router) {}

  goBack() {
    this.router.navigate([this.goBackUrl]);
  }
}
