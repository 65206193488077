import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared-candidat/shared.module';
import { StepTypePartenaireComponent } from './step-type-partenaire.component';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: [StepTypePartenaireComponent],
  exports: [StepTypePartenaireComponent],
  providers: [],
})
export class StepTypePartenaireModule {}
