import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { ProjetInfoPolesComponent } from './projet-info-poles.component';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [ProjetInfoPolesComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
})
export class ProjetInfoPolesModule {}
