import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Environment } from '../models/environment.model';
import { DocumentProjet } from '@shared-ui';

@Injectable()
export class ProjetService {
  constructor(@Inject('environment') private environment: Environment, public httpClient: HttpClient) {}

  deleteDocument(idProjet: string, idDocument: string, poleId?: string): Observable<object> {
    const documentUrl = this.environment.apiUrl + 'documents';
    let queryPole = '';
    if (poleId) {
      queryPole = '&poleId=' + poleId;
    }
    return this.httpClient.delete(documentUrl + '/' + idDocument + '?projetId=' + idProjet + queryPole, { observe: 'response' });
  }

  createDocumentProjet(document: DocumentProjet): Observable<HttpResponse<DocumentProjet>> {
    const documentUrl = this.environment.apiUrl + 'documents';
    return this.httpClient.post<DocumentProjet>(documentUrl, JSON.stringify(document), {
      observe: 'response',
    });
  }
}
