import { HttpErrorResponse, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { DemandeDeDocumentComplementaireService } from '@services-candidat/demandeDeDocumentComplementaire.service';
import { DepensesService } from '@services-candidat/depenses.service';
import { PoleService } from '@services-candidat/pole.service';
import { ProjetService } from '@services-candidat/projet.service';
import { polesMocks } from '@services-candidat/stub/mocks/poles.mocks';
import { UploadDocumentService } from '@services-candidat/upload-document.service';
import { UserService } from '@services-candidat/user.service';
import { ConfirmModalComponent } from '@shared-candidat/components/modals/confirm-modal/confirm-modal.component';
import { URL_PUBLIC_DOC } from '@shared-candidat/utils/constants';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  Aap,
  DemandeComplements,
  DocumentAapModel,
  DocumentHelper,
  DocumentProjet,
  Domaine,
  EnumDocumentComplementaireType,
  EnumDocumentProjetType,
  EnumProjetEtape,
  EnumRoleStructure,
  EnumScanDocument,
  EnumScope,
  EnumTypeDocument,
  EnumTypePartenaire,
  EnumValidation,
  DownloadUtils,
  FILE_SIZE,
  FicheComHelperService,
  GrilleImpactHelperService,
  GrilleImpactHttpService,
  LETTRE_LABELISATION,
  Projet,
  ProjetValidationFunction,
  Secteur,
  SelectUploadDocComponent,
  ShowToastrService,
  SignedUrlResponseModel,
  StatutGrilleImpacts,
  Structure,
  SubscriptionDestroyerComponent,
  Thematique,
  UploadDocModalDocumentModel,
  UploadDocModalResultModel,
  UploadDocumentHttpService,
  Utilisateur,
  VIDEO_SIZE,
} from '@shared-ui';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Observable, Subscription, forkJoin, of, switchMap, tap } from 'rxjs';
import { catchError, concatMap, filter, finalize, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-projet-info',
  templateUrl: './projet-info.component.html',
  styleUrls: ['./projet-info.component.scss'],
})
export class ProjetInfoComponent extends SubscriptionDestroyerComponent implements OnInit, OnDestroy {
  readonly DEFAULT_FILE_TYPES = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
    'application/vnd.ms-excel',
    'video/mp4',
    'video/x-ms-wmv',
    'video/quicktime',
    'video/avi',
    'video/3gpp',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
  ];

  readonly CONNAISSANCE_CLIENT_FILE_TYPES = [
    'application/pdf', // .pdf
    'image/gif', // .gif
    'image/png', // .png
    'image/jpeg', // .jpg, .jpeg
    'image/bmp', // .bmp
    'image/tiff', // .tif, .tiff
    'application/vnd.ms-outlook', // .msg
  ];

  readonly FILE_SIZE_LIMIT = FILE_SIZE;
  readonly VALIDE = (EnumValidation as any)[EnumValidation.VALIDE.toString()];

  dossier: string;
  subscriptions: Subscription[] = [];
  projet: Projet = new Projet();
  aap: Aap = new Aap();
  inputType = 'file';
  fileTooBig = false;
  videoTooBig = false;
  fileNotSupported = false;
  fileComplTooBig = false;
  fileComplNotSupported = false;
  connaissanceClientFileNotSupported = false;
  connaissanceClientFileComplNotSupported = false;
  showDiv = false;
  allowDownload = false;
  listDoc = [];
  listDocumentProjet: DocumentProjet[] = [];
  listDocuments: DocumentProjet[] = [];
  demandesComplements: DemandeComplements[] = [];
  demandesComplementsEncours: DemandeComplements[] = [];
  documentsComplementaires: DocumentProjet[] = [];
  isDocumentsComplementairesInvalid = false;
  remainingDocumentsComplementaires: UploadDocModalDocumentModel[] = [];
  etapeProjet: EnumProjetEtape;
  userPermissions: NgxPermissionsObject;
  utilisateur: Utilisateur;
  projetId: string;

  structureList: Structure[];
  declaredBudgetSum = 0;

  poles = polesMocks;
  listDocumentPoles: any[] = [];
  documentPole: DocumentProjet = new DocumentProjet();

  isKpiProjetNeeded = false;
  isGrilleImpactsValid: boolean;

  isExpensesValid = false;
  isFicheComValid = false;

  secteurs: Array<Secteur> = [];
  secteurSelected = false;
  domaines: Array<Domaine> = [];
  thematique: Thematique;

  isUploading = false;

  // Permissions
  isProjectUpdatableByUser: boolean;
  isGrilleImpactUpdatable = false;

  EnumProjetEtape = EnumProjetEtape;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public projetService: ProjetService,
    private depensesService: DepensesService,
    private poleService: PoleService,
    public uploadDocumentService: UploadDocumentService,
    private uploadDocumentHttpService: UploadDocumentHttpService,
    private downloadUtils: DownloadUtils,
    private showToastrService: ShowToastrService,
    private aapService: AapService,
    public userService: UserService,
    public sharedFunction: SharedFunction,
    private permissionsService: NgxPermissionsService,
    private dialog: MatDialog,
    private grilleImpactHttpService: GrilleImpactHttpService,
    private demandeComplementService: DemandeDeDocumentComplementaireService,
    public projetValidationFunction: ProjetValidationFunction
  ) {
    super();
  }

  ngOnInit(): void {
    this.projetId = this.route.snapshot.parent.params.id;
    this.getProjetIfExist();
    this.getProjetDocuments();
    this.getExpensesStatus();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.isUploading;
  }

  /*
   * Cette méthode récupère le projet si l'id est renseigné.
   * */
  getProjetIfExist(): void {
    if (!this.projetId) {
      return;
    }

    this.subscriptions.push(
      this.projetService
        .getProjetById(this.projetId)
        .pipe(
          concatMap(responseProjet => {
            this.projet = responseProjet?.body;
            this.getProjetStructures(this.projet);

            this.subscriptions.push(
              this.userService.getUserObservable().subscribe(response => {
                if (response) {
                  this.utilisateur = response;
                  this.userPermissions = this.permissionsService.getPermissions();
                }
              })
            );

            this.isFicheComValid = FicheComHelperService.isFicheComComplete(this.projet);
            this.showDiv = this.checkProjetInfo();
            this.secteurs = this.projet.secteurs;
            this.domaines = this.projet.domaines;
            this.thematique = this.projet.thematique;
            return this.aapService.loadAapSubject();
          })
        )
        .subscribe({
          next: responseAap => {
            this.aap = responseAap?.body;
            this.dossier = this.aap.lienDocumentation;

            this.isKpiProjetNeeded = this.aap.kpis?.filter(kpi => kpi.scope === 'PROJET').length > 0;

            if (this.isKpiProjetNeeded) {
              this.getProjetKpiStatus();
            }

            this.etapeProjet = this.sharedFunction.getProjectEtapeName(this.projet);
            this.listDoc = this.aap.documentAaps?.filter(document => {
              if (
                document.etapes.includes(this.etapeProjet) &&
                document.typePartenaires?.includes(this.projet.partenaireType) &&
                document.scope === EnumScope.PROJET
              ) {
                return document;
              }
            });
            this.listDoc.sort((a, b) => (a.nomDocument > b.nomDocument ? 1 : -1));
            this.isProjectUpdatableByUser = this.sharedFunction.isProjectUpdatableByUser(this.aap, this.projet, this.userPermissions);
            this.isGrilleImpactUpdatable = !this.sharedFunction.isUserInStructureCandidatContacts(this.projet, this.utilisateur);
            if (!this.isProjectUpdatableByUser) {
              this.inputType = 'none';
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        })
    );
  }

  getProjetStructures(projet: Projet): void {
    this.structureList = projet.structures.filter(structure => structure.role !== EnumRoleStructure.MANDATAIRE && !structure.closed);
    this.structureList.sort((a, b) => (a.raisonSocial.toLocaleLowerCase() > b.raisonSocial.toLocaleLowerCase() ? 1 : -1));
    this.declaredBudgetSum = 0;
    this.structureList.forEach(structure => {
      if (this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT && !this.aap.budgetEstime) {
        this.declaredBudgetSum += structure.budgetPreDepot?.montant ? Number(structure.budgetPreDepot.montant) : 0;
      } else if (this.sharedFunction.getProjectEtapeName(this.projet) !== EnumProjetEtape.PRE_DEPOT) {
        this.declaredBudgetSum += structure.budgetDepot?.montant ? Number(structure.budgetDepot.montant) : 0;
      }
    });
  }

  getProjetDocuments(): void {
    const fork = forkJoin({
      poles: this.poleService.getProjetPoles(this.projetId),
      documents: this.projetService.getDocumentsProjet(this.projetId, [
        EnumTypeDocument.PROJET,
        EnumTypeDocument.MEDIA_VIDEO,
        EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE,
        EnumTypeDocument.POLE,
      ]),
      demandesComplements: this.demandeComplementService.getDemandesComplements(this.projetId),
    });

    fork.pipe(catchError(error => of(error)));

    fork.subscribe(result => {
      this.poles = result.poles.body;
      this.demandesComplements = result.demandesComplements.body;
      this.demandesComplementsEncours = this.demandesComplements.filter((d: DemandeComplements) =>
        ['ENVOYEE', 'COMPLETEE'].includes(d.statut)
      );
      this.handleDocuments(result.documents.body);
    });
  }

  handleDocuments(documents: DocumentProjet[]) {
    this.listDocumentProjet = documents;
    this.listDocuments = this.listDocumentProjet.filter(
      doc => doc.type !== LETTRE_LABELISATION && doc.typeDoc !== EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE
    );
    if (this.projet?.document?.nom) {
      this.listDocumentProjet.push(this.projet.document);
    }
    this.listDocumentPoles = [];

    this.listDocumentProjet.forEach(document => {
      if (document.type === LETTRE_LABELISATION && this.poles.find(i => i.id === document.poleId) && this.projetId === document.projetId) {
        this.listDocumentPoles.push(document);
      }
    });
    this.poles.map(pole => (pole.hasDoc = !!this.listDocumentProjet.find(i => i.poleId === pole.id)));
    this.documentsComplementaires = this.listDocumentProjet.filter(doc => doc.typeDoc === EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE);
    this.documentsComplementaires.forEach(
      doc => (doc.type = EnumDocumentComplementaireType.toString(doc.type as EnumDocumentComplementaireType))
    );
    this.updateDocumentsComplementaires();
  }

  updateDocumentsComplementaires() {
    this.remainingDocumentsComplementaires = [];
    this.isDocumentsComplementairesInvalid = false;
    this.demandesComplementsEncours.forEach(d => {
      d.documents.forEach(doc => {
        if (
          !this.documentsComplementaires.some(
            document => document.demandeComplementId === d.id && document.type === EnumDocumentComplementaireType.toString(doc.type)
          )
        ) {
          this.remainingDocumentsComplementaires.push({
            nom: EnumDocumentComplementaireType.toString(doc.type),
            demandeComplementId: d.id,
          });
          if (doc.obligatoire) {
            this.isDocumentsComplementairesInvalid = true;
          }
        }
      });
    });
  }

  getProjetKpiStatus(): void {
    this.subscriptions.push(
      this.grilleImpactHttpService
        .getProjectAndStructuresKpisStatus(this.projetId)
        .pipe(map(GrilleImpactHelperService.getGIProjetStatut))
        .subscribe({
          next: this.setGrilleImpactsValid.bind(this),
          error: (err: HttpErrorResponse) => {
            if (err.status === HttpStatusCode.NotFound) return;
            this.showToastrService.checkCodeError(err?.error);
          },
        })
    );
  }

  private setGrilleImpactsValid(statut: StatutGrilleImpacts) {
    this.isGrilleImpactsValid = GrilleImpactHelperService.isGrilleImpactsValid(statut);
  }

  getExpensesStatus(): void {
    this.depensesService
      .getProjetExpensesStatus(this.projetId)
      .pipe(take(1))
      .subscribe({
        next: (resp: HttpResponse<EnumValidation>) => {
          this.isExpensesValid = resp.body === this.VALIDE;
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.NotFound) {
            return;
          }
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  /*
   * Vérifie si les informations du projet sont remplies
   * */
  checkProjetInfo(): boolean {
    return this.projet.nom != null && this.projet.description != null;
  }

  /*
   * Vérifie si le document uploadé a passé le test antivirus
   * */
  isValidDocument(document: DocumentProjet): boolean {
    return document?.scan === EnumScanDocument.SAFE;
  }

  isScanedDocument(document: DocumentProjet): boolean {
    return document?.scan === EnumScanDocument.SAFE || document?.scan === EnumScanDocument.UNSAFE;
  }

  checkDocument(): boolean {
    return this.listDocumentProjet.length > 0 || !!this.projet?.document?.nom;
  }

  calculatePurcent(montant: string): number {
    return Math.round((parseInt(montant.replace(/\s/g, ''), 10) * 100) / this.declaredBudgetSum);
  }

  getPoleName(id: string): string {
    const pole = this.poles.find(i => i.id === id);
    return pole ? pole.nomCommercial : '';
  }

  onSecteurSelected(): void {
    this.secteurSelected = !this.secteurSelected;
  }

  /*
   * Permet d'aller au formulaire vide
   * */
  onGoToProjetInfoGene(): void {
    if (!this.isProjectUpdatableByUser) {
      return;
    }
    this.router.navigate(['../projet-info-general'], { relativeTo: this.route });
  }

  onGoToBudgetInfo(): void {
    if (!this.isProjectUpdatableByUser) {
      return;
    }
    this.router.navigate(['../projet-info-budget'], { relativeTo: this.route });
  }

  onGoToBudgetChefDeFile(): void {
    const chefDeFile = this.structureList.filter(structure => structure.role === EnumRoleStructure.CHEF_DE_FILE)[0];
    if (chefDeFile) {
      this.onGoToBudgetStructure(chefDeFile.id);
    }
  }

  onGoToBudgetStructure(structureId): void {
    if (this.isProjectUpdatableByUser) {
      this.router.navigate(['../projet-consortium-info/' + structureId + '/projet-consortium-budget'], { relativeTo: this.route });
    } else {
      this.router.navigate(['../projet-consortium-info/' + structureId], { relativeTo: this.route });
    }
  }

  onGoToPolesInfo(): void {
    if (!this.isProjectUpdatableByUser) {
      return;
    }
    this.router.navigate(['../projet-info-poles'], { relativeTo: this.route });
  }

  onGoToSecteursInfo(): void {
    if (!this.isProjectUpdatableByUser) {
      return;
    }
    this.router.navigate(['../projet-info-secteurs'], { relativeTo: this.route });
  }

  onGoToDomainesInfo(): void {
    if (!this.isProjectUpdatableByUser) {
      return;
    }
    this.router.navigate(['../projet-info-domaines'], { relativeTo: this.route });
  }

  onGoToThematiqueInfo(): void {
    if (!this.isProjectUpdatableByUser) {
      return;
    }
    this.router.navigate(['../projet-info-thematique'], { relativeTo: this.route });
  }

  onGoToEnquete(): void {
    this.router.navigate(['../projet-info-enquete'], { relativeTo: this.route });
  }

  onGoToGrilleImpacts(): void {
    this.router.navigate(['../projet-info-grille-impacts'], { relativeTo: this.route });
  }

  onGoToExpensesInfo(): void {
    if (!this.isProjectUpdatableByUser) {
      return;
    }
    this.router.navigate(['../projet-info-depenses'], { relativeTo: this.route });
  }

  onGoToFicheComInfo(): void {
    this.router.navigate(['../projet-info-fiche-com'], { relativeTo: this.route });
  }

  /*
   * Affiche la modale pour ajouter un document
   * */
  onUploadDocument(docs: UploadDocModalDocumentModel[]): void {
    if (this.isProjectUpdatableByUser && docs.length > 0) {
      const dialogRef = this.dialog.open(SelectUploadDocComponent, {
        data: {
          title: 'Ajouter un fichier',
          docList: docs,
          textGoButton: 'Ajouter',
          textReturnButton: 'Annuler',
          isProjetInfoPage: true,
        },
      });

      dialogRef.afterClosed().subscribe((result: UploadDocModalResultModel) => {
        if (result) {
          this.allowDownload = true;
          this.onUpload(result.files, result.document);
        }
      });
    }
  }

  onUploadPoleDocument($event: any, index: number): void {
    const result: any[] = [];
    result.push($event.target.files);

    this.poles[index].fileTooBig = false;
    this.poles[index].fileNotSupported = false;
    this.poles[index].fileToUpload = result[0].item(0);

    this.isUploading = true;

    this.projetService
      .getDocumentsProjet(this.projet.id, [EnumTypeDocument.POLE])
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (resp: any) => {
          this.listDocumentPoles = resp.body.filter((doc: DocumentProjet) => doc.type === LETTRE_LABELISATION && this.poles.find(p => p.id === doc.poleId));
          if (this.validatePoleDocumentFile(index)) {
            this.projetService
              .upsertDocumentProjet(this.buildPoleDocument(index))
              .pipe(this.takeUntilDestroyed())
              .subscribe({
                next: (response: any) => {
                  if (response) {
                    this.uploadPoleDocumentToS3(response.body, this.poles[index].fileToUpload);
                  }
                },
                error: (err: HttpErrorResponse) => {
                  this.showToastrService.checkCodeError(err?.error);
                  this.isUploading = false;
                }
              });
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
          this.isUploading = false;
        }
      });
  }

  private validatePoleDocumentFile(index: number): boolean {
    if (this.listDocumentPoles.find(i => i.poleId === this.poles[index].id)) {
      this.showToastrService.checkCodeError({ code: "document.present" }, this.poles[index].nomCommercial);
      this.poles[index].hasDoc = true;
      return false;
    }

    if (!this.poles[index].fileToUpload || this.poles[index].fileToUpload.size === 0) {
      this.poles[index].fileEmpty = true;
      return false;
    }

    if (this.poles[index].fileToUpload.size > FILE_SIZE * 1048576) {
      this.poles[index].fileTooBig = true;
      return false;
    }

    if (!["application/pdf"].includes(this.poles[index].fileToUpload.type)) {
      this.poles[index].fileNotSupported = true;
      return false;
    }
    return true;
  }

  private buildPoleDocument(index: number): DocumentProjet {
    const documentPole = new DocumentProjet();
    documentPole.nom = this.poles[index].fileToUpload?.name || "";
    documentPole.dateModification = this.projet.dateModification;
    documentPole.projetId = this.projet.id;
    documentPole.poleId = this.poles[index].id;
    documentPole.type = LETTRE_LABELISATION;
    documentPole.scope = EnumScope.PROJET;
    documentPole.typeDoc = EnumTypeDocument.POLE;
    return documentPole;
  }

  private uploadPoleDocumentToS3(upsertedDocument: DocumentProjet, fileToUpload: File) {
    const typeDocument = "pole";
    this.uploadDocumentService
      .uploadDocumentToS3(upsertedDocument, DocumentHelper.TYPE_B401, typeDocument, fileToUpload)
      .pipe(
        this.takeUntilDestroyed(),
        finalize(() => {
          this.isUploading = false;
        })
      )
      .subscribe({
        next: () => {
          this.showToastrService.success("Le document a bien été ajouté");
          return this.getProjetDocuments();
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
          this.deleteDocumentStructure(upsertedDocument, true);
        }
      });
  }

  isFileSizeValid(file: File, videoDocEnable: boolean): boolean {
    if (videoDocEnable) {
      return !(file.size && file.size > VIDEO_SIZE * 1048576);
    }
    return !(file.size && file.size > FILE_SIZE * 1048576);
  }

  isFileTypeValid(file: File, supportedTypesExtensions: string[]): boolean {
    return supportedTypesExtensions.includes(file.type);
  }

  setFileInvalidityFlags(
    document: UploadDocModalDocumentModel,
    isFileSizeValid: boolean,
    isFileTypeValid: boolean,
    videoDocEnable: boolean
  ): void {
    const isConnaissanceClient = !!document.connaissanceClient;
    const isDocumentComplementaire = !!document.demandeComplementId;

    this.fileTooBig = !videoDocEnable && !isDocumentComplementaire && !isFileSizeValid;
    this.fileComplTooBig = !videoDocEnable && isDocumentComplementaire && !isFileSizeValid;
    this.videoTooBig = videoDocEnable && !isFileSizeValid;

    this.fileNotSupported = !isDocumentComplementaire && !isConnaissanceClient && !isFileTypeValid;
    this.fileComplNotSupported = isDocumentComplementaire && !isConnaissanceClient && !isFileTypeValid;

    this.connaissanceClientFileNotSupported = !isDocumentComplementaire && isConnaissanceClient && !isFileTypeValid;
    this.connaissanceClientFileComplNotSupported = isDocumentComplementaire && isConnaissanceClient && !isFileTypeValid;
  }

  resetFileInvalidityFlags(): void {
    this.fileTooBig = false;
    this.fileComplTooBig = false;
    this.videoTooBig = false;
    this.fileNotSupported = false;
    this.fileComplNotSupported = false;
    this.connaissanceClientFileNotSupported = false;
    this.connaissanceClientFileComplNotSupported = false;
  }

  onUpload(files: FileList, document?: UploadDocModalDocumentModel): void {
    this.resetFileInvalidityFlags();
    if (this.allowDownload) {
      this.allowDownload = false;
      const isConnaissanceClient = !!document.connaissanceClient;
      const fileToUpload = files.item(0);
      const fileTypes = isConnaissanceClient ? this.CONNAISSANCE_CLIENT_FILE_TYPES : this.DEFAULT_FILE_TYPES;
      const videoDocEnable = DocumentHelper.isVideoMediaTypes(document?.mediaTypes);
      const isFileSizeValid = this.isFileSizeValid(fileToUpload, videoDocEnable);
      const isFileTypeValid = this.isFileTypeValid(fileToUpload, fileTypes);

      if (fileToUpload && isFileSizeValid && isFileTypeValid) {
        this.processFilePersistence(fileToUpload, document);
      } else {
        this.setFileInvalidityFlags(document, isFileSizeValid, isFileTypeValid, videoDocEnable);
      }
    }
  }

  persistFile(fileToUpload: File, document?: UploadDocModalDocumentModel) {
    const videoDocEnable = DocumentHelper.isVideoMediaTypes(document.mediaTypes);
    if (fileToUpload.size > VIDEO_SIZE * 1048576 && videoDocEnable) {
      this.videoTooBig = true;
    } else if (fileToUpload.size > FILE_SIZE * 1048576 && !videoDocEnable) {
      this.fileTooBig = !document?.demandeComplementId;
      this.fileComplTooBig = !!document?.demandeComplementId;
    } else if (document.connaissanceClient && !this.CONNAISSANCE_CLIENT_FILE_TYPES.includes(fileToUpload.type)) {
      this.connaissanceClientFileNotSupported = !document?.demandeComplementId;
      this.connaissanceClientFileComplNotSupported = !!document?.demandeComplementId;
    } else if (
      !document.connaissanceClient &&
      !this.DEFAULT_FILE_TYPES.includes(fileToUpload.type) &&
      !fileToUpload.name.toLocaleLowerCase().endsWith('.flv')
    ) {
      this.fileNotSupported = !document?.demandeComplementId;
      this.fileComplNotSupported = !!document?.demandeComplementId;
    } else {
      this.processFilePersistence(fileToUpload, document);
    }
  }

  processFilePersistence(fileToUpload: File, document?: UploadDocModalDocumentModel) {
    let documentProjet: DocumentProjet = DocumentHelper.buildDocumentProjetWithEtape(
      fileToUpload,
      this.projet,
      this.sharedFunction.getProjectEtapeName(this.projet),
      this.utilisateur,
      document
    );
    this.isUploading = true;
    let docSavedInBase = false;
    const typeUpload = DocumentHelper.getUploadDocumentType(documentProjet);
    this.projetService
      .upsertDocumentProjet(documentProjet)
      .pipe(
        filter(response => response.body !== null),
        tap(response => (documentProjet = response.body)),
        tap(response => this.listDocumentProjet.push(response.body)),
        tap(response => {
          document?.demandeComplementId
            ? this.documentsComplementaires.push({
                ...response.body,
                type: EnumDocumentComplementaireType.toString(response.body.type as EnumDocumentComplementaireType),
              })
            : this.listDocuments.push(response.body);
          this.updateDocumentsComplementaires();
        }),
        tap(() => (docSavedInBase = true)),
        switchMap(() => this.retrievedSignedUrl(typeUpload, documentProjet, fileToUpload)),
        switchMap(signedUrl => this.processS3Upload(signedUrl, typeUpload, documentProjet, fileToUpload)),
        this.takeUntilDestroyed(),
        catchError(err => of(this.handleSaveDocumentError(err, docSavedInBase, documentProjet)))
      )
      .subscribe(value => this.handleSaveDocumentSuccess(value));
  }

  private retrievedSignedUrl(
    typeUpload: string,
    documentProjet: DocumentProjet,
    fileToUpload: File
  ): Observable<HttpResponse<SignedUrlResponseModel>> {
    const path = DocumentHelper.getS3Path(this.projet, documentProjet);
    return this.uploadDocumentService.getValueForDocStructureUpload(fileToUpload.name, path, typeUpload, documentProjet.id, this.projet.id);
  }

  private processS3Upload(response: HttpResponse<{ url: string }>, typeUpload: string, documentProjet: DocumentProjet, fileToUpload: File) {
    const url = response.body.url;
    return this.uploadDocumentService.upload(url, fileToUpload, this.projet.id, documentProjet.id, typeUpload);
  }

  private handleSaveDocumentError(err: HttpErrorResponse, docSavedInBdd: boolean, documentProjet: DocumentProjet): HttpErrorResponse {
    this.showToastrService.checkCodeError(err?.error);
    this.isUploading = false;
    if (docSavedInBdd) {
      this.deleteDocumentStructure(documentProjet, true);
    }
    return err;
  }

  private handleSaveDocumentSuccess(value: HttpErrorResponse | HttpResponse<SignedUrlResponseModel>) {
    if (!(value instanceof HttpErrorResponse)) {
      this.showToastrService.success('Le fichier a bien été ajouté');
      this.isUploading = false;
    }
  }

  /*
   * Affiche le nom du créateur du Document
   * */
  getNomCreateurDocument(document: DocumentProjet): string {
    return DocumentHelper.getNomCreateurDocument(document);
  }

  /*
   * Download a document
   * */
  downloadDocument(document: DocumentProjet): void {
    const typeUpload = DocumentHelper.getUploadDocumentType(document);
    const path = DocumentHelper.getS3Path(this.projet, document) + '/' + document.nom;
    if (document.stockerGed) {
      this.subscriptions.push(
        this.uploadDocumentHttpService
          .getDocFromGED(document.id)
          .pipe(this.takeUntilDestroyed())
          .subscribe({
            next: (response: HttpResponse<Blob>) => {
              if (response.body) {
                const fileName = document.nom;
                this.downloadUtils.download(response.body, fileName);
              }
            },
            error: (err: HttpErrorResponse) => {
              this.showToastrService.checkCodeError(err?.error);
            },
          })
      );
    } else {
      this.uploadDocumentService
        .getValueForDocProjetDownload(path, typeUpload)
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: (response: HttpResponse<SignedUrlResponseModel>) => {
            const url = response.body.url;
            window.open(url);
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    }
  }

  /*
   * Affiche la modale pour supprimer un document
   * */
  onDeleteDocument(document: DocumentProjet): void {
    if (document.etape !== this.sharedFunction.getProjectEtapeName(this.projet).toString()) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        description: `<p>Êtes-vous sûr de vouloir supprimer ce fichier.</p>
                          <p>Cette action est irréversible. </p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
        icon: true,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (document && (document.type !== DocumentHelper.TYPE_B401 || document.typeDoc === EnumTypeDocument.MEDIA_VIDEO)) {
          this.deleteDocumentStructure(document);
        } else {
          this.projet.document = new DocumentProjet();
          this.projet.document.dateModification = this.projet.dateModification;
          this.subscriptions.push(
            this.projetService.upsertDocumentProjetExistant(this.projet.id, this.projet.document).subscribe({
              next: (response: any) => {
                this.projet = response.body;
                this.listDocumentProjet = this.listDocumentProjet.filter(doc => doc.id);
                this.showToastrService.success('Le fichier a bien été supprimé');

                if (document.scan === EnumScanDocument.UNSAFE) {
                  return;
                }
                const path = this.projet.id + '/' + document.id + '/' + document.nom;
                this.uploadDocumentService.deletDocStructureUpload(path, DocumentHelper.TYPE_B401, document.id, this.projet.id).subscribe({
                  next: () => undefined,
                  error: (err: HttpErrorResponse) => {
                    this.showToastrService.checkCodeError(err?.error);
                  },
                });
              },
              error: (err: HttpErrorResponse) => {
                this.showToastrService.checkCodeError(err?.error);
              },
            })
          );
        }
      }
    });
  }

  deleteDocumentStructure(document: DocumentProjet, keepInS3?: boolean): void {
    this.projetService
      .deleteDocument(this.projet.id, document.id)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: response => {
          if (response) {
            this.listDocuments = this.listDocuments.filter(doc => doc.id !== document.id);
            this.documentsComplementaires = this.documentsComplementaires.filter(doc => doc.id !== document.id);
            this.listDocumentPoles = this.listDocumentPoles.filter(doc => doc.id !== document.id);
            this.updateDocumentsComplementaires();
            this.getProjetDocuments();
            if (keepInS3) {
              return;
            }
            this.showToastrService.success('Le fichier a bien été supprimé');

            if (document.scan === EnumScanDocument.UNSAFE) {
              return;
            }

            if (document.stockerGed) {
              return;
            }

            const typeUpload = DocumentHelper.getUploadDocumentType(document);
            const path = DocumentHelper.getS3Path(this.projet, document) + '/' + document.nom;
            this.subscriptions.push(
              this.uploadDocumentService.deletDocStructureUpload(path, typeUpload, document.id, this.projet.id).subscribe({
                error: (err: HttpErrorResponse) => {
                  this.showToastrService.checkCodeError(err?.error);
                },
              })
            );
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  downloadRGPD(): void {
    window.open(URL_PUBLIC_DOC + this.aap.programme.fichierRgpd);
  }

  getDocumentTypeLabel(type: string): string {
    return EnumDocumentProjetType[type];
  }

  /**
   * Retourne un Boolean pour afficher le message Lecture seule en haut de la page
   */
  showReadOnlyMessage(): boolean {
    return this.userPermissions != null && this.aap?.id != null && !this.isProjectUpdatableByUser;
  }

  /**
   * Retourne un Boolean pour afficher le lien de dossier de candidature
   */
  showDossierCandidatureLink(): boolean {
    return this.etapeProjet === EnumProjetEtape.PRE_DEPOT && this.aap.lienDocumentation?.length > 0;
  }

  toUploadDocModalDocumentModel(documents: DocumentAapModel[]): UploadDocModalDocumentModel[] {
    return documents.map((document: DocumentAapModel) => ({
      nom: document.nomDocument,
      mediaTypes: document.mediaTypes,
      connaissanceClient: document.connaissanceClient,
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }

  protected readonly EnumScope = EnumScope;
  protected readonly EnumTypePartenaire = EnumTypePartenaire;
  protected readonly enumValidation = EnumValidation;
}
