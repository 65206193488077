import { ProgramInterface } from '../interfaces/program.interface';
import { Aap } from './aap.model';

export class Program implements ProgramInterface {
  id: string;
  type: string;
  typeComptable: string;
  typeFront: string;
  nom: string;
  description: string;
  fichierRgpd: string;
  fichierRgpdEvaluateur: string;
  historiqueStatuts: object[];
  url: string;
  aaps: Aap[];
  statut: string;
  dateCreation: Date;
  dateModification: Date;
}
