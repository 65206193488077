import { DatePipe } from '@angular/common';

export class DateTimeUtils {
  static datePipe = new DatePipe('en-US');

  static toUtcDate(date: Date | null): Date | null {
    if (!date) {
      return null;
    }

    if (typeof date === 'string') {
      date = new Date(date);
    }

    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
  }

  /**
   * Format a date to a simple date string (without time).
   */
  public static formatToDate(date: Date): string | null {
    if (!date) {
      return null;
    }
    return this.datePipe.transform(new Date(date), 'yyyy-MM-dd');
  }

  /**
   * Format a date for front .
   */
  public static formatDateForFront(value: string): string | null {
    if (!value) {
      return null;
    }
    return value
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
      .replace(/[^\d/]/g, '');
  }

  public static generateTimes(startHourIncluded: number, endHourExcluded: number, stepInMinutes: number): string[] {
    const times: string[] = [];
    for (let hour = startHourIncluded; hour < endHourExcluded; hour++) {
      for (let minute = 0; minute < 60; minute += stepInMinutes) {
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        times.push(time);
      }
    }
    return times;
  }
}
