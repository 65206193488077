import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { ProjetInfoSecteursComponent } from '@features-candidat/projet-creation/component/projet-info-secteurs/projet-info-secteurs.component';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [ProjetInfoSecteursComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
})
export class ProjetInfoSecteursModule {}
