import { StructureInterface } from '../interfaces/structure.interface';
import { Contact } from './contact.model';
import { EnumRoleStructure } from '../enums/enum.roleStructure';
import { Adresse } from './adresse.model';
import { Budget } from './budget.model';
import { EnumMotifNonRattachement } from '../enums/enum.motifNonRattachement';
import { LieuRD } from './lieuRD.model';
import { Verrouillage } from './verrouillage.model';
import { MembreEquipe } from './membreEquipe.model';
import { AlerteFinanciere } from './alerteFinanciere.model';
import { EntrepriseDifficulte } from './entrepriseDifficulte.model';
import { ContractualisationStructure } from './contractualisation-structure.model';
import { InformationsStructure } from './informations-structure.model';
import { dossierCommercial } from './dossierCommercial.model';
import { InformationsReferentiel } from './informationsReferentiel.model';
import { EnumProjetTag } from '../enums/enum.projetTag';
import { Eligibilite } from './eligibilite.model';
import { InstructionStructure } from './instruction-structure.model';
import { FicheFlaminem } from './fiche-flaminem.model';
import { EnumTypeStructure } from '../enums/enum.typeStructure';
import { InformationsNonFinancieres } from './informations-non-financieres.model';

export class Structure implements StructureInterface {
  id: string;
  projetId: string;
  siret: string;
  raisonSiret?: EnumMotifNonRattachement;
  raisonSocial: string;
  activite: string;
  role: EnumRoleStructure;
  contacts: Contact[] = [];
  dateModification: Date;
  typeStructure?: EnumTypeStructure;
  adresse: Adresse = new Adresse();
  lieuRD: LieuRD = new LieuRD();
  closed = false;
  budgetPreDepot: Budget = new Budget();
  budgetDepot: Budget = new Budget();
  verrouillage: Verrouillage = new Verrouillage();
  eligibilite: Eligibilite = new Eligibilite();
  equipe: MembreEquipe[] = [];
  instruction: InstructionStructure = new InstructionStructure();
  ereputation: string;
  alerteFinanciere: AlerteFinanciere;
  entrepriseDifficulte: EntrepriseDifficulte;
  contractualisation: ContractualisationStructure;
  informationsStructure?: InformationsStructure = new InformationsStructure();
  dossiersCommerciaux: dossierCommercial[];
  informationsReferentiel?: InformationsReferentiel = new InformationsReferentiel();
  tag: EnumProjetTag;
  ficheFlaminem: FicheFlaminem = new FicheFlaminem();
  effectifSiege: string;
  effectifGroupe: string;
  informationsNonFinancieres?: InformationsNonFinancieres = new InformationsNonFinancieres();
}
