import { enumKeys } from "../../utils/enum-utils";

export enum EnumScope {
  PROJET = 'PROJET',
  STRUCTURE = 'STRUCTURE',
  STRUCTURE_ACTIONARIAL = 'STRUCTURE_ACTIONARIAL'
}

export namespace EnumScope {
  export function toString(scope: EnumScope, plural: boolean = false): string {
    switch (scope) {
      case EnumScope.PROJET:
        return 'Projet';
      case EnumScope.STRUCTURE:
        return plural ? 'Structure(s)' : 'Structure';
      case EnumScope.STRUCTURE_ACTIONARIAL:
        return plural ? 'Structure(s) actionariale(s)' : 'Structure actionariale';
    }
  }

  export function all(): EnumScope[] {
    return enumKeys(EnumScope) as unknown as EnumScope[];
  }
}
