<div class="filter-container" [ngClass]="{ 'filter-container-disabled': isDisabled, 'filter-container-error': !isValid }">
  <input
    class="filter-input"
    id="closeDatePicker"
    placeholder="{{ placeholder ? placeholder : 'JJ/MM/AAAA' }}"
    matInput
    [matDatepicker]="closeDatePicker"
    [(ngModel)]="selectedDate"
    (dateChange)="checkValidity()"
    (keyup)="checkValidity()"
  />
  <mat-datepicker #closeDatePicker></mat-datepicker>
  <div class="icon-container">
    <mat-datepicker-toggle matSuffix [for]="closeDatePicker"></mat-datepicker-toggle>
  </div>
</div>
