import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@environments-candidat/environment';
import { ProjetInfoService } from '@features-candidat/projet-creation/service/projet-info.service';
import { AapService } from '@services-candidat/aap.service';
import { ProjetService } from '@services-candidat/projet.service';
import {
  Aap,
  DemandeModificationHelperService,
  EnumAapStatut,
  EnumProjetEtape,
  EnumProjetStatut,
  EnumProjetTag,
  EnumTypePartenaire,
  EnumTypeWorkflow,
  Projet,
  ProjetEtape,
  ShowToastrService,
} from '@shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pxl-sidenav-projet',
  templateUrl: './sidenav.projet.component.html',
  styleUrls: ['./sidenav.projet.component.scss'],
})
export class SidenavProjetComponent implements OnInit, OnDestroy {
  projet: Projet = new Projet();
  subscriptions: Subscription[] = [];
  aap: Aap = new Aap();
  emailString = '';
  originUrl: string;
  modificationTag: string;

  constructor(
    private projetService: ProjetService,
    private aapService: AapService,
    private showToastrService: ShowToastrService,
    private projetInfoService: ProjetInfoService,
    private demandeModificationHelperService: DemandeModificationHelperService
  ) {
    this.updateProjet();
  }

  ngOnInit(): void {
    this.originUrl = environment.docPublicBaseUrl;
    this.getProjet();
    this.getAap();
  }

  /*
   * Met à jour le nom du projet
   * */
  updateProjet(): void {
    this.subscriptions.push(
      this.projetInfoService.onNomProjet().subscribe((nomProjet: string) => {
        if (nomProjet) {
          this.projet.nom = nomProjet;
        }
      })
    );
    this.subscriptions.push(
      this.projetInfoService.onAcronymeProjet().subscribe((acronymeProjet: string) => {
        if (acronymeProjet) {
          this.projet.acronyme = acronymeProjet;
        }
      })
    );

    this.subscriptions.push(
      this.projetInfoService.onStatutProjet().subscribe((statutProjet: EnumProjetStatut) => {
        if (statutProjet) {
          this.projet.statut = statutProjet;
        }
      })
    );

    this.projet.statut = null;
    this.projet.nom = '';
  }

  /*
   * Récupérer l'aap et le projet et qui a été sélectionné
   * */
  getProjet(): void {
    this.subscriptions.push(
      this.projetService.getProjetObservable().subscribe((response: Projet) => {
        if (response) {
          this.projet = response;
          this.modificationTag = this.demandeModificationHelperService.tagValeur(this.projet);
        }
      })
    );
  }

  getAap(): void {
    let stop = 0;
    this.subscriptions.push(
      this.aapService.loadAapSubject().subscribe((response: HttpResponse<Aap>) => {
        if (response) {
          this.aap = response.body;
          if (
            this.aap.statut === EnumAapStatut.FERME &&
            this.aap.typeWKF !== EnumTypeWorkflow.WKF1 &&
            stop === 0 &&
            this.projet.tag !== EnumProjetTag[EnumProjetTag.A_CORRIGER.toString()]
          ) {
            this.showToastrService.aapNotOuvert();
            stop = 1;
          }
          this.emailString = 'mailto:' + this.aap.bal + "?Subject=Demande d'information " + this.aap.nom;
        }
      })
    );
  }

  /*
   * Récupère la dernière étape du projet
   * */
  getEtapeProjet(): ProjetEtape {
    if (this.projet.etapes != null) {
      /* Classe les dates par ordre croissant afin de récupérer la plus récente */
      const datesEtapes = this.projet.etapes;
      datesEtapes.sort((b, a) => {
        return (new Date(b.date) as any) - (new Date(a.date) as any);
      });

      return datesEtapes[datesEtapes.length - 1];
    }

    return { nom: null, statut: null, date: null };
  }

  ngOnDestroy(): void {
    this.showToastrService.clearToastr();

    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }

  protected readonly EnumProjetStatut = EnumProjetStatut;
  protected readonly EnumProjetEtape = EnumProjetEtape;
  protected readonly EnumTypePartenaire = EnumTypePartenaire;
}
