import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjetInfoService } from '@features-candidat/projet-creation/service/projet-info.service';
import { AapService } from '@services-candidat/aap.service';
import { ProjetService } from '@services-candidat/projet.service';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import { Aap, DESCRIPTION_SIZE_LIMIT, Projet, FormValidatorService, ProjetInfo, ShowToastrService } from '@shared-ui';
import { Observable, Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-projet-info-general',
  templateUrl: './projet-info-general.component.html',
  styleUrls: ['./projet-info-general.component.scss'],
})
export class ProjetInfoGeneralComponent implements OnInit, OnDestroy {
  disableValidBtn = false;
  isProjectHasInfo = false;
  projetForm: UntypedFormGroup;
  projet: Projet = new Projet();
  aap: Aap = new Aap();
  subscriptions: Subscription[] = [];
  projetInfo: ProjetInfo = new ProjetInfo();

  readonly DESCRIPTION_SIZE = DESCRIPTION_SIZE_LIMIT;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private projetService: ProjetService,
    private aapService: AapService,
    private router: Router,
    private route: ActivatedRoute,
    private projetInfoService: ProjetInfoService,
    private showToastrService: ShowToastrService,
    public sharedFunction: SharedFunction,
    private formValidatorService: FormValidatorService
  ) {}

  ngOnInit(): void {
    this.createForms();
    this.getProjet();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.projetForm.dirty;
  }

  /*
   * Initialise le formulaire
   * */
  createForms(): void {
    this.projetForm = this.formBuilder.group({
      nom: [null, Validators.required],
      acronyme: [null, [Validators.required, this.formValidatorService.singleSpaceValidator()]],
      description: [null, Validators.required],
    });
  }

  /*
   * Modifie le nom, acronyme et la description d'un projet
   * */
  modifyProjetInfo(): void {
    if (this.sharedFunction.isProjectUpdatable(this.aap, this.projet)) {
      if (this.projetForm.valid) {
        this.projetInfo.dateModification = this.projet.dateModification;
        this.projetInfo.budget = this.projet.budget;
        Object.assign(this.projetInfo, this.projetForm.value);
        this.subscriptions.push(
          this.projetService.upsertInfoGeneProjet(this.projet.id, this.projetInfo).subscribe({
            next: (response: any) => {
              if (response) {
                this.projetInfoService.updateNomProjet(this.projetInfo.nom);
                this.projetInfoService.updateAcronymeProjet(this.projetInfo.acronyme);
                this.projetService.setProjetObservable(response.body);
                this.projetForm.reset();
                this.projetForm.markAsPristine();
                this.showToastrService.success('Projet modifié');
                this.onGoToProjetInfo();
              }
            },
            error: (err: HttpErrorResponse) => {
              this.showToastrService.checkCodeError(err?.error);
            },
          })
        );
      } else {
        this.projetForm.markAllAsTouched();
      }
    }
  }

  /*
   * Cette méthode récupère le projet
   * */
  getProjet(): void {
    this.subscriptions.push(
      this.projetService
        .getProjetObservable()
        .pipe(
          concatMap(responseProjet => {
            if (responseProjet) {
              this.projet = responseProjet;
              this.projetForm.patchValue(this.projet);
              this.isProjectHasInfo = !this.checkProjetInfo();
              return this.aapService.loadAapSubject();
            }
          })
        )
        .subscribe({
          next: responseAap => {
            if (responseAap) {
              this.aap = responseAap.body;
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        })
    );
  }

  /*
   * Vérifie si les informations du projet sont remplies
   * */
  checkProjetInfo(): boolean {
    return this.projet.nom == null && this.projet.acronyme == null && this.projet.description == null;
  }

  /*
   * Cette méthode permet de retourner à la page d'information projet
   * */
  onGoToProjetInfo(): void {
    this.router.navigate(['../projet-info'], { relativeTo: this.route });
  }

  public inputValidator(event: Event) {
    const targetEvent = event.target as HTMLInputElement;
    targetEvent.value = targetEvent.value.replace(/[^a-zA-Z\d\s]/g, '');
    this.projetForm.patchValue({ acronyme: targetEvent.value.toUpperCase() });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }
}
