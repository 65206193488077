import { isNil } from 'lodash';
import { EnumEnqueteConnaissanceTech } from '../shared/enums/enum.enquete.connaissance';
import { EnumScanDocument } from '../shared/enums/enum.scanDocument';
import { EnumScope } from '../shared/enums/enum.scope';
import { EnumProjetEtape } from '../shared/enums/enumProjetEtape';
import { Aap } from '../shared/models/aap.model';
import { DocumentProjet } from '../shared/models/documentProjet.model';
import { Enquete } from '../shared/models/enquete.model';
import { Projet } from '../shared/models/projet.model';
import { NumberUtils } from './number-utils';

export class ProjetValidationFunction {
  /**
   * Fonction indiquant si les informations générales d'un projet passé en paramètre sont correctes
   * @param projet Projet à vérifier
   * @returns Boolean indiquant si les infos du projet sont bien renseignées
   */
  infosGeneralesIsCorrect(projet: Projet): boolean {
    return (
      !!projet?.nom &&
      projet.nom.trim() !== '' && // Le nom est complet
      !!projet?.acronyme &&
      projet?.acronyme.trim() !== '' && // L'acronyme est complet
      !!projet?.description &&
      projet?.description.trim() !== ''
    ); // La description est complete
  }

  /**
   * Fonction indiquant si la thématique d'un projet passé en paramètre est correcte
   * @param projet Projet à vérifier
   * @returns Boolean indiquant si la thématique est bien renseignée
   */
  thematiquesIsCorrect(projet: Projet): boolean {
    return (
      projet.thematique != null && // La thématique n'est pas null
      !!projet?.thematique.codeThematique &&
      projet.thematique.codeThematique?.trim() !== '' && // Le code est complet
      !!projet?.thematique.libelleThematique &&
      projet.thematique.libelleThematique?.trim() !== ''
    ); // Le libelle est complet
  }

  /**
   * Fonction indiquant si les domaines techno d'un projet passé en paramètre sont corrects
   * @param projet Projet à vérifier
   * @returns Boolean indiquant si les domaines techno sont bien renseignés
   */
  domainesTechnologiquesIsCorrect(projet: Projet): boolean {
    return projet.domaines?.length > 0;
  }

  /**
   * Fonction indiquant si les secteurs d'un projet passé en paramètre sont corrects
   * @param projet Projet à vérifier
   * @returns Boolean indiquant si les secteurs sont bien renseignés
   */
  secteursApplicationIsCorrect(projet: Projet): boolean {
    return projet.secteurs?.length > 0;
  }

  /**
   * Fonction indiquant si toutes les valeurs obligatoires d'un budget sont renseignées
   * @param projet Projet à vérifier
   * @returns Boolean indiquant si le formulaire budget est valide
   */
  isBudgetComplete(aap: Aap, projet: Projet): boolean {
    return (
      aap?.budgetEstime === false ||
      (NumberUtils.isNumeric(projet.budget?.montant) && NumberUtils.isNumeric(projet.budget?.montantAideDemande))
    );
  }

  /**
   * Fonction indiquant si le budget d'un projet a déja été renseigné
   * @param projet Projet à vérifier
   * @returns Boolean indiquant si le budget est bien renseigné
   */
  isBudgetSet(projet: Projet): boolean {
    return !isNil(projet.budget?.montant) || projet.budget?.besoin === false;
  }

  /**
   * Fonction indiquant si les poles d'un projet passé en paramètre sont corrects
   * @param projet Projet à vérifier
   * @returns Boolean indiquant si les poles sont bien renseignés
   */
  polesCompetitiviteIsCorrect(projet: Projet): boolean {
    return projet.poles?.length > 0;
  }

  /**
   * Fonction indiquant si les documents d'un projet passé en paramètre sont corrects
   * @param documents Documents du projet à vérifier
   * @param projet Projet à vérifier
   * @returns Boolean indiquant si les documents du projet sont bien renseignés
   */
  documentsIsCorrect(aap: Aap, documents: DocumentProjet[], projet: Projet): boolean {
    const listDocForThisStep = aap.documentAaps?.filter((document: any) => {
      return (
        document.etapes[0] === this.getProjectEtapeName(projet).toString() &&
        document.typePartenaires?.includes(projet.partenaireType) &&
        document.scope === EnumScope.PROJET
      );
    });
    return (
      !listDocForThisStep?.length ||
      (documents.length > 0 &&
        documents.some(doc => listDocForThisStep.some((sDoc: any) => doc.type === sDoc.nomDocument)) &&
        documents.every(doc => this.isValidDocument(doc)))
    );
  }

  /*
   * Vérifie si le document uploadé a passé le test antivirus
   * */
  isValidDocument(document: DocumentProjet): boolean {
    return document?.scan !== EnumScanDocument.UNSAFE;
  }

  // TODO déplacer dans sharedFunction quand celui sera factorisé
  getProjectEtapeName(projet: Projet): EnumProjetEtape {
    return projet.etapes[projet.etapes.length - 1].nom;
  }

  enqueteIsCorrect(projet: Projet): boolean {
    const enquete = projet.enquete;

    if (enquete == null) {
      return false;
    }

    const sourceInfoCorrect =
      enquete.sourceInfo &&
      (enquete.sourceInfo === EnumEnqueteConnaissanceTech[EnumEnqueteConnaissanceTech.SITE_INTERNET] ? enquete.sourceInfositeWeb : true);

    const exploitRecherchePublicCorrect = this.enqueteExploitRecherchePublicCorrect(enquete);

    const hasBrevetDepotPublicCorrect = enquete.hasBrevetDepotPublic != null;

    const lieRecherchePublicCorrect =
      enquete.lieRecherchePublic != null && (enquete.lieRecherchePublic ? enquete.lieRecherchePublicDetail : true);

    const incubeIncubateurPublicCorrect =
      enquete.incubeIncubateurPublic != null && (enquete.incubeIncubateurPublic ? enquete.incubateurPublicDetail : true);

    const incubeIncubateurPriveCorrect =
      enquete.incubeIncubateurPrive != null && (enquete.incubateurPriveDetail ? enquete.incubateurPriveDetail : true);

    const matureSATTCorrect = enquete.matureSATT != null && (enquete.matureSATT ? enquete.sattdetail : true);

    const objetFinancementCorrect = enquete.objetFinancement != null && (enquete.objetFinancement ? enquete.objetFinancementType : true);

    const developpementDurableCorrect = this.enqueteDeveloppementDurableCorrect(enquete);

    return (
      !!sourceInfoCorrect &&
      !!exploitRecherchePublicCorrect &&
      hasBrevetDepotPublicCorrect &&
      !!lieRecherchePublicCorrect &&
      !!incubeIncubateurPublicCorrect &&
      !!incubeIncubateurPriveCorrect &&
      !!matureSATTCorrect &&
      !!objetFinancementCorrect &&
      developpementDurableCorrect
    );
  }

  private enqueteDeveloppementDurableCorrect(enquete: Enquete): boolean {
    return (
      enquete.developpementDurable != null &&
      (enquete.developpementDurable
        ? enquete.developpementDurableEnergiesRenouv?.length > 0 ||
          enquete.developpementDurableGestionPollution?.length > 0 ||
          enquete.developpementDurableOptimConsommation?.length > 0
        : true)
    );
  }

  private enqueteExploitRecherchePublicCorrect(enquete: Enquete): string | boolean {
    return (
      enquete.hasExploitRecherchePublic != null &&
      (enquete.hasExploitRecherchePublic ? enquete.exploitRecherchePublicOrganisme : true) &&
      (enquete.hasExploitRecherchePublic ? enquete.exploitRecherchePublicOrganismeCoordonnees : true)
    );
  }
}
