import { EnumRadioType } from '../../shared/enums/_public_enums';
import { RadioButtonOption } from '../../shared/models/_public_models';

export const OPTIONS_FAV_DEFAV: RadioButtonOption[] = [
  { key: 'Favorable', value: 'Favorable', type: EnumRadioType.SUCCESS },
  { key: 'Défavorable', value: 'Défavorable', type: EnumRadioType.ERROR },
];

export const OPTIONS_FAV_DEFAV_REO: RadioButtonOption[] = [
  { key: 'Favorable', value: 'Favorable', type: EnumRadioType.SUCCESS },
  { key: 'Défavorable', value: 'Défavorable', type: EnumRadioType.ERROR },
  { key: 'Réorientation', value: 'Réorientation', type: EnumRadioType.DEFAULT },
];

export const OPTIONS_A_RETENIR_SANS_SUITE: RadioButtonOption[] = [
  { key: 'A retenir', value: 'A retenir', type: EnumRadioType.SUCCESS },
  { key: 'Sans suite', value: 'Sans suite', type: EnumRadioType.ERROR },
];

export const OPTIONS_1234: RadioButtonOption[] = [
  { key: '1', value: '1', type: EnumRadioType.ERROR },
  { key: '2', value: '2', type: EnumRadioType.WARNING },
  { key: '3', value: '3', type: EnumRadioType.WARNING },
  { key: '4', value: '4', type: EnumRadioType.SUCCESS },
];

export const OPTIONS_1234_NA: RadioButtonOption[] = [
  { key: '1', value: '1', type: EnumRadioType.ERROR },
  { key: '2', value: '2', type: EnumRadioType.WARNING },
  { key: '3', value: '3', type: EnumRadioType.WARNING },
  { key: '4', value: '4', type: EnumRadioType.SUCCESS },
  { key: 'NA', value: 'NA', type: EnumRadioType.ERROR },
];

export const OPTIONS_ABC: RadioButtonOption[] = [
  { key: 'A', value: 'A', type: EnumRadioType.SUCCESS },
  { key: 'B', value: 'B', type: EnumRadioType.WARNING },
  { key: 'C', value: 'C', type: EnumRadioType.ERROR },
];
export const OPTIONS_: RadioButtonOption[] = [
  { key: 'A', value: 'A', type: EnumRadioType.SUCCESS },
  { key: 'B', value: 'B', type: EnumRadioType.WARNING },
  { key: 'C', value: 'C', type: EnumRadioType.ERROR },
];

export const OPTIONS_FAV_DEFAV_RES_AJOURNEE: RadioButtonOption[] = [
  { key: 'Favorable', value: 'Favorable', type: EnumRadioType.SUCCESS },
  { key: 'Défavorable', value: 'Défavorable', type: EnumRadioType.ERROR },
  { key: 'Réservé', value: 'Réservé', type: EnumRadioType.DEFAULT },
  { key: 'Ajournement', value: 'Ajournement', type: EnumRadioType.DEFAULT },
];
