import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { PoleService } from '@services-candidat/pole.service';
import { ProjetService } from '@services-candidat/projet.service';
import { ConfirmModalComponent } from '@shared-candidat/components/modals/confirm-modal/confirm-modal.component';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import { Aap, Projet, ProjetInfo, ShowToastrService } from '@shared-ui';
import { Observable, Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-projet-info-poles',
  templateUrl: './projet-info-poles.component.html',
  styleUrls: ['./projet-info-poles.component.scss'],
})
export class ProjetInfoPolesComponent implements OnInit, OnDestroy {
  disableValidBtn = false;
  isProjectHasBudget = false;
  projet: Projet = new Projet();
  aap: Aap = new Aap();
  subscriptions: Subscription[] = [];
  projetInfo: ProjetInfo = new ProjetInfo();

  aapPoles: any[];
  selectedPoles: any[] = [];
  InitialSelectedPoles: any[] = [];
  polesNonSelectione = false;

  constructor(
    private projetService: ProjetService,
    private aapService: AapService,
    private poleService: PoleService,
    private router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    public sharedFunction: SharedFunction,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getProjet();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return true;
  }

  /*
   * Cette méthode récupère le projet
   * */
  getProjet(): void {
    const projetId = this.route.snapshot.parent.params.id;
    if (projetId) {
      this.subscriptions.push(
        this.projetService
          .getProjetObservable()
          .pipe(
            concatMap(responseProjet => {
              if (responseProjet) {
                this.projet = responseProjet;
                return this.aapService.loadAapSubject();
              }
            })
          )
          .subscribe({
            next: responseAap => {
              if (responseAap) {
                this.aap = responseAap.body;
                this.getPoles();
              }
            },
            error: (err: HttpErrorResponse) => {
              this.showToastrService.checkCodeError(err?.error);
            },
          })
      );
    }
  }

  /*
   * Cette méthode récupère les poles de l'AAP
   * */
  getPoles(): void {
    this.subscriptions.push(
      this.poleService.getAapPoles(this.aap.id).subscribe({
        next: response => {
          this.aapPoles = response.body;
          this.aapPoles.forEach(pole => {
            pole.checked = false;
          });
          this.aapPoles.forEach(pole => {
            if (this.projet.poles.find(i => i.id === pole.id)) {
              pole.checked = true;
            }
          });
          this.selectedPoles = [];
          this.projet.poles.forEach(poleProjet => {
            this.selectedPoles.push(poleProjet.id);
          });
          this.InitialSelectedPoles = [];
          this.selectedPoles.forEach(pole => {
            this.InitialSelectedPoles.push(pole);
          });
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      })
    );
  }

  onSelectCheckbox(pole: any): void {
    if (pole.checked) {
      this.selectedPoles = this.selectedPoles.filter(selectedPole => selectedPole !== pole.id);
      pole.checked = false;
    } else {
      this.selectedPoles.push(pole.id);
      pole.checked = true;
    }
  }

  /*
   * Cette méthode permet de retourner à la page d'information projet
   * */
  onGoToProjetInfo(): void {
    this.router.navigate(['../projet-info'], { relativeTo: this.route });
  }

  onClickBack(): void {
    if (
      this.selectedPoles.filter(x => !this.InitialSelectedPoles.includes(x)).length > 0 ||
      this.InitialSelectedPoles.filter(x => !this.selectedPoles.includes(x)).length > 0
    ) {
      this.showToastrService.checkCodeError({ code: 'poles.non.enregistrés' });
    }
    this.onGoToProjetInfo();
  }

  onValidateChoice(): void {
    if (this.selectedPoles.length === 0 && this.aap.poleObligatoire) {
      this.polesNonSelectione = true;
    } else {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          title: "Confirmez vous l'affectation des Pôles sélectionnés sur le  projet ?",
          description: `<p>En confirmant votre action, les Pôles sélectionnés vont être notifiés et pourront accéder aux données de la partie projet de votre candidature.
                        <br>Vous devez parallèlement prendre contact avec le / les pôle(s) de compétitivité sélectionnés afin d'initier la démarche de labellisation et de leur transmettre les données et documents que vous jugerez utile à l'obtention de la lettre de labellisation.
                        <br>Les pôles désélectionnés ne pourront plus accéder à votre candidature.</p>`,
          textGoButton: 'Oui',
          textReturnButton: 'Non',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.editPoles();
        }
      });
    }
  }

  editPoles(): void {
    this.subscriptions.push(
      this.poleService.assignerPolesProjet(this.projet.id, this.selectedPoles).subscribe({
        next: projet => {
          this.showToastrService.success('Projet modifié');
          this.projetService.setProjetObservable(projet);
          this.onGoToProjetInfo();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }
}
