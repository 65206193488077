import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { ProjetService } from '@services-candidat/projet.service';
import { StructureService } from '@services-candidat/structure.service';
import { UserService } from '@services-candidat/user.service';
import { ShowToastrService, SubscriptionDestroyerComponent, Utilisateur } from '@shared-ui';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { SidebarStateService } from '../../services/sidebar-state.service';
import { ProjetInfoDepensesSharedService } from './component/projet-info-depenses/projet-info-depenses-shared.service';

@Component({
  selector: 'app-projet-creation',
  templateUrl: './projet-creation.component.html',
  styleUrls: ['./projet-creation.component.scss'],
})
export class ProjetCreationComponent extends SubscriptionDestroyerComponent implements OnInit, OnDestroy {
  private sideBarIsOpen: boolean;
  subscriptions: Subscription[] = [];

  userCharged = false;
  projetCharged = false;
  aapCharged = false;
  structuresCharged = false;

  isShowing = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private sidebarStateService: SidebarStateService,
    private userService: UserService,
    private aapService: AapService,
    private projetService: ProjetService,
    private structureService: StructureService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    private sharedService: ProjetInfoDepensesSharedService
  ) {
    super();
    this.sideBarIsOpen = false;

    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.sidebarStateService.changeState(false);
        this.renderer.addClass(document.body, 'sidebar-closed');
      }
    });
  }

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'nav-collapsed');

    this.sidebarStateService.changeState(this.sideBarIsOpen);
    this.sidebarStateService.clickBehavior.subscribe(state => {
      this.sideBarIsOpen = state;
    });

    if (!this.sideBarIsOpen) {
      this.renderer.addClass(document.body, 'sidebar-closed');
    }

    const projetId = this.route.snapshot.parent.params.id;
    this.subscriptions.push(
      this.userService.getUserObservable().subscribe((utilisateur: Utilisateur) => {
        if (utilisateur !== null) {
          const userPermissions = utilisateur.permissions
            .filter(permission => {
              if (permission.idProjet === projetId) {
                return permission;
              }
            })
            .map(permission => permission.privileges)[0]
            ?.map(privilege => privilege.nomPrivilege.toString());

          this.permissionsService.flushPermissions();
          this.permissionsService.addPermission(userPermissions);
          this.userCharged = true;
        }
      })
    );

    this.subscriptions.push(
      this.projetService.getProjetById(projetId).subscribe({
        next: projetResult => {
          this.projetService.setProjetObservable(projetResult.body);
          this.projetCharged = true;

          this.loadAap(projetResult.body.aap.id);
          this.loadStructures(projetResult.body.id);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      })
    );

    this.loadSidenavDepenses();
  }

  loadAap(aapId: string): void {
    this.subscriptions.push(
      this.aapService.getAapById(aapId).subscribe({
        next: () => {
          this.aapCharged = true;
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      })
    );
  }

  loadStructures(projectId: string): void {
    this.subscriptions.push(
      this.structureService.getStructuresByIdProject(projectId).subscribe({
        next: structuresResult => {
          this.structureService.setStructuresListObservable(structuresResult.body);
          this.structuresCharged = true;
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      })
    );
  }

  close(): void {
    this.isShowing = false;
  }

  private loadSidenavDepenses(): void {
    this.sharedService
      .getIsShowing()
      .pipe(this.takeUntilDestroyed())
      .subscribe((show: boolean) => {
        this.isShowing = show;
      });
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'nav-collapsed');
    this.sideBarIsOpen = false;
    this.sidebarStateService.changeState(this.sideBarIsOpen);
    this.renderer.removeClass(document.body, 'sidebar-closed');
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }
}
