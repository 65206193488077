<div>
  <form [formGroup]="kpiQuestionsForm">
    <header class="">
      <h2 class="kpi-titre d-flex align-items-center">
        <span class="kpi-ordre">&#x25CF;</span>{{ kpi.titre }}
        <div [matTooltip]="kpi.aide" *ngIf="!kpi.aideVisible && kpi.aideVisible !== null" class="d-flex align-items-center">
          <lib-pxl-icon *ngIf="true" class="icon-info" icon="icon-action-info-circle-outline" aria-hidden="true"> </lib-pxl-icon>
        </div>
      </h2>
    </header>
    <p class="question-aide" *ngIf="kpi.aideVisible">
      {{ kpi.aide }}
    </p>
    <div>
      <div class="kpi-container" [formArrayName]="kpi.clef">
        <div *ngFor="let sousAxe of questionsBySousAxe | keyvalue : valueOrder; let i = index">
          <div>
            <!-- titre sous axe -->
            <h4 class="kpi-sous-axe d-flex align-items-center" *ngIf="sousAxe.value.sousAxe.sousAxeExistant">
              {{ sousAxe.key }}
              <div [matTooltip]="sousAxe.value.sousAxe.sousAxeAide" class="d-flex align-items-center">
                <lib-pxl-icon
                  *ngIf="
                    sousAxe.value.sousAxe && !sousAxe.value.sousAxe.sousAxeAideVisible && sousAxe.value.sousAxe.sousAxeAideVisible !== null
                  "
                  class="icon-info"
                  icon="icon-action-info-circle-outline"
                  aria-hidden="true"
                >
                </lib-pxl-icon>
              </div>
            </h4>
            <p class="question-aide" *ngIf="sousAxe.value.sousAxe.sousAxeAideVisible">
              {{ sousAxe.value.sousAxe.sousAxeAide }}
            </p>

            <ng-container *ngFor="let question of sousAxe.value.questions; let j = index">
              <div class="question-container">
                <!-- composant de type liste -->
                <div
                  id="input-typeVoie"
                  *ngIf="question.reponse.type === 'LISTE'"
                  class="ds-input-group"
                  [class.ds-input-group--error]="
                    question.obligatoire &&
                    !kpiQuestionsForm.controls[kpi.clef].get(getQuestionControlName(sousAxe.key, j))?.valid &&
                    kpiQuestionsForm.controls[kpi.clef].get(getQuestionControlName(sousAxe.key, j))?.touched
                  "
                >
                  <label for="numero" class="ds-input-group__label">
                    {{ question.question }}
                    <span *ngIf="question.obligatoire" class="ds-input-group__label-asterisk"> *</span>
                    <div [matTooltip]="question.aide" class="d-flex align-items-center">
                      <lib-pxl-icon
                        *ngIf="question.aide && !question.aideVisible && question.aideVisible !== null"
                        class="icon-info"
                        icon="icon-action-info-circle-outline"
                        aria-hidden="true"
                      >
                      </lib-pxl-icon>
                    </div>
                  </label>
                  <div>
                    <p class="question-aide" *ngIf="question.aideVisible">{{ question.aide }}</p>
                  </div>
                  <div class="ds-input-group__select ds-input-group__select--with-icon">
                    <select
                      id="kpi-liste"
                      name="typeVoie"
                      [attr.disabled]="!isUpdatableByUser ? true : null"
                      [formControlName]="getQuestionControlName(sousAxe.key, j)"
                    >
                      <option [value]="null" selected disabled>{{ question.reponse.placeholder }}</option>
                      <option *ngFor="let reponse of question.reponse.listeValeursPossibles" [value]="reponse">
                        {{ reponse }}
                      </option>
                    </select>
                  </div>
                </div>
                <div *ngIf="question.reponse.type === 'LIBRE'">
                  <!-- composant de saisie libre numérique -->
                  <div *ngIf="question.reponse.format === 'NUMERIQUE'">
                    <div
                      id="input-numero"
                      class="ds-input-group"
                      [class.ds-input-group--error]="
                        question.obligatoire &&
                        !kpiQuestionsForm.controls[kpi.clef].get(getQuestionControlName(sousAxe.key, j))?.valid &&
                        kpiQuestionsForm.controls[kpi.clef].get(getQuestionControlName(sousAxe.key, j))?.touched
                      "
                    >
                      <label for="numero" class="ds-input-group__label">
                        {{ question.question }}
                        <span *ngIf="question.obligatoire" class="ds-input-group__label-asterisk"> *</span>
                        <div [matTooltip]="question.aide" class="d-flex align-items-center">
                          <lib-pxl-icon
                            *ngIf="question.aide && !question.aideVisible && question.aideVisible !== null"
                            class="icon-info"
                            icon="icon-action-info-circle-outline"
                            aria-hidden="true"
                          >
                          </lib-pxl-icon>
                        </div>
                      </label>
                      <div>
                        <p class="question-aide" *ngIf="question.aideVisible">{{ question.aide }}</p>
                      </div>
                      <span class="ds-input-group__input">
                        <input
                          id="numero"
                          [name]="kpi.titre + '-reponse-' + question.index"
                          type="text"
                          (keypress)="onkeypress($event)"
                          [placeholder]="question.reponse.placeholder"
                          class="input_numero ds-input-group__input"
                          [attr.disabled]="!isUpdatableByUser ? true : null"
                          [formControlName]="getQuestionControlName(sousAxe.key, j)"
                        />
                      </span>
                    </div>
                  </div>
                  <!-- composant de saisie libre alphanumérique -->
                  <div
                    class="ds-input-group"
                    *ngIf="question.reponse.format === 'ALPHANUMERIQUE'"
                    [class.ds-input-group--error]="
                      question.obligatoire &&
                      !kpiQuestionsForm.controls[kpi.clef].get(getQuestionControlName(sousAxe.key, j))?.valid &&
                      kpiQuestionsForm.controls[kpi.clef].get(getQuestionControlName(sousAxe.key, j))?.touched
                    "
                  >
                    <label for="kpi.titre + '-reponse-' + question.index" class="ds-input-group__label">
                      {{ question.question }}
                      <span *ngIf="question.obligatoire" class="ds-input-group__label-asterisk"> *</span>
                      <div [matTooltip]="question.aide" class="d-flex align-items-center">
                        <lib-pxl-icon
                          *ngIf="question.aide && !question.aideVisible && question.aideVisible !== null"
                          class="icon-info"
                          icon="icon-action-info-circle-outline"
                          aria-hidden="true"
                        >
                        </lib-pxl-icon>
                      </div>
                    </label>
                    <div>
                      <p class="question-aide" *ngIf="question.aideVisible">{{ question.aide }}</p>
                    </div>
                    <span class="ds-input-group__input">
                      <input
                        [name]="kpi.titre + '-reponse-' + question.index"
                        type="text"
                        [placeholder]="question.reponse.placeholder"
                        class="input_hameau ds-input-group__input"
                        [attr.disabled]="!isUpdatableByUser ? true : null"
                        [formControlName]="getQuestionControlName(sousAxe.key, j)"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!kpiQuestionsForm.valid && kpiQuestionsForm.touched && saveGrilleImpact" class="ds-input-group ds-input-group--error">
      <div class="ds-input-group__feedback">Merci de renseigner les champs entourés en rouge.</div>
    </div>
  </form>
</div>
