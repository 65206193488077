<div *ngIf="documents?.length" class="mb-3">
  <div class="mb-2" *ngFor="let document of documents">
    <lib-document-card
      *ngIf="document.scope === scope"
      [document]="document"
      [projet]="projet"
      [hasCheckbox]="false"
      [canUserWrite]="canUserDeleteDocument"
      (deleteDocEmitter)="onDeleteDocument($event)"
      [showDownload]="canDownload(document)"
      [deleteDisabled]="document.etape !== projectEtapeName"
    >
      <ng-container document-modification-tag>
        <div
          class="card__action-button-wrapper"
          *ngIf="showTag && document.completudeSecondNiveauDocument?.statut === 'MODIFICATION_DEMANDEE'"
        >
          <div class="card__tag card__tag--yellow">Demande de modification</div>
        </div>
      </ng-container>
    </lib-document-card>
  </div>
</div>
