<div
  [formGroup]="group"
  id="input-value"
  class="form-step__value ds-input-group"
  [class.ds-input-group--error]="control.invalid && control.touched"
  [class.m-0]="!text"
>
  <div class="ds-input-group__label" *ngIf="text">
    <label>{{ text }}</label>
    <lib-asterisk *ngIf="isMandatory"> *</lib-asterisk>
  </div>
  <p class="ds-input-group__label info__text w-100" *ngIf="infoText">{{ infoText }}</p>
  <div class="select-container d-flex align-items-center">
    <div class="value-select" [class.disabled]="disabled === 'DISABLED'">
      <ng-container *ngFor="let option of options">
        <input
          [formControlName]="controlName"
          type="radio"
          [name]="controlName"
          [id]="controlName + index + '__' + option.key.toString()"
          [value]="option.key"
          hidden
        />
        <label
          class="radio-label"
          [for]="controlName + index + '__' + option.key.toString()"
          tabindex="0"
          #input
          (keydown.enter)="input.click()"
          [ngClass]="{
          'green-bg': option.type === enumRadioType.SUCCESS,
          'red-bg': option.type === enumRadioType.ERROR,
          'orange-bg': option.type === enumRadioType.WARNING,
          'default-bg': option.type === enumRadioType.DEFAULT,
        }"
          >{{ option.value }}</label
        >
      </ng-container>
    </div>
    <div *ngIf="helpText" [matTooltip]="helpText">
      <lib-pxl-icon class="icon radio_button_help ml-3" icon="icon-action-info-circle-outline"></lib-pxl-icon>
    </div>
  </div>
  <!-- Icône et message d'erreur -->
  <ng-container id="error-value" *ngIf="!control.valid && control.touched && errorMessage">
    <div class="ds-input-group__feedback">{{ errorMessage }}</div>
  </ng-container>
</div>
