import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';

import { ProjetService } from '@services-candidat/projet.service';

import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import { Aap, Domaine, Projet, ProjetInfo, ShowToastrService, SousDomaine } from '@shared-ui';
import { Observable, Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-projet-info-domaines',
  templateUrl: './projet-info-domaines.component.html',
  styleUrls: ['./projet-info-domaines.component.scss'],
})
export class ProjetInfoDomainesComponent implements OnInit, OnDestroy {
  disableValidBtn = false;
  isProjectHasBudget = false;
  projet: Projet = new Projet();
  aap: Aap = new Aap();
  subscriptions: Subscription[] = [];
  projetInfo: ProjetInfo = new ProjetInfo();

  aapDomaines: any[];
  selectedDomaines: any[] = [];
  InitialselectedDomaines: any[] = [];
  selectedSousDomaines: any[] = [];
  initialSelectedSousDomaines: any[] = [];
  domainesNonSelectione = false;
  maxChecked = 0;

  constructor(
    private projetService: ProjetService,
    private aapService: AapService,
    private router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    public sharedFunction: SharedFunction
  ) {}

  ngOnInit(): void {
    this.getProjet();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !(
      this.initialSelectedSousDomaines.filter(o1 => !this.selectedSousDomaines.some(o2 => o1.libelleSousDomaine === o2.libelleSousDomaine))
        .length > 0 ||
      this.selectedSousDomaines.filter(o1 => !this.initialSelectedSousDomaines.some(o2 => o1.libelleSousDomaine === o2.libelleSousDomaine))
        .length > 0
    );
  }

  /*
   * Cette méthode récupère le projet
   * */
  getProjet(): void {
    this.subscriptions.push(
      this.projetService
        .getProjetObservable()
        .pipe(
          concatMap(responseProjet => {
            if (responseProjet) {
              this.projet = responseProjet;

              return this.aapService.loadAapSubject();
            }
          })
        )
        .subscribe({
          next: responseAap => {
            if (responseAap) {
              this.aap = responseAap.body;
              this.getDomaines();
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        })
    );
  }

  /*
   * Cette méthode récupère les domaines de l'AAP
   * */
  getDomaines(): void {
    this.maxChecked = 0;
    this.aapDomaines = this.aap.domaines;

    this.selectedDomaines = this.projet.domaines;

    this.InitialselectedDomaines = [];

    this.projet.domaines.forEach(dom => {
      this.InitialselectedDomaines.push(JSON.parse(JSON.stringify(dom)));
    });

    this.aapDomaines.forEach(domaine => {
      const projetsousDomaine = this.projet.domaines.filter(sect => sect.libelleDomaine === domaine.libelleDomaine);

      return domaine.sousDomaines.forEach(sousDomaine => {
        if (projetsousDomaine.length > 0) {
          if (projetsousDomaine[0].sousDomaines.find(sousSect => sousSect.libelleSousDomaine === sousDomaine.libelleSousDomaine)) {
            sousDomaine.checked = true;
            this.maxChecked++;
          } else {
            sousDomaine.checked = false;
          }
        } else {
          sousDomaine.checked = false;
        }
      });
    });
  }

  onSelectCheckbox(domaine: Domaine, sousDomaine: SousDomaine): void {
    if (sousDomaine.checked) {
      sousDomaine.checked = false;
      this.maxChecked--;

      this.selectedDomaines.forEach(dom => {
        if (dom.libelleDomaine === domaine.libelleDomaine) {
          dom.sousDomaines = dom.sousDomaines.filter(sousSect => sousSect.libelleSousDomaine !== sousDomaine.libelleSousDomaine);
        }
      });

      this.selectedDomaines = this.selectedDomaines.filter(sect => {
        return sect.sousDomaines.length > 0;
      });
    } else {
      sousDomaine.checked = true;
      this.maxChecked++;

      const isdomaineAlreadyExist = this.selectedDomaines.filter(sect => sect.libelleDomaine === domaine.libelleDomaine);
      if (isdomaineAlreadyExist.length > 0) {
        this.selectedDomaines.forEach(sect => {
          if (sect.libelleDomaine === domaine.libelleDomaine) {
            sect.sousDomaines.push(sousDomaine);
          }
        });
      } else {
        const addDomaine: Domaine = new Domaine();
        addDomaine.libelleDomaine = domaine.libelleDomaine;
        addDomaine.libelleDomaine = domaine.libelleDomaine;
        addDomaine.sousDomaines = [sousDomaine];
        this.selectedDomaines.push(addDomaine);
      }
    }

    this.initialSelectedSousDomaines = [];
    this.selectedSousDomaines = [];

    this.InitialselectedDomaines.forEach(dom => {
      dom.sousDomaines.forEach(sousDom => {
        this.initialSelectedSousDomaines.push(sousDom);
      });
    });

    this.selectedDomaines.forEach(dom => {
      dom.sousDomaines.forEach(sousDom => {
        this.selectedSousDomaines.push(sousDom);
      });
    });
  }

  /*
   * Cette méthode permet de retourner à la page d'information projet
   * */
  onGoToProjetInfo(): void {
    this.router.navigate(['../projet-info'], { relativeTo: this.route });
  }

  onClickBack(): void {
    this.initialSelectedSousDomaines = [];
    this.selectedSousDomaines = [];

    this.InitialselectedDomaines.forEach(dom => {
      dom.sousDomaines.forEach(sousDom => {
        this.initialSelectedSousDomaines.push(sousDom);
      });
    });

    this.selectedDomaines.forEach(dom => {
      dom.sousDomaines.forEach(sousDom => {
        this.selectedSousDomaines.push(sousDom);
      });
    });

    this.onGoToProjetInfo();
  }

  onValidateChoice(): void {
    if (this.selectedDomaines.length === 0 && this.aap.domaineObligatoire) {
      this.domainesNonSelectione = true;
    } else {
      this.projet.domaines = this.selectedDomaines;
      this.projetService.putProjet(this.projet).subscribe({
        next: response => {
          if (response) {
            this.showToastrService.success('Projet modifié');
            this.initialSelectedSousDomaines = this.selectedSousDomaines;
            this.projetService.setProjetObservable(response.body);
            this.onGoToProjetInfo();
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }
}
