import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as uuid from 'uuid';

declare const dsLib: any;

@Component({
  selector: 'lib-telephone-input',
  templateUrl: './telephone-input.component.html',
  styleUrls: ['./telephone-input.component.scss'],
})
export class TelephoneInputComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() id: string;
  @Input() containerId: string;
  @Input() required?: boolean;

  telephone: any = {};

  ngOnInit(): void {
    this.id = this.id + uuid.v4();
    this.telephone = new dsLib.InputPhone(this.id);
  }

  onBlur(): void {
    if (
      !this.telephone?.input?.iti?.isValidNumber() &&
      ((this.required && this.form?.controls['telephone'].value == '') ||
        (!this.required && this.form?.controls['telephone'].value && this.form?.controls['telephone'].value !== ''))
    ) {
      this.form?.controls['telephone'].setErrors({ incorrect: true });
    }
  }
}
