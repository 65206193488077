<h2 class="step__title">Informations de ma structure</h2>

<!-- Informations de la structure -->
<form class="step__content">
  <!-- Siret de la structure -->
  <lib-structure-searcher
    [displayCreationEnCoursOption]="isAapPersonnePhysique"
    (motifNonRattachementSelected)="onMotifNonRattachementSelected($event)"
    (structureSelected)="onStructureSelected($event)"
    (structuresFound)="onStructuresFound($event)"
    (maStructureNotListed)="onMaStructureNotListed()"
  ></lib-structure-searcher>
</form>
<!-- FIN de Informations de la structure -->

<div class="step__buttons">
  <button class="ds-btn ds-btn--secondary" (click)="onPreviousButtonClick()">Précédent</button>
  <button class="ds-btn ds-btn--primary" (click)="onNextButtonClick()" [disabled]="nextButtonDisabled">
    {{ nextButtonAsSaisieManuelle ? 'Saisir vos informations' : 'Suivant' }}
  </button>
</div>
