<div class="instructions">Pour établir votre planning, commencez par choisir la date de départ T0, puis ajoutez vos étapes clés</div>

<!-- Nouvelle date étape clé -->
<form id="projet-form" class="form" [formGroup]="calendarForm">
  <div
    id="input-newStepDate"
    class="ds-input-group ds-input-group--date"
    [class.ds-input-group--error]="calendarForm.controls['dateT0']?.invalid && calendarForm.controls['dateT0']?.touched"
  >
    <label class="ds-input-group__label fw-bold"> Date T0 <lib-asterisk></lib-asterisk> </label>
    <div class="add-date">
      <span class="ds-input-group__input">
        <input
          matInput
          [matDatepicker]="openDatePicker"
          formControlName="dateT0"
          (dateChange)="onDateChange()"
          class="ds-input-group__input"
          placeholder="JJ/MM/AAAA"
        />
        <mat-datepicker-toggle matSuffix [for]="openDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #openDatePicker></mat-datepicker>
      </span>

      <div class="add-date-btn">
        <button
          type="button"
          (click)="addDate()"
          [disabled]="dataSourceArray.length > 7 || !calendarForm.controls['dateT0']?.valid"
          class="add-step__create-button ds-btn ds-btn--secondary"
        >
          Ajouter une étape clé
          <pxl-icon class="icon" icon="icon-action-add"></pxl-icon>
        </button>
        <div *ngIf="dataSourceArray.length > 7" class="ds-input-group--error">
          <div class="ds-input-group__feedback">Vous avez atteint le nombre maximum d'étapes clés autorisé</div>
        </div>
      </div>
    </div>

    <!-- Icône et message d'erreur -->
    <ng-container id="error-newStepDate" *ngIf="!calendarForm.controls['dateT0']?.valid && calendarForm.controls['dateT0']?.touched">
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une date valide</div>
    </ng-container>
  </div>
</form>

<lib-table
  [dataArray]="dataSource"
  [columns]="displayedColumns"
  [disable]="calendarForm?.status"
  (dataChange)="updateData($event)"
  (keyPress)="onkeypress($event.event, $event.element)"
  (deleteElement)="onDeleteElement($event)"
></lib-table>

<ng-container
  id="error-etapeCle"
  *ngIf="
    !calendarForm.controls['etapesCles']?.valid &&
    calendarForm.controls['etapesCles']?.touched &&
    calendarForm.controls['etapesCles']?.status === 'INVALID'
  "
>
  <div class="ds-input-group ds-input-group--error">
    <div class="ds-input-group__feedback mt-3">Merci de bien vouloir renseigner un délai d'étape clé</div>
  </div>
</ng-container>
