import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { EnumScope, SelectUploadDocComponent, Structure, UploadDocModalDocumentModel } from '@shared-ui';
import { MatDialog } from '@angular/material/dialog';
import { SelectDocumentModalComponent } from '../select-document-modal/select-document-modal.component';

@Component({
  selector: 'lib-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UploadFilesComponent {
  @Input() isProjectUpdatableByUser: boolean;
  @Input() droitUpdateProjetByUser: boolean;
  @Input() disabledModalStructureActionarial: boolean;
  @Input() disabledModalStructure: boolean;

  @Input() scopes: EnumScope;
  @Input() structure: Structure;
  @Input() listDocActio: any[];
  @Input() listDoc: UploadDocModalDocumentModel[] = [];

  @Input() cardDocument: boolean;
  @Input() cardDocumentOveride: boolean;
  @Input() isValid: boolean;
  @Input() isInvalid: boolean;
  @Input() cardInactive: boolean;
  @Input() carddisabled: boolean;
  @Input() disabled: boolean;
  @Output() uploadDocModalResultModelEventEmitter = new EventEmitter<any>();

  constructor(public dialog: MatDialog) {}

  onUploadDocument(): void {
    let dialogRef;

    if (this.scopes === EnumScope.STRUCTURE_ACTIONARIAL) {
      if (!this.disabledModalStructureActionarial) {
        const data = {
          title: 'Ajouter un fichier',
          docList: this.listDocActio,
          structure: this.structure,
          textGoButton: 'Ajouter',
          textReturnButton: 'Annuler',
        };

        dialogRef = this.showDialogToUploadFiles(data);
      }
    } else if (this.scopes === EnumScope.STRUCTURE) {
      if (!this.disabledModalStructure) {
        const data = {
          title: 'Ajouter un fichier',
          docList: this.listDoc,
          structure: this.structure,
          textGoButton: 'Ajouter',
          textReturnButton: 'Annuler',
        };

        dialogRef = this.showDialogToUploadFiles(data);
      }
    } else {
      if (!this.droitUpdateProjetByUser) {
        return;
      }
      this.listDoc.sort((a, b) => a.nom.localeCompare(b.nom));
      const data = {
        title: 'Ajouter un fichier',
        docList: this.listDoc,
        textGoButton: 'Ajouter',
        textReturnButton: 'Annuler',
        isProjetInfoPage: true,
      };

      dialogRef = this.showDialogToUploadFiles(data);
    }
    if (dialogRef) {
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (this.scopes) {
            const resultScopes = {
              scopes: this.scopes,
              documents: result,
            };
            this.uploadDocModalResultModelEventEmitter.emit(resultScopes);
          } else {
            this.uploadDocModalResultModelEventEmitter.emit(result);
          }
        }
      });
    }
  }

  showDialogToUploadFiles(data: any) {
    if (this.scopes === EnumScope.STRUCTURE_ACTIONARIAL || this.scopes === EnumScope.STRUCTURE) {
      return this.dialog.open(SelectDocumentModalComponent, {
        data: data,
      });
    }
    return this.dialog.open(SelectUploadDocComponent, {
      data: data,
    });
  }
}
