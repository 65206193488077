import { FormGroup } from '@angular/forms';
import { Aap, DocumentAapModel, EnumProjetEtape, EnumRoleStructure, EnumScope, Projet, Structure } from '@shared-ui';

export class SharedFunction {
  getProjectEtapeName(projet: Projet): EnumProjetEtape | null {
    if (projet?.etapes == null) {
      return null;
    }
    return projet?.etapes[projet?.etapes.length - 1].nom;
  }

  getDocStructureAap(aap: Aap, structure: Structure, projet: Projet): DocumentAapModel[] {
    return aap.documentAaps?.filter((document: any) => {
      if (
        document.etapes.includes(this.getProjectEtapeName(projet)) &&
        document.typePartenaires?.includes(projet.partenaireType) &&
        document.scope === EnumScope.STRUCTURE
      ) {
        const roleMatching = document.roleStructures.filter((role: EnumRoleStructure) => {
          return structure.role === role;
        });
        const typeStructMatching = document.typeStructures.filter((type: string) => {
          return structure.typeStructure === type;
        });

        if (roleMatching.length > 0 && typeStructMatching.length > 0) {
          return document;
        }
      }
    });
  }

  isDisabledFormValid(form: FormGroup): boolean {
    const wasDisabled = form?.disabled;
    form?.enable();
    const formValid = form?.valid;
    if (wasDisabled) {
      form?.disable();
    }
    return formValid;
  }
}
