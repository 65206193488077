import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent {
  @Input() isDisabled: boolean;
  @Input() placeholder?: string;
  selectedDate: Date;
  isValid = true;

  checkValidity(): void {
    this.isValid = this.selectedDate !== null;
  }

  getSelectedDate(): Date | null {
    return this.selectedDate ? this.selectedDate : null;
  }
}
