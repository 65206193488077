import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss'],
})
export class TextBoxComponent {
  @Input() text = '';
  @Input() color: 'orange' | 'green' | 'red' = 'green';
}
