import { UtilisateurInterface } from '../interfaces/utilisateur.interface';
import { PrivilegeUtilisateur } from '../models/privilegeUtilisateur.model';
import { PermissionUtilisateur } from '../models/permissionUtilisateur.model';
import { EnumUserGroup } from '../enums/enum.userGroup';

export class Utilisateur implements UtilisateurInterface {
  id: string;
  matricule: string;
  matriculeIsam: string;
  email: string;
  firstName: string;
  lastName: string;
  groupe: EnumUserGroup;
  cgus: any;
  privileges: PrivilegeUtilisateur[] = [];
  permissions: PermissionUtilisateur[] = [];
  dateDernierLogin: Date;
  dateCreation: Date;
  dateModification: Date;
  aaps: any[];
  prenom: string;
  nom: string;
  lastNps: Date;
  favoriteAapIds: string[] = [];
}
