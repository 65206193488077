<!-- Entête de la page -->
<header id="header-structure" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToConsortium()">
    <pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></pxl-icon>
    Ma Structure
  </a>
  <h2 id="title-structure" class="page-header__title">{{ structure.raisonSocial }}</h2>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <form id="structure-form" class="form" [formGroup]="structureForm" (ngSubmit)="submitForm(structureForm.getRawValue())">
    <!-- Informations de la structure -->
    <section class="form-step structure">
      <h3 class="form-step__title">Informations du lieu de réalisation</h3>
      <ng-container *ngIf="structure.raisonSiret !== EnumMotifNonRattachement.EN_COURS_DE_CREATION">
        <div class="form-step__content form-step-same-adress">
          <div id="input-same-adress" class="ds-input-group">
            <label for="lieuRDisSiege" class="ds-input-group__label">Lieu de réalisation</label>
            <div id="lieu-rd-container">
              <label for="lieuRd" class="ds-switch ds-switch--m">
                <input type="checkbox" (change)="isSameAdress()" id="lieuRd" formControlName="lieuRDisSiege" />
                <span class="slider"></span>
              </label>
              <span id="oui-option" class="ds-body-2--regular label-r align-bottom">
                Le projet est réalisé à la même adresse que le siège</span
              >
            </div>
          </div>
        </div>
      </ng-container>

      <div class="form-step__content">
        <pxl-informations-structure
          *ngIf="!!structure"
          [structure]="structure.lieuRD"
          [updateForm]="updatedStructureInfoForm$"
          [aap]="aap"
          [fomSubmited]="formSubmited"
          [disableForm]="changeStructureInfoFormStatus$.asObservable()"
          (formChanged)="structureInfoChanged($event)"
          (structureDirty)="setStructureDirty($event)"
        >
        </pxl-informations-structure>
      </div>
    </section>
    <!-- FIN de Informations de la structure -->
    <section>
      <ng-container *ngIf="structureEnCreation">
        <h3 class="form-step__title">Adresse du lieu de réalisation</h3>
        <div class="form-step__content" formGroupName="adresse">
          <!-- Code Postal -->
          <div
            id="input-codePostal"
            class="ds-input-group"
            [class.ds-input-group--error]="structureForm.get('adresse').get('cp').invalid && structureForm.get('adresse').get('cp').touched"
          >
            <label for="cp" class="ds-input-group__label">Code postal <span class="ds-input-group__label-asterisk"> *</span></label>
            <span class="ds-input-group__input">
              <input
                id="cp"
                name="cp"
                type="text"
                maxlength="9"
                placeholder="Code Postal de la structure"
                class="input_codePostal ds-input-group__input"
                formControlName="cp"
              />
            </span>
            <!-- Icône et message d'erreur -->
            <ng-container
              id="error-codePostal"
              *ngIf="structureForm.get('adresse').get('cp').invalid && structureForm.get('adresse').get('cp').touched"
            >
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un code postal valide</div>
            </ng-container>
          </div>

          <!-- Ville -->
          <div
            id="input-ville"
            class="ds-input-group"
            [class.ds-input-group--error]="
              structureForm.get('adresse').get('ville').invalid && structureForm.get('adresse').get('ville').touched
            "
          >
            <label for="ville" class="ds-input-group__label">Ville <span class="ds-input-group__label-asterisk"> *</span></label>
            <span class="ds-input-group__input">
              <input
                id="ville"
                name="ville"
                type="text"
                maxlength="32"
                placeholder="Ville de la structure"
                class="input_ville ds-input-group__input"
                formControlName="ville"
              />
            </span>
            <!-- Icône et message d'erreur -->
            <ng-container
              id="error-ville"
              *ngIf="structureForm.get('adresse').get('ville').invalid && structureForm.get('adresse').get('ville').touched"
            >
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une ville valide</div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!structureEnCreation">
        <h3 class="form-step__title">Adresse du lieu de réalisation</h3>
        <div class="form-step__content">
          <lib-structure-adresse
            [adresseForm]="structureForm.get('adresse')"
            [isPersonnePhysique]="structureEnCreation"
            [readOnly]="isAdresseRdSameAsAdresseStructure"
          >
          </lib-structure-adresse>
        </div>
      </ng-container>
    </section>

    <div class="form-actions">
      <button id="cancel-save" type="button" class="ds-btn ds-btn--secondary" (click)="onGoToConsortium()">Annuler</button>
      <button id="save-structure" type="submit" class="ds-btn ds-btn--primary" *ngIf="isStructureUpdatableByUser">
        <span>Modifier</span>
      </button>
    </div>
  </form>
</div>
<!-- FIN de Contenu de la page -->
