import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FilterDataInterface } from '@shared-ui';
declare const dsLib: any;

/* Problématique : Le composant design système se base sur les "value" affichées comme ID de sélection, donc il est impossible d'avoir deux multiselect avec la même valeur
 * Solution : Ajouter un préfixe invisible à chaque valeur pour les différencier sans impacter l'affichage, ce prefixe est enlevé dans le getSelectedValues
 *            Ceci est une solution temporaire en attendant la màj de la version du design système
 */
const generatedStrings: Set<string> = new Set();
const PREFIX_LENGTH = 8;

@Component({
  selector: 'lib-ds-multiselect',
  templateUrl: './dsMultiselect.component.html',
  styleUrls: ['./dsMultiselect.component.scss'],
})
export class DsMultiselectComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() dataList: FilterDataInterface[];
  @Input() selectOptions: any = {};
  @Input() name: string;
  @Input() title: string;
  @Input() isError = false;
  @Input() required?: boolean;

  multiSelect: any;

  initialDataList: FilterDataInterface[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataList'] && !changes['dataList'].firstChange) {
      this.prefixDataValues();
    }
  }

  ngOnInit(): void {
    this.prefixDataValues();
    this.initialDataList = this.dataList.map(obj => ({ ...obj }));
  }

  ngAfterViewInit(): void {
    this.multiSelect = new dsLib.MultiSelect(this.name, this.dataList, this.selectOptions);
  }

  prefixDataValues(): void {
    this.dataList = this.dataList?.map(data => {
      return {
        id: data.id,
        value: this.generateInvisibleString(PREFIX_LENGTH) + data.value,
        isSelected: data.isSelected,
      };
    });
  }

  /**
   * Retourne les valeurs sélectionnées
   */
  getSelectedValues(): FilterDataInterface[] {
    return this.multiSelect._dataListSelection
      ? this.multiSelect?.getSelectedValues().map((data: FilterDataInterface) => {
          return {
            id: data.id,
            value: Array.from(data.value).slice(PREFIX_LENGTH).join(''),
            isSelected: data.isSelected,
          };
        })
      : undefined;
  }

  /**
   * Remet le composant à son état de départ
   */
  reset(clearSelectedData = true, resetToInitial = false): void {
    if (clearSelectedData) {
      this.dataList.forEach(data => {
        data.isSelected = false;
      });
    }
    
    if (resetToInitial) {
      this.dataList = this.initialDataList.map(obj => ({ ...obj }));
    }
    
    const eltById: HTMLElement | null = document.getElementById(this.name);
    if (eltById) {
      setTimeout(() => {
        if (eltById.getElementsByClassName('ds-select-button')[0] && eltById.getElementsByClassName('ds-select__options-list')[0]) {
          eltById.getElementsByClassName('ds-select-button')[0].remove();
          eltById.getElementsByClassName('ds-select__options-list')[0].remove();
          this.multiSelect = new dsLib.MultiSelect(this.name, this.dataList, this.selectOptions);
        }
      });
    }
  }

  disable(): void {
    if (this.selectOptions) {
      this.selectOptions.isDisabled = true;
    }
    this.reset();
  }

  enable(): void {
    if (this.selectOptions) {
      this.selectOptions.isDisabled = false;
    }
    this.reset();
  }

  generateInvisibleString(stringLength: number): string {
    const length = invisiblePool.length;
    let randomString = '';

    do {
      randomString = '';
      for (let i = 0; i < stringLength; i++) {
        const randomIndex = Math.floor(Math.random() * length);
        randomString += invisiblePool[randomIndex];
      }
    } while (generatedStrings.has(randomString));

    generatedStrings.add(randomString);
    return randomString;
  }
}

const invisiblePool = [
  '︀',
  '︁',
  '︂',
  '︃',
  '︄',
  '︅',
  '︆',
  '︇',
  '︈',
  '︉',
  '︊',
  '︋',
  '︌',
  '︍',
  '︎',
  '️',
  '󠄀',
  '󠄁',
  '󠄂',
  '󠄃',
  '󠄄',
  '󠄅',
  '󠄆',
  '󠄇',
  '󠄈',
  '󠄉',
  '󠄊',
  '󠄋',
  '󠄌',
  '󠄍',
  '󠄎',
  '󠄏',
  '󠄐',
  '󠄑',
  '󠄒',
  '󠄓',
  '󠄔',
  '󠄕',
  '󠄖',
  '󠄗',
  '󠄘',
  '󠄙',
  '󠄚',
  '󠄛',
  '󠄜',
  '󠄝',
  '󠄞',
  '󠄟',
  '󠄠',
  '󠄡',
  '󠄢',
  '󠄣',
  '󠄤',
  '󠄥',
  '󠄦',
  '󠄧',
  '󠄨',
  '󠄩',
  '󠄪',
  '󠄫',
  '󠄬',
  '󠄭',
  '󠄮',
  '󠄯',
  '󠄰',
  '󠄱',
  '󠄲',
  '󠄳',
  '󠄴',
  '󠄵',
  '󠄶',
  '󠄷',
  '󠄸',
  '󠄹',
  '󠄺',
  '󠄻',
  '󠄼',
  '󠄽',
  '󠄾',
  '󠄿',
  '󠅀',
  '󠅁',
  '󠅂',
  '󠅃',
  '󠅄',
  '󠅅',
  '󠅆',
  '󠅇',
  '󠅈',
  '󠅉',
  '󠅊',
  '󠅋',
  '󠅌',
  '󠅍',
  '󠅎',
  '󠅏',
  '󠅐',
  '󠅑',
  '󠅒',
  '󠅓',
];
