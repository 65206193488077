import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-candidat/environment';
import {
  Structure,
  MembreEquipe,
  Contact,
  DemandeRectification,
  SignataireInterface,
  SyntheseContractualisationStructure,
} from '@shared-ui';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class StructureService {
  structureUrl = environment.apiUrl + 'structures';
  notifUrl = environment.apiNotif;
  private structureSubject = new BehaviorSubject(null);
  private structuresListSubject = new BehaviorSubject(null);

  public setStructureObservable(structure: Structure): void {
    this.structureSubject.next(structure);
  }

  public getStructureObservable(): Observable<Structure> {
    return this.structureSubject.asObservable();
  }

  public setStructuresListObservable(structures: Structure[]): void {
    this.structuresListSubject.next(structures);
  }

  public setUniqueStructureListObservable(structure: Structure, structures: Structure[]): void {
    const index = structures.findIndex(struct => {
      return struct.id === structure.id;
    });
    const newList = structures;
    newList[index] = structure;
    this.setStructuresListObservable(newList);
  }

  public getStructuresListObservable(): Observable<Structure[]> {
    return this.structuresListSubject.asObservable();
  }

  constructor(private httpClient: HttpClient) {}

  getStructures(): Observable<HttpResponse<Structure[]>> {
    return this.httpClient.get<Structure[]>(this.structureUrl, { observe: 'response' });
  }

  getStructuresByIdProject(idProject: string): Observable<HttpResponse<Structure[]>> {
    return this.httpClient.get<Structure[]>(this.structureUrl, {
      params: { idProjet: idProject },
      observe: 'response',
    });
  }

  addStructure(structure: Structure): Observable<HttpResponse<Structure>> {
    if (structure.siret) {
      structure.siret = structure.siret.replace(/\s/g, '');
    }
    return this.httpClient.post<Structure>(this.structureUrl, JSON.stringify(structure), { observe: 'response' });
  }

  updateStructure(structure: Structure): Observable<HttpResponse<Structure>> {
    if (structure.siret) {
      structure.siret = structure.siret.replace(/\s/g, '');
    }
    if (structure.lieuRD?.siret) {
      structure.lieuRD.siret = structure.lieuRD.siret.replace(/\s/g, '');
    }
    if (structure.budgetPreDepot?.montant) {
      structure.budgetPreDepot.montant = structure.budgetPreDepot.montant ?? '0';
    }
    if (structure.budgetDepot?.montant) {
      structure.budgetDepot.montant = structure.budgetDepot.montant ?? '0';
    }

    return this.httpClient.put<Structure>(this.structureUrl, JSON.stringify(structure), { observe: 'response' });
  }

  updateMembreEquipeStructure(structureId: string, membreEquipe: MembreEquipe): Observable<HttpResponse<Structure>> {
    return this.httpClient.put<Structure>(this.structureUrl + '/' + structureId + '/equipe', JSON.stringify(membreEquipe), {
      observe: 'response',
    });
  }

  getStructureById(idStructure: string): Observable<HttpResponse<Structure>> {
    return this.httpClient.get<Structure>(this.structureUrl + '/' + idStructure, { observe: 'response' });
  }

  deleteStructure(idStructure: string, structure: Structure): Observable<object> {
    return this.httpClient.request('delete', this.structureUrl + '/' + idStructure, {
      body: JSON.stringify(structure),
      observe: 'response',
    });
  }

  addContactStructure(idStructure: string, contact: Contact): Observable<HttpResponse<Structure>> {
    return this.httpClient.post<Structure>(this.structureUrl + '/' + idStructure + '/contacts', JSON.stringify(contact), {
      observe: 'response',
    });
  }

  updateContactStructure(idStructure: string, contact: Contact, idContact: string): Observable<HttpResponse<Structure>> {
    return this.httpClient.put<Structure>(this.structureUrl + '/' + idStructure + '/contacts/' + idContact, JSON.stringify(contact), {
      observe: 'response',
    });
  }

  deleteContactStructure(idStructure: string, idContact: string): Observable<Structure> {
    return this.httpClient.delete<Structure>(this.structureUrl + '/' + idStructure + '/contacts/' + idContact);
  }
  updateStructureDemandeDeRectification(
    structureId: string,
    demandeRectification: DemandeRectification
  ): Observable<HttpResponse<Structure>> {
    return this.httpClient.patch<Structure>(
      this.structureUrl + '/' + structureId + '/adresse/demande-rectification',
      JSON.stringify(demandeRectification),
      {
        observe: 'response',
      }
    );
  }

  disableContactStructure(idStructure: string, idContact: string): Observable<Structure> {
    return this.httpClient.post<Structure>(this.structureUrl + '/' + idStructure + '/contacts/' + idContact + '/desactiver', null);
  }

  reinvitationContactStructure(idStructure: string, idContact: string): Observable<Structure> {
    return this.httpClient.post<Structure>(this.structureUrl + '/' + idStructure + '/contacts/' + idContact + '/reactiver', null);
  }

  saveSignataire(idStructure: string, signataire: SignataireInterface): Observable<HttpResponse<SignataireInterface>> {
    return this.httpClient.patch<SignataireInterface>(
      `${this.structureUrl}/${idStructure}/contractualisation/signataires`,
      JSON.stringify(signataire),
      {
        observe: 'response',
      }
    );
  }

  getSignataires(idStructure: string): Observable<HttpResponse<SignataireInterface[]>> {
    return this.httpClient.get<SignataireInterface[]>(`${this.structureUrl}/${idStructure}/contractualisation/signataires`, {
      observe: 'response',
    });
  }

  getSyntheseContractualisationStructures(projetId: string): Observable<HttpResponse<SyntheseContractualisationStructure[]>> {
    return this.httpClient.get<SyntheseContractualisationStructure[]>(`${this.structureUrl}/contractualisation/synthese`, {
      params: { projetId: projetId },
      observe: 'response',
    });
  }
}
