import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments-candidat/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@services-candidat/user.service';
import { CguModalComponent } from '@shared-candidat/components/modals/cgu-modal/cgu-modal.component';
import { PxlOidcService } from '@services-candidat/pxl-oidc.service';
import { EnumAuthConfigurationType, Utilisateur } from '@shared-ui';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard {
  utilisateur: Utilisateur = new Utilisateur();
  url: string;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    private oidcSecurityService: PxlOidcService
  ) {}

  canActivate(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated$.pipe(
      map((isAuthorized: boolean) => {
        if (!isAuthorized && !environment.stubAuth) {
          return false;
        } else {
          this.userService.getUserObservableForCGU().subscribe((response: any) => {
            this.utilisateur = response;
            if (this.utilisateur && !this.utilisateur.cgus[this.utilisateur.cgus.length - 1].dateValidation) {
              const dialogRef = this.dialog.open(CguModalComponent, {
                data: {
                  title: 'Les CGU évoluent',
                  textGoButton: 'Accepter',
                  textReturnButton: 'Refuser',
                },
              });

              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  this.utilisateur.cgus[this.utilisateur.cgus.length - 1].dateValidation = Date.now();
                  this.userService.setUserObservable(this.utilisateur);
                  this.userService.putCguUser().subscribe();
                } else {
                  this.deconnexion();
                }
              });
            }
          });
          return true;
        }
      })
    );
  }

  private deconnexion() {
    const configId = localStorage.getItem('idp');
    if (configId === EnumAuthConfigurationType.ISAM) {
      const x = window.open(environment.isam.stsServer + '/pkmslogout', '_blank');
      this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
        x.blur();
        x.close();
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
      });
    } else {
      this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {});
    }
  }
}
