import { HttpErrorResponse, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments-candidat/environment';
import { AapService } from '@services-candidat/aap.service';
import { DepensesService } from '@services-candidat/depenses.service';
import { ProjetService } from '@services-candidat/projet.service';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  Aap,
  ConfirmModalComponent,
  Depenses,
  DocumentProjet,
  EnumValidation,
  LotModel,
  Projet,
  ShowToastrService,
  SubscriptionDestroyerComponent,
  URL_DEPENSES_TEMPLATE,
} from '@shared-ui';
import { Observable, concatMap, take, tap } from 'rxjs';
import { ProjetInfoDepensesSharedService } from './projet-info-depenses-shared.service';
import { ProjetInfoDepensesHelperService as Helper } from './projet-info-depenses.helper.service';

declare const dsLib: any;

@Component({
  selector: 'app-projet-info-depenses',
  templateUrl: './projet-info-depenses.component.html',
  styleUrls: ['./projet-info-depenses.component.scss'],
})
export class ProjetInfoDepensesComponent extends SubscriptionDestroyerComponent implements OnInit {
  projet: Projet = new Projet();
  aap: Aap = new Aap();
  enumValidation = EnumValidation;
  tabs = new dsLib.Tabs('dstab');
  currentTab = 'calendar';
  currentTabValid = false;
  expensesForm: UntypedFormGroup;
  expenses: Depenses = new Depenses();
  isLoaded = false;
  syntheseNonValidable = false;
  documentBdd: DocumentProjet;
  afficherOnglets = false;
  isLoading = false;
  validateClicked = false;

  readonly VALIDE = (EnumValidation as any)[EnumValidation.VALIDE.toString()];
  readonly NON_VALIDE = (EnumValidation as any)[EnumValidation.NON_VALIDE.toString()];

  constructor(
    public projetService: ProjetService,
    public depensesService: DepensesService,
    private aapService: AapService,
    private router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    public matDialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    public sharedFunction: SharedFunction,
    public sharedService: ProjetInfoDepensesSharedService
  ) {
    super();
  }

  ngOnInit(): void {
    this.detectDevice();
    this.getProjet();
    this.getExpenses();
    this.getExpensesDoc();
  }

  private detectDevice(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.modalCheckDevice();
    }
  }

  private modalCheckDevice(): void {
    const projetId = this.route.snapshot.parent.params.id;
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: 'Attention: Fonctionnalité Renseigner  Dépenses  Projet Non Responsive.',
        description: `<p>La fonctionnalité Renseigner les Dépenses du Projet n'est pas conçue pour ce type d'écran. Pour une meilleure expérience, veuillez utiliser un appareil avec une résolution d'écran plus grande.</p>
        <p>Merci de votre compréhension,<br />
        Equipe projet.</p>`,
        textGoButton: 'OK',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['projet-creation', projetId, 'projet-apercu']);
      }
    });
  }

  /*
   * Cette méthode récupère le projet
   * */
  getProjet(): void {
    this.projetService
      .getProjetObservable()
      .pipe(this.takeUntilDestroyed(), concatMap(this.processProjectResponse.bind(this)))
      .subscribe({
        next: this.processAapResponse.bind(this),
        error: this.handleHttpError.bind(this),
      });
  }

  getExpenses(): void {
    const projetId = this.route.snapshot.parent.params.id;

    this.depensesService
      .getExpensesByProjetId(projetId)
      .pipe(
        take(1),
        tap({
          finalize: () => (this.isLoaded = true),
        })
      )
      .subscribe({
        next: (resp: HttpResponse<Depenses>) => {
          this.expenses = resp.body;
          this.expensesForm?.patchValue({
            synthese: this.expenses.lots,
          });
          this.expensesForm.setControl('structures', Helper.getStructuresForm(this.expenses.structures, this.projet.structures));
          this.expensesForm.setControl('calendrier', Helper.getCalendrierForm(this.expenses.calendrier));
          this.selectTab(this.currentTab);
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.NotFound) {
            this.expenses.projetId = projetId;
            return;
          }
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  getExpensesDoc(): void {
    const projetId = this.projet.id;

    this.depensesService
      .getExpensesDocument(projetId)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (resp: HttpResponse<DocumentProjet[]>) => {
          this.documentBdd = resp.body[0];
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  onDocChanged(docChanged: DocumentProjet): void {
    this.documentBdd = docChanged;
    this.selectTab(this.currentTab);
  }

  processProjectResponse(responseProjet: Projet): Observable<HttpResponse<Aap>> {
    if (responseProjet) {
      this.projet = responseProjet;
      return this.aapService.loadAapSubject();
    }
  }

  processAapResponse(responseAap: HttpResponse<Aap>): void {
    if (responseAap) {
      this.aap = responseAap.body;
      this.createForms();
    }
  }

  handleHttpError(err: HttpErrorResponse): void {
    this.showToastrService.checkCodeError(err?.error);
  }

  createForms(): void {
    this.expensesForm = this.formBuilder.group({
      calendrier: Helper.getCalendrierForm(undefined),
      structures: Helper.getStructuresForm(undefined, this.projet.structures),
      synthese: this.expenses.lots,
    });
  }
  selectTab(site: string) {
    this.syntheseNonValidable = false;
    if (site === 'synthese') {
      this.isLoading = true;
    }
    this.currentTab = site;

    switch (this.currentTab) {
      case 'calendar':
        this.currentTabValid = this.expenses.calendrier?.statut === this.VALIDE;
        if (this.currentTabValid) {
          this.expensesForm?.get('calendrier')?.disable();
        } else {
          this.expensesForm?.get('calendrier')?.enable();
        }
        break;
      case 'structure':
        this.currentTabValid = this.expenses.structures?.statut === this.VALIDE;
        if (this.currentTabValid) {
          this.expensesForm?.get('structures')?.disable();
        } else {
          this.expensesForm?.get('structures')?.enable();
        }
        break;
      case 'download':
        this.currentTabValid = !!this.documentBdd?.id;
        break;
      case 'synthese':
        this.currentTabValid = this.expenses.statut === this.VALIDE;
        this.sharedService.setIsSyntheseFormValid(this.currentTabValid);
        if (this.expenses.calendrier?.statut !== this.VALIDE || this.expenses.structures?.statut !== this.VALIDE) {
          this.syntheseNonValidable = true;
        }
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
        break;
    }
  }

  cancelBtn(): void {
    if (this.currentTab === 'calendar') {
      const dialogRef = this.matDialog.open(ConfirmModalComponent, {
        data: {
          title: 'Annulation du calendrier',
          description: `<p>Vous avez demandé d'annuler votre calendrier de projet. Vos modifications seront perdues.</p>`,
          textGoButton: 'Confirmer',
          textReturnButton: 'Annuler',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.onGoToProjetInfo();
        }
      });
    } else {
      const dialogRef = this.matDialog.open(ConfirmModalComponent, {
        data: {
          title: 'Annulation',
          description: `<p>La validation de cette action entraînera la perte de toutes les informations.</p>`,
          textGoButton: 'Confirmer',
          textReturnButton: 'Annuler',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.onGoToProjetInfo();
        }
      });
    }
  }

  saveBtn(validation?: boolean): void {
    switch (this.currentTab) {
      case 'calendar':
        this.saveCalendar(validation);
        break;
      case 'structure':
        this.saveStructure(validation);
        break;
      case 'synthese':
        this.saveSynthese(validation);
        break;
    }
  }

  private saveCalendar(validation: boolean) {
    this.expenses.calendrier = Helper.getCalendrierDTO(this.expensesForm?.get('calendrier') as FormGroup);

    this.depensesService.updateExpensesCalendar(this.expenses).subscribe({
      next: (resp: HttpResponse<Depenses>) => {
        this.expenses = resp.body;
        if (validation) {
          this.validDepensesCalendarStatut();
        }
        this.selectTab(this.currentTab);
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  private saveStructure(validation: boolean) {
    this.expenses.structures = Helper.getStructuresBddDTO(this.expensesForm.get('structures') as FormGroup);
    if (validation) {
      this.expenses.structures.statut = this.VALIDE;
    }
    this.depensesService.updateExpensesStructures(this.expenses).subscribe({
      next: (resp: HttpResponse<Depenses>) => {
        this.expenses = resp.body;
        if (validation) {
          this.validDepensesStructureStatut();
        }
        this.selectTab(this.currentTab);
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  private saveSynthese(validation: boolean): void {
    if (this.expensesForm.get('synthese').valid && this.expensesForm.get('synthese').value) {
      this.expenses.lots = this.expensesForm.get('synthese').value;
      this.depensesService.createExpenses(this.expenses).subscribe({
        next: (resp: HttpResponse<Depenses>) => {
          this.expenses = resp.body;
          if (validation) {
            this.validDepensesSyntheseStatut();
          }
          this.selectTab(this.currentTab);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    }
  }

  validBtn(): void {
    setTimeout(() => (this.validateClicked = true), 0);
    this.validateClicked = true;
    switch (this.currentTab) {
      case 'calendar':
        if (this.expensesForm?.get('calendrier')?.valid) {
          this.saveBtn(true);
        } else {
          this.expensesForm?.get('calendrier')?.markAllAsTouched();
        }
        break;
      case 'structure':
        if (this.expensesForm?.get('structures')?.valid) {
          this.saveBtn(true);
        } else {
          this.expensesForm?.get('structures')?.markAllAsTouched();
        }
        break;
      case 'synthese':
        if (this.expensesForm.get('synthese').valid) {
          this.saveBtn(true);
        } else {
          this.expensesForm.get('synthese').markAllAsTouched();
        }
        break;
    }
    this.validateClicked = false;
  }

  validDepensesCalendarStatut(): void {
    this.depensesService.updateExpensesCalendarStatut(this.expenses, this.VALIDE).subscribe({
      next: (resp: HttpResponse<Depenses>) => {
        this.expenses = resp.body;
        this.selectTab(this.currentTab);
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  validDepensesStructureStatut(): void {
    this.depensesService.updateExpensesStructureStatut(this.expenses, this.VALIDE).subscribe({
      next: (resp: HttpResponse<Depenses>) => {
        this.expenses = resp.body;
        this.selectTab(this.currentTab);
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  validDepensesSyntheseStatut(): void {
    this.depensesService.updateExpensesSyntheseStatut(this.expenses, this.VALIDE).subscribe({
      next: (resp: HttpResponse<Depenses>) => {
        this.expenses = resp.body;
        this.selectTab(this.currentTab);
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  modifyBtn(): void {
    if (this.currentTab === 'download') {
      return;
    }

    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: 'Annulation',
        description: `<p>Vous êtes sur le point de modifier les données existantes.
          Cette action nécessite une validation de votre part, et toute modification sera définitive. Souhaitez-vous continuer ?</p>`,
        textGoButton: 'Confirmer',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      switch (this.currentTab) {
        case 'calendar':
          this.expenses.calendrier.statut = this.NON_VALIDE;
          this.depensesService.updateExpensesCalendar(this.expenses).subscribe({
            next: (resp: HttpResponse<Depenses>) => {
              this.expenses = resp.body;
              this.selectTab(this.currentTab);
            },
            error: (err: HttpErrorResponse) => {
              this.showToastrService.checkCodeError(err?.error);
            },
          });
          break;
        case 'structure':
          this.expenses.structures.statut = this.NON_VALIDE;
          this.depensesService.updateExpensesStructures(this.expenses).subscribe({
            next: (resp: HttpResponse<Depenses>) => {
              this.expenses = resp.body;
              this.selectTab(this.currentTab);
            },
            error: (err: HttpErrorResponse) => {
              this.showToastrService.checkCodeError(err?.error);
            },
          });
          break;
        case 'synthese':
          this.expenses.statut = this.NON_VALIDE;
          this.depensesService.updateExpensesSyntheseStatut(this.expenses, this.NON_VALIDE).subscribe({
            next: (resp: HttpResponse<Depenses>) => {
              this.expenses = resp.body;
              this.selectTab(this.currentTab);
            },
            error: (err: HttpErrorResponse) => {
              this.showToastrService.checkCodeError(err?.error);
            },
          });
          break;
        default:
          return;
      }
    });
  }

  /*
   * Cette méthode permet de retourner à la page d'information projet
   * */
  onGoToProjetInfo(): void {
    this.router.navigate(['../projet-info'], { relativeTo: this.route });
  }

  updateFormWithLots($event: { isSideNav: boolean; lots: LotModel[] }) {
    this.expensesForm.patchValue({ synthese: $event.lots });
    if ($event.isSideNav) {
      this.saveBtn();
    }
  }

  public onExpensesChanged($event: Depenses): void {
    this.expenses = $event;
    this.expensesForm?.patchValue({ synthese: $event.lots });
  }

  downloadTemplate(): void {
    window.open(environment.docPublicBaseUrl + URL_DEPENSES_TEMPLATE);
  }

  setAfficherOnglets(value: boolean): void {
    setTimeout(() => (this.isLoading = false), 0);
    this.afficherOnglets = value;
    this.isLoading = true;
  }
}
