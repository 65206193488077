import { HttpHeaders } from '@angular/common/http';

export class DownloadUtils {
  download(data: Blob, filename: string): void {
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(data);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      // if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  extractFileNameFromHeaders(headers: HttpHeaders): string {
    const contentDisposition = headers.get('Content-Disposition');
    const filename = contentDisposition?.split(';')[1].split('=')[1].replace(/"/g, '');
    return decodeURIComponent(filename || 'filename');
  }
}
