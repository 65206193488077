import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@environments-candidat/environment';
import { Projet } from '@shared-ui';

@Injectable()
export class PoleService {
  projetUrl = environment.apiUrl + 'projets';
  notifUrl = environment.apiNotif;

  constructor(private httpClient: HttpClient) {}

  getAapPoles(aapId: string): Observable<HttpResponse<any[]>> {
    return this.httpClient.get<any[]>(environment.apiUrl + 'aaps/' + aapId + '/poles/list', { observe: 'response' });
  }

  assignerPolesProjet(projetId: string, polesIds: string[]): Observable<Projet> {
    return this.httpClient.put<Projet>(environment.apiUrl + 'poles/projets/' + projetId + '/assign-pole?ids=' + polesIds.toString(), {
      observe: 'response',
    });
  }

  getProjetPoles(projetId: string): Observable<HttpResponse<Projet>> {
    return this.httpClient.get<any>(environment.apiUrl + 'poles/projets/' + projetId + '/list', { observe: 'response' });
  }
}
