import { DocumentAapModel } from '../shared/models/document-aap.model';
import {
  DocumentProjet,
  EnumDocumentComplementaireType,
  EnumProjetEtape,
  EnumScope,
  EnumTypeDocument,
  Projet,
  UploadDocModalDocumentModel,
  Utilisateur,
} from '@shared-ui';

export class DocumentHelper {
  public static readonly TYPE_VIDEO = 'video';
  public static readonly TYPE_B401 = 'B401';
  public static readonly VIDEO_ROOT_PATH = 'videos/';
  public static readonly BUCKET_VIDEO = 'DOCUMENT_PRIVATE_VIDEO';
  public static readonly BUCKET_PRIVATE = 'DOCUMENT_PRIVATE';

  public static buildDocumentProjetWithEtape(
    fileToUpload: File,
    projet: Projet,
    etape: EnumProjetEtape,
    utilisateur: Utilisateur,
    document?: UploadDocModalDocumentModel
  ): DocumentProjet {
    const documentProjet = DocumentHelper.buildDocumentProjet(fileToUpload, projet, utilisateur, document);
    documentProjet.etape = etape;
    return documentProjet;
  }

  public static buildDocumentProjet(
    fileToUpload: File,
    projet: Projet,
    utilisateur: Utilisateur,
    document?: UploadDocModalDocumentModel
  ): DocumentProjet {
    const documentProjet: DocumentProjet = new DocumentProjet();
    documentProjet.typeDoc = this.getTypeDoc(document);
    if (documentProjet.typeDoc === EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE) {
      documentProjet.type = document?.nom ? (EnumDocumentComplementaireType.toKey(document?.nom) as string) : '';
    } else {
      documentProjet.type = document?.nom ?? '';
    }
    documentProjet.demandeComplementId = document?.demandeComplementId;
    documentProjet.nom = fileToUpload.name;
    documentProjet.dateModification = projet.dateModification;
    documentProjet.projetId = projet.id;
    documentProjet.scope = EnumScope.PROJET;
    return documentProjet;
  }

  private static getTypeDoc(document?: UploadDocModalDocumentModel): string {
    return document?.demandeComplementId
      ? EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE
      : DocumentHelper.isVideoMediaTypes(document?.mediaTypes)
      ? EnumTypeDocument.MEDIA_VIDEO
      : EnumTypeDocument.PROJET;
  }

  public static getS3Path(projet: Projet, document: DocumentProjet): string {
    const videoDocEnable = DocumentHelper.isVideoDocument(document);
    const root = videoDocEnable ? DocumentHelper.VIDEO_ROOT_PATH : '';
    return root + projet.id + '/' + document.id;
  }

  public static getUploadDocumentType(document: DocumentProjet): string {
    const videoDocEnable = DocumentHelper.isVideoDocument(document);
    return videoDocEnable ? DocumentHelper.TYPE_VIDEO : DocumentHelper.TYPE_B401;
  }

  public static isVideoDocument(document: DocumentProjet): boolean {
    return document.typeDoc === EnumTypeDocument.MEDIA_VIDEO;
  }

  public static isVideoDocumentAap(documentAap?: DocumentAapModel): boolean {
    return documentAap?.mediaTypes?.includes(EnumTypeDocument.MEDIA_VIDEO) ?? false;
  }

  public static isVideoMediaTypes(mediaTypes?: EnumTypeDocument[]): boolean {
    return mediaTypes?.includes(EnumTypeDocument.MEDIA_VIDEO) ?? false;
  }

  public static getDocumentBucketType(typeUpload: string): string {
    return typeUpload === DocumentHelper.TYPE_VIDEO ? DocumentHelper.BUCKET_VIDEO : DocumentHelper.BUCKET_PRIVATE;
  }

  public static getDocumentS3FolderPath(document: DocumentProjet) {
    if (document.typeDoc == EnumTypeDocument.CONTRAT) {
      return "contrats/" + document.nom;
    }

    const racines: string[] = [];
    racines.push(document.projetId);

    if (this.isScopeStructure(document.scope)) {
      racines.push(document.structureId);
    }

    if (document.typeDoc == EnumTypeDocument.JUSTIFICATIF_FLAMINEM) {
      racines.push("justificatif_connaissance_client");
    }

    if (document.typeDoc == EnumTypeDocument.RIB) {
      racines.push("rib");
    }

    if (document.typeDoc == EnumTypeDocument.JUSTIFICATIF_VALIDATION_RIB) {
      racines.push("justificatif_validation_rib");
    }

    if (document.typeDoc == EnumTypeDocument.RAPPORT_INSTRUCTION) {
      racines.push("rapport_instruction");
    }

    // add others such as notation if needed

    racines.push(document.id);

    return racines.join("/");
  }

  public static getDocumentS3FilePath(document: DocumentProjet) {
    return this.getDocumentS3FolderPath(document) + "/" + document.nom;
  }

  private static isScopeStructure(scope: EnumScope): boolean {
    return scope === EnumScope.STRUCTURE || scope === EnumScope.STRUCTURE_ACTIONARIAL;
  }

  /*
   * Affiche le nom du créateur du Document
   * */
  static getNomCreateurDocument(document: DocumentProjet): string {
    if (document.nomCreateur && document.nomCreateur !== 'undefined undefined' && document.nomCreateur.length > 0) {
      return 'par ' + document.nomCreateur;
    } else {
      return '';
    }
  }
}
