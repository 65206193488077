import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccueilComponent } from './accueil.component';
import { SharedModule } from '@shared-candidat/shared.module';

@NgModule({
  declarations: [AccueilComponent],
  imports: [CommonModule, SharedModule],
})
export class AccueilModule {}
