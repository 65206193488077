export const polesMocks: any = [{ nom: 'Alsace BIOVALLEY' }, { nom: 'AEROSPACE VALLEY' }, { nom: 'Booster NOVA - Open Data' }];

export const aapPolesMocks: any = [
  {
    siret: 'siret1',
    autreRegionIntervention: 'autreRegionIntervention',
    nomCommercial: '10',
    active: true,
    domaineTechnologiquePrincipaux: ['domaine1', 'domaine2'],
    autresDomaineTechnologiques: ['domaine1', 'domaine2'],
    emails: [
      {
        address: null,
        active: false,
      },
    ],
    id: '61f9818b9c85d27e878e24a7',
    dateCreation: '2022-02-01T18:52:59.436Z',
    dateModification: '2022-02-01T18:52:59.436Z',
  },
  {
    siret: 'siret1',
    autreRegionIntervention: 'autreRegionIntervention',
    nomCommercial: '10',
    active: true,
    domaineTechnologiquePrincipaux: ['domaine1', 'domaine2'],
    autresDomaineTechnologiques: ['domaine1', 'domaine2'],
    emails: [
      {
        address: null,
        active: false,
      },
    ],
    id: '61f9818b9c85d27e878e24a7',
    dateCreation: '2022-02-01T18:52:59.436Z',
    dateModification: '2022-02-01T18:52:59.436Z',
  },
  {
    siret: 'siret1',
    autreRegionIntervention: 'autreRegionIntervention',
    nomCommercial: '10',
    active: true,
    domaineTechnologiquePrincipaux: ['domaine1', 'domaine2'],
    autresDomaineTechnologiques: ['domaine1', 'domaine2'],
    emails: [
      {
        address: null,
        active: false,
      },
    ],
    id: '61f9818b9c85d27e878e24a7',
    dateCreation: '2022-02-01T18:52:59.436Z',
    dateModification: '2022-02-01T18:52:59.436Z',
  },
  {
    siret: 'siret1',
    autreRegionIntervention: 'autreRegionIntervention',
    nomCommercial: '10',
    active: true,
    domaineTechnologiquePrincipaux: ['domaine1', 'domaine2'],
    autresDomaineTechnologiques: ['domaine1', 'domaine2'],
    emails: [
      {
        address: null,
        active: false,
      },
    ],
    id: '61f9818b9c85d27e878e24a7',
    dateCreation: '2022-02-01T18:52:59.436Z',
    dateModification: '2022-02-01T18:52:59.436Z',
  },
  {
    siret: 'siret1',
    autreRegionIntervention: 'autreRegionIntervention',
    nomCommercial: '10',
    active: true,
    domaineTechnologiquePrincipaux: ['domaine1', 'domaine2'],
    autresDomaineTechnologiques: ['domaine1', 'domaine2'],
    emails: [
      {
        address: null,
        active: false,
      },
    ],
    id: '61f9818b9c85d27e878e24a7',
    dateCreation: '2022-02-01T18:52:59.436Z',
    dateModification: '2022-02-01T18:52:59.436Z',
  },
];
