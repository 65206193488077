<lib-expansion-panel [title]="'Informations du projet'" [showValidIcon]="showValidIcon()" [showMandatoryAsterisk]="true">
  <div panel-content>
    <div class="w-100">
      <lib-card-info
        message="Merci de vérifier que les images chargées dans cette section s’affichent correctement (bonne qualité, non tronquée,...), ces
          dernières seront reprises dans la fiche de communication"
      ></lib-card-info>
    </div>
    <form class="form" [formGroup]="infoProjetForm" (ngSubmit)="onInfoProjetSubmitted()">
      <div class="d-flex flex-wrap">
        <!-- Description -->
        <div
          class="ds-input-group w-100"
          [class.ds-input-group--error]="!infoProjetForm.controls['description'].valid && infoProjetForm.controls['description'].touched"
        >
          <label for="description" class="ds-input-group__label fw-bold">
            Description du projet
            <lib-asterisk></lib-asterisk>
          </label>
          <span class="ds-input-group__input">
            <input
              id="description"
              name="description"
              type="text"
              [maxlength]="maxLengthDescription"
              class="ds-input-group__input"
              formControlName="description"
              placeholder="Description synthétique du projet à destination d'une communication publique"
              #descriptionInput
            />
          </span>
          <div
            class="form-input__length-hint"
            [class.form-input__length-hint--full]="descriptionInput.value?.length === maxLengthDescription"
          >
            <span>{{ descriptionInput.value?.length || 0 }}/{{ maxLengthDescription }}</span>
          </div>

          <!-- Icône et message d'erreur -->
          <ng-container *ngIf="infoProjetForm.controls['description'].invalid && infoProjetForm.controls['description'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
          </ng-container>
        </div>
        <!-- FIN de Description -->

        <!-- Objectifs du projet -->
        <div
          id="input-objectifs"
          class="form-step__objectifs ds-input-group w-100"
          [class.ds-input-group--error]="!infoProjetForm.controls['objectifs'].valid && infoProjetForm.controls['objectifs'].touched"
        >
          <label for="objectifs" class="ds-input-group__label fw-bold">Objectifs du projet<lib-asterisk></lib-asterisk></label>
          <textarea
            id="creation_projet_objectifs"
            name="objectifs"
            rows="5"
            col="0"
            [maxlength]="maxLengthObjectifs"
            pattern="(\n|.)*\S+(\n|.)*"
            placeholder="Décrire les objectifs du projet à destination d'une communication publique"
            class="ds-input-group__textarea"
            formControlName="objectifs"
            #objectifsInput
          ></textarea>
          <div class="form-input__length-hint" [class.form-input__length-hint--full]="objectifsInput.value?.length === maxLengthObjectifs">
            <span>{{ objectifsInput.value?.length || 0 }}/{{ maxLengthObjectifs }}</span>
          </div>
          <!-- Icône et message d'erreur -->
          <ng-container
            id="error-objectifs"
            *ngIf="!infoProjetForm.controls['objectifs'].valid && infoProjetForm.controls['objectifs'].touched"
          >
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
          </ng-container>
        </div>
        <!-- FIN de Objectifs du projet -->

        <!-- Fonctionnement du consortium -->
        <div
          *ngIf="projet?.partenaireType === EnumTypePartenaire.MULTI"
          id="input-description-consortium"
          class="form-step__description-consortium ds-input-group w-100"
          [class.ds-input-group--error]="
            !infoProjetForm.controls['fonctionnementConsortium'].valid && infoProjetForm.controls['fonctionnementConsortium'].touched
          "
        >
          <label for="fonctionnementConsortium" class="ds-input-group__label fw-bold"
            >Fonctionnement du consortium<lib-asterisk></lib-asterisk
          ></label>
          <textarea
            id="creation_projet_description-consortium"
            name="fonctionnementConsortium"
            rows="5"
            col="0"
            [maxlength]="maxLengthDescriptionConsortium"
            pattern="(\n|.)*\S+(\n|.)*"
            placeholder="Décrire le fonctionnement du consortium"
            class="ds-input-group__textarea"
            formControlName="fonctionnementConsortium"
            #fonctionnementConsortiumInput
          ></textarea>
          <div
            class="form-input__length-hint"
            [class.form-input__length-hint--full]="fonctionnementConsortiumInput.value?.length === maxLengthDescriptionConsortium"
          >
            <span>{{ fonctionnementConsortiumInput.value?.length || 0 }}/{{ maxLengthDescriptionConsortium }}</span>
          </div>
          <!-- Icône et message d'erreur -->
          <ng-container
            id="error-description"
            *ngIf="
              !infoProjetForm.controls['fonctionnementConsortium'].valid && infoProjetForm.controls['fonctionnementConsortium'].touched
            "
          >
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
          </ng-container>
        </div>
        <!-- FIN de Fonctionnement du consortium -->
        <!-- Logo projet -->
        <div class="fw-bold">Image du projet<lib-asterisk></lib-asterisk></div>
        <div
          id="logotype"
          class="form-step__logotype file-upload ds-input-group w-100"
          [class.ds-input-group--error]="!infoProjetForm.controls['logo'].valid && infoProjetForm.controls['logo'].touched"
        >
          <div id="info-upload-fichier" class="file-upload__hint" *ngIf="!imgInfoProjet.id">
            Poids maximum par fichier 17Mo. Taille requise : 340x226px. Format requis : PNG, JPEG.<br />
          </div>
          <label class="file-upload__custom-input">
            <input type="file" id="file" *ngIf="!imgInfoProjet.id" (change)="onUpload($event.target)" accept=".jpg, .png" />
            <input type="hidden" name="fileHidden" formControlName="logo" />
            <div id="add-fichier" class="ds-btn ds-btn--primary" *ngIf="!imgInfoProjet.id && canUserWrite">Ajouter un fichier</div>
            <div *ngIf="imgInfoProjet.id" class="sidenav-projet__logo">
              <img [alt]="imgInfoProjet.nom" [src]="imagePath" />
            </div>
          </label>
          <div id="logo-value" class="file-upload__hint file-value" *ngIf="imgInfoProjet.id">
            <div class="doc-infos">
              <span>{{ imgInfoProjet?.nom }}</span>
              <br />
              <span *ngIf="imgInfoProjet.dateCreation !== null"
                >Chargé le {{ imgInfoProjet?.dateCreation | date : 'dd/MM/yyyy' }} {{ getNomCreateurDocument(imgInfoProjet) }}</span
              >
            </div>
            <div class="doc-actions">
              <div class="card__action-button-wrapper">
                <button
                  type="button"
                  id="download-document"
                  class="card__action-button"
                  [ngClass]="{
                    'card__action-button--error': isScanedDocument(imgInfoProjet) && imgInfoProjet.scan !== SAFE
                  }"
                  (click)="downloadDocument(imgInfoProjet)"
                  [disabled]="!isScanedDocument(imgInfoProjet) || imgInfoProjet.scan !== SAFE"
                >
                  <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
                </button>
                <div class="card__action-button-tooltip" *ngIf="!isScanedDocument(imgInfoProjet)">
                  <p>Le test antimalware est en cours.</p>
                </div>
                <div class="card__action-button-tooltip" *ngIf="isScanedDocument(imgInfoProjet) && imgInfoProjet.scan !== SAFE">
                  <p>Ce fichier a été identifié comme étant potentiellement dangereux et ne peut donc pas être téléchargé.</p>
                </div>
              </div>
              <div class="card__action-button-wrapper" *ngIf="canUserWrite && !isValideFicheCom">
                <button type="button" id="delete-document" class="card__action-button" (click)="onDeleteDocument(imgInfoProjet)">
                  <lib-pxl-icon class="icon" icon="icon-action-delete"></lib-pxl-icon>
                </button>
              </div>
            </div>
          </div>
          <div id="error-logo-type" class="file-upload__error" *ngIf="fileNotSupported">
            Le type du document sélectionné n'est pas supporté, merci de sélectionner un autre fichier.
          </div>
          <div id="error-logo-size" class="file-upload__error" *ngIf="fileTooBig">
            Le fichier sélectionné est trop lourd, merci de sélectionner un autre fichier.
          </div>
        </div>
        <!-- Icône et message d'erreur -->
        <ng-container id="error-logo" *ngIf="!infoProjetForm.controls['logo'].valid && infoProjetForm.controls['logo'].touched">
          <div class="ds-input-group__feedback error-logo">Merci de bien vouloir renseigner un fichier</div>
        </ng-container>
        <!-- FIN de Logo projet -->

        <!-- Légende et crédits -->
        <div
          class="ds-input-group w-100"
          [class.ds-input-group--error]="
            !infoProjetForm.controls['legendeCredit'].valid && infoProjetForm.controls['legendeCredit'].touched
          "
        >
          <label for="legendeCredit" class="ds-input-group__label fw-bold">
            Légende et crédits de l'illustration
            <lib-asterisk></lib-asterisk>
          </label>
          <span class="ds-input-group__input">
            <input
              id="legendeCredit"
              name="legendeCredit"
              [maxlength]="maxLengthLegendeCredit"
              type="text"
              class="ds-input-group__input"
              formControlName="legendeCredit"
              placeholder="Légende et crédits de l'illustration"
              #legendeInput
            />
          </span>
          <div
            class="form-input__length-hint"
            [class.form-input__length-hint--full]="legendeInput.value?.length === maxLengthLegendeCredit"
          >
            <span>{{ legendeInput.value?.length || 0 }}/{{ maxLengthLegendeCredit }}</span>
          </div>
          <!-- Icône et message d'erreur -->
          <ng-container *ngIf="infoProjetForm.controls['legendeCredit'].invalid && infoProjetForm.controls['legendeCredit'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
          </ng-container>
        </div>
        <!-- FIN de Légende et crédits -->

        <div class="w-100 d-flex">
          <ng-container *ngIf="formSubmitted && infoProjetForm.touched && !infoProjetForm.valid">
            <div class="ds-input-group ds-input-group--error w-100">
              <div class="ds-input-group__feedback">Merci de renseigner les champs entourés en rouge.</div>
            </div>
          </ng-container>

          <div class="w-100 d-flex justify-content-end button-container" *ngIf="canUserWrite && !isValideFicheCom">
            <button type="button" class="ds-btn ds-btn--secondary me-2" (click)="cancel()">
              <span>Annuler</span>
            </button>
            <button type="submit" class="ds-btn ds-btn--primary">
              <span>Enregistrer</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</lib-expansion-panel>
