<div class="doc-container">
  <div class="doc-infos">
    <span class="doc-infos-type" *ngIf="document.type && !hideType">{{ document.type }}</span>
    <br />
    <span class="loaded-document-title">{{ document.nom }}</span>
    <br />
    <span class="loaded-document-information" *ngIf="!!document.dateCreation && !hideDateCreation">
      Chargé le {{ document.dateCreation | date : 'dd/MM/yyyy' }} {{ getNomCreateurDocument(document) }}
    </span>
  </div>
  <div class="doc-actions">
    <div class="card__action-button-wrapper">
      <div class="card__action-button-tooltip" *ngIf="!isScannedDocument(document)">
        <p>Le test antimalware est en cours.</p>
      </div>
      <button
        type="button"
        id="download-document"
        class="card__action-button"
        [ngClass]="{
          'card__action-button--error': isScannedDocument(document) && !isValidDocument(document)
        }"
        (click)="downloadDocument(document)"
        [disabled]="!isScannedDocument(document) || !isValidDocument(document)"
      >
        <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
      </button>
    </div>
    <div *ngIf="canUserWrite" class="card__action-button-wrapper">
      <button type="button" id="delete-document" class="card__action-button" (click)="onDeleteDocument()">
        <lib-pxl-icon class="icon" icon="icon-action-delete"></lib-pxl-icon>
      </button>
    </div>
    <div *ngIf="hasCheckbox" class="card__action-button-wrapper">
      <mat-checkbox [checked]="isChecked" [(ngModel)]="isChecked" (click)="checkChange()" [id]="'checkbox__' + document.id"></mat-checkbox>
    </div>
  </div>
</div>
