import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments-candidat/environment';
import { PxlOidcService } from '@services-candidat/pxl-oidc.service';
import { UserService } from '@services-candidat/user.service';
import { ShowToastrService } from '@shared-ui';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'pxl_front_candidat';

  constructor(
    private userService: UserService,
    private oidcSecurityService: PxlOidcService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private showToastrService: ShowToastrService
  ) {}

  ngOnInit(): void {
    this.oidcSecurityService.isAuthenticated().subscribe(isAuthenticated => {
      if (
        !isAuthenticated &&
        !environment.stubAuth &&
        '/autologin' !== window.location.pathname &&
        '/accueil' !== window.location.pathname &&
        '/' !== window.location.pathname
      ) {
        this.write('redirect', window.location.pathname + window.location.search);
        this.router.navigate(['/autologin']);
      }
      if (isAuthenticated) {
        this.userService.getUtilisateur();
        if (!this.navigateToStoredEndpoint() && '/' === window.location.pathname) {
          this.router.navigate(['/espace']);
        }
      }
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);

      rt.data.subscribe(data => {
        this.titleService.setTitle(data.title);
      });
    });

    // If there is an error param from evaluateur, we display a toastr
    this.activatedRoute.queryParams.subscribe(params => {
      const errorParam = params.error;

      if (errorParam) {
        this.showToastrService.checkCodeError(errorParam);
      }
    });
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  private navigateToStoredEndpoint(): boolean {
    const path = this.read('redirect');

    if (path) {
      localStorage.removeItem('redirect');
      if (path.toString().includes('/unauthorized')) {
        this.router.navigate(['/']);
      } else {
        this.router.navigateByUrl(path);
      }
    }
    return !!path;
  }

  private read(key: string): any {
    const data = localStorage.getItem(key);
    if (data) {
      return data;
    } else {
      return;
    }
  }

  private write(key: string, value: any): void {
    localStorage.setItem(key, value);
  }
}
