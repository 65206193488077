import { LOCALE_ID, Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import {
  Depenses,
  EnumClassificationSaisie,
  EnumValidation,
  LotLightModel,
  LotModel,
  Projet,
  Structure,
  TableCell,
  TacheDepensesModel,
  TacheModel,
  TachePersonnelModel,
  TacheSyntheseModel,
} from '@shared-ui';
import { formatPercent } from '@angular/common';

@Component({
  selector: 'pxl-projet-info-depenses-synthese',
  templateUrl: './projet-info-depenses-synthese.component.html',
})
export class ProjetInfoDepensesSyntheseComponent {
  @Input() set depenses(data: { depense: Depenses; projet: Projet }) {
    setTimeout(() => {
      const lotsTmp = [];
      data.depense?.lots?.forEach(lot => {
        const taches = lot.taches.map(tache => this.mapToLotLight(tache, lot, data?.projet?.structures));
        lotsTmp.push(...taches);
      });
      this.lots = lotsTmp;
      this.readOnly = data.depense?.statut === this.VALIDE;
    }, 0);
  }

  @Output() lotsChanges: EventEmitter<{ isSideNav: boolean; lots: LotModel[] }> = new EventEmitter<{
    isSideNav: boolean;
    lots: LotModel[];
  }>();

  lots: LotLightModel[] = [];
  @Input() afficherOnglets = false;
  public readOnly: boolean;
  readonly VALIDE = (EnumValidation as any)[EnumValidation.VALIDE.toString()];

  constructor(@Inject(LOCALE_ID) public locale: string) {}

  private mapToLotLight(tache: TacheModel, lot: LotModel, structures: Structure[]): LotLightModel {
    const ec: number[] = tache?.synthese?.etapeCles.map(etape => etape.depenses) ?? [];
    const structureRaison = structures?.find(structure => structure.id === tache.structureId)?.raisonSocial || '';
    return {
      lot: new TableCell(lot.index),
      lotName: new TableCell(lot.intitule),
      idTache: tache.lotTacheId,
      indexTache: new TableCell(tache.index),
      tacheName: new TableCell(tache.intitule),
      structureId: new TableCell(tache.structureId),
      structureName: new TableCell(structureRaison),
      tacheDebut: new TableCell(tache.moisDebut?.value),
      tacheFin: new TableCell(tache.moisFin?.value),
      livrable: new TableCell(tache.livrable?.value),
      personnelCat1: new TableCell(tache.personnel?.personneCat1?.value),
      personnelCat2: new TableCell(tache.personnel?.personneCat2?.value),
      personnelCat3: new TableCell(tache.personnel?.personneCat3?.value),
      sousTraitance: new TableCell(
        tache.depenses?.sousTraitance?.value,
        false,
        false,
        false,
        !!tache.depenses?.sousTraitance?.note?.prioriteElevee,
        tache.depenses?.sousTraitance?.note
      ),
      amortissement: new TableCell(
        tache.depenses?.amortissement?.value,
        false,
        false,
        false,
        !!tache.depenses?.amortissement?.note?.prioriteElevee,
        tache.depenses?.amortissement?.note
      ),
      autre: new TableCell(
        tache.depenses?.autre?.value,
        false,
        false,
        false,
        !!tache.depenses?.autre?.note?.prioriteElevee,
        tache.depenses?.autre?.note
      ),
      investissement: new TableCell(
        tache.depenses?.investissement?.value,
        false,
        false,
        false,
        !!tache.depenses?.investissement?.note?.prioriteElevee,
        tache.depenses?.investissement?.note
      ),
      classification: new TableCell(tache.classificationSaisie),
      totalPersonne: new TableCell(tache.synthese?.totalPersonne),
      tacheDuree: new TableCell(tache.synthese?.duree),
      salaires: new TableCell(tache.synthese?.salairesPresentes),
      frais: new TableCell(tache.synthese?.fraisPresentes),
      cout: new TableCell(tache.synthese?.coutsPresentes),
      ec: ec.map(etape => new TableCell(formatPercent(etape as number, this.locale))),
      sousTraitancePresentes: new TableCell(tache.depenses?.sousTraitance?.value),
      amortissementPresentes: new TableCell(tache.depenses?.amortissement?.value),
      autrePresentes: new TableCell(tache.depenses?.autre?.value),
      ecartsSalairesFg: new TableCell(tache.synthese?.ecartsSalairesFg),
      ecartsTotaux: new TableCell(tache.synthese?.ecartsTotaux),
      ecartsAutresCouts: new TableCell(tache.synthese?.ecartsAutresCouts),
    };
  }

  onLotsTachesChanges($event: { isSideNav: boolean; lots: LotLightModel[] }): void {
    const lotModels: LotModel[] = [];

    const tachesByLots = $event.lots.reduce((acc, curr) => {
      acc[curr['lot'].value] = acc[curr['lot'].value] || [];
      acc[curr['lot'].value].push(curr);
      return acc;
    }, {});

    Object.keys(tachesByLots).forEach(key => {
      const lotsLight: LotLightModel[] = tachesByLots[key];
      const taches: TacheModel[] = this.extractedTachesFromLots(lotsLight);
      const lot: LotModel = { index: lotsLight[0].lot.value as number, intitule: lotsLight[0].lotName.value + '', taches };
      lotModels.push(lot);
    });

    this.lotsChanges.emit({ isSideNav: $event.isSideNav, lots: lotModels });
  }

  private extractedTachesFromLots(lotsLight: LotLightModel[]): TacheModel[] {
    const taches: TacheModel[] = [];
    Object.keys(lotsLight).forEach(index => {
      const lotLight: LotLightModel = lotsLight[index];
      const tache: TacheModel = this.getTache(lotLight);
      taches.push(tache);
    });
    return taches;
  }

  private getTache(lotLight: LotLightModel): TacheModel {
    return {
      lotTacheId: lotLight.idTache,
      structureId: lotLight.structureId.value + '',
      index: lotLight.indexTache.value + '',
      intitule: lotLight.tacheName.value + '',
      moisDebut: { value: lotLight.tacheDebut.value as number },
      moisFin: { value: lotLight.tacheFin.value as number },
      livrable: { value: lotLight.livrable.value },
      classificationSaisie: EnumClassificationSaisie[lotLight.classification.value],
      personnel: this.getPersonnel(lotLight),
      depenses: this.getDepenses(lotLight),
      synthese: this.getSynthese(lotLight),
    };
  }

  private getPersonnel(lotLight: LotLightModel): TachePersonnelModel {
    return {
      personneCat1: { value: lotLight.personnelCat1.value as number },
      personneCat2: { value: lotLight.personnelCat2.value as number },
      personneCat3: { value: lotLight.personnelCat3.value as number },
    };
  }

  private getDepenses(lotLight: LotLightModel): TacheDepensesModel {
    return {
      investissement: {
        value: lotLight.investissement.value as number,
        note: lotLight.investissement?.metaData,
      },
      autre: {
        value: lotLight.autre.value as number,
        note: lotLight.autre?.metaData,
      },
      amortissement: {
        value: lotLight.amortissement.value as number,
        note: lotLight.amortissement?.metaData,
      },
      sousTraitance: {
        value: lotLight.sousTraitance.value as number,
        note: lotLight.sousTraitance?.metaData,
      },
    };
  }

  private getSynthese(lotLight: LotLightModel): TacheSyntheseModel {
    return {
      coutsPresentes: lotLight.cout.value as number,
      fraisPresentes: lotLight.frais.value as number,
      salairesPresentes: lotLight.salaires.value as number,
      duree: lotLight.tacheDuree.value as number,
      totalPersonne: lotLight.totalPersonne.value as number,
      etapeCles: lotLight.ec?.map((etape, index) => {
        return { index: index, depenses: etape.value as number };
      }),
    };
  }
}
