import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjetCreationComponent } from './projet-creation.component';
import { SidenavProjetModule } from './component/sidenav-creation/sidenav.projet.module';
import { ProjetCreationRoutingModule } from '@features-candidat/projet-creation/projet-creation-routing.module';
import { ProjetApercuModule } from '@features-candidat/projet-creation/component/projet-apercu/projet-apercu.module';
import { ProjetInfoModule } from '@features-candidat/projet-creation/component/projet-info/projet-info.module';
import { ProjetInfoGeneralModule } from '@features-candidat/projet-creation/component/projet-info-general/projet-info-general.module';
import { ProjetConsortiumModule } from '@features-candidat/projet-creation/component/projet-consortium/projet-consortium.module';
import { ProjetInfoService } from '@features-candidat/projet-creation/service/projet-info.service';
import { ProjetConsortiumCreationModule } from '@features-candidat/projet-creation/component/projet-consortium-creation/projet-consortium-creation.module';
import { ProjetConsortiumInfoModule } from '@features-candidat/projet-creation/component/projet-consortium-info/projet-consortium-info.module';
import { ProjetConsortiumContactModule } from '@features-candidat/projet-creation/component/projet-consortium-contact/projet-consortium-contact.module';
import { SelectDocumentModalModule } from '@shared-candidat/components/modals/select-document-modal/select-document-modal.module';
import { ProjetInfoBudgetModule } from './component/projet-info-budget/projet-info-budget.module';
import { ProjetConsortiumBudgetModule } from './component/projet-consortium-budget/projet-consortium-budget.module';
import { InvitationContactModalModule } from '@shared-candidat/components/modals/invitation-contact-modal/invitation-contact-modal.module';
import { ProjetInfoPolesModule } from './component/projet-info-poles/projet-info-poles.module';
import { ProjetConsortiumAdresseRDModule } from './component/projet-consortium-adresse-rd/projet-consortium-adresse-rd.module';
import { ProjetInfoSecteursModule } from '@features-candidat/projet-creation/component/projet-info-secteurs/projet-info-secteurs.module';
import { ProjetInfoDomainesModule } from './component/projet-info-domaines/projet-info-domaines.module';
import { ProjetInfoThematiqueModule } from './component/projet-info-thematique/projet-info-thematique.module';
import { ProjetInfoEnqueteModule } from './component/projet-info-enquete/projet-info-enquete.module';
import { ProjetConsortiumMembreEquipeModule } from './component/projet-consortium-membre-equipe/projet-consortium-membre-equipe.module';
import { ProjetInfoGrilleImpactsModule } from './component/projet-info-grille-impact/projet-info-grille-impacts.module';
import { ProjetConsortiumGrilleImpactsModule } from './component/projet-consortium-grille-impact/projet-consortium-grille-impacts.module';
import { ProjetInfoDepensesModule } from './component/projet-info-depenses/projet-info-depenses.module';
import { ProjetInfoFicheComModule } from './component/projet-info-fiche-com/projet-info-fiche-com.module';
import { SidenavDepensesModule } from './component/sidenav-depenses/sidenav-depenses.module';
import { ProjetConsortiumFicheDemandeAideModule } from './component/projet-consortium-fiche-demande-aide/projet-consortium-fiche-demande-aide.module';
import { ProjetConsortiumInformationBancaireModule } from '@features-candidat/projet-creation/component/projet-consortium-information-bancaire/projet-consortium-information-bancaire.component.module';
import {
  ProjetConsortiumSignataireModule
} from "@features-candidat/projet-creation/component/projet-consortium-signataire/projet-consortium-signataire.module";

@NgModule({
  declarations: [ProjetCreationComponent],
  imports: [
    CommonModule,
    ProjetCreationRoutingModule,
    SidenavProjetModule,
    ProjetApercuModule,
    ProjetInfoModule,
    ProjetInfoGeneralModule,
    ProjetConsortiumModule,
    ProjetConsortiumCreationModule,
    ProjetConsortiumAdresseRDModule,
    ProjetConsortiumInfoModule,
    ProjetConsortiumContactModule,
    ProjetConsortiumMembreEquipeModule,
    ProjetConsortiumGrilleImpactsModule,
    ProjetConsortiumFicheDemandeAideModule,
    SelectDocumentModalModule,
    InvitationContactModalModule,
    ProjetInfoBudgetModule,
    ProjetInfoPolesModule,
    ProjetConsortiumBudgetModule,
    ProjetConsortiumInformationBancaireModule,
    ProjetInfoSecteursModule,
    ProjetInfoDomainesModule,
    ProjetInfoThematiqueModule,
    ProjetInfoEnqueteModule,
    ProjetInfoGrilleImpactsModule,
    ProjetInfoDepensesModule,
    ProjetInfoFicheComModule,
    SidenavDepensesModule,
    ProjetConsortiumSignataireModule
  ],
  providers: [ProjetInfoService],
})
export class ProjetCreationModule {}
