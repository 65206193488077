import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnumScanDocument } from '../../shared/enums/enum.scanDocument';
import { EnumScope } from '../../shared/enums/enum.scope';
import { DocumentProjet } from '../../shared/models/documentProjet.model';
import { Projet } from '../../shared/models/projet.model';
import { ProjetService } from '../../shared/services/projet.service';
import { ShowToastrService } from '../../shared/services/show-toastr.service';
import { UploadDocumentHttpService } from '../../shared/services/upload-document.http.service';
import { SubscriptionDestroyerComponent } from '../../shared/subscription-destroyer/subscription-destroyer.abstract';
import { DocumentHelper } from '../../utils/document-helper';
import { DownloadUtils } from '../../utils/download-utils';
import { StructureValidationFunction } from '../../utils/structure-validation';
import { ConfirmModalComponent } from '../_public_api';

@Component({
  selector: 'lib-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss'],
})
export class DocumentCardComponent extends SubscriptionDestroyerComponent implements OnInit {
  @Input() hasCheckbox = false;
  @Input() isChecked = false;
  @Input() document: DocumentProjet;
  @Input() projet: Projet;
  @Input() canUserWrite: boolean;
  @Input() showDownload?: boolean;
  @Input() deleteDisabled?: boolean;
  @Input() showNomAsType?: boolean;

  @Output() deleteDocEmitter = new EventEmitter<DocumentProjet>();
  @Output() isCheckedChange = new EventEmitter();

  isScanedDocument: boolean;
  isValidDocument: boolean;
  nomCreateur: string;

  constructor(
    public structureValidationFunction: StructureValidationFunction,
    public dialog: MatDialog,
    public showToastrService: ShowToastrService,
    public uploadDocumentService: UploadDocumentHttpService,
    public downloadUtils: DownloadUtils,
    public projetService: ProjetService
  ) {
    super();
  }

  ngOnInit(): void {
    this.isScanedDocument = this.structureValidationFunction.isScanedDocument(this.document);
    this.isValidDocument = this.structureValidationFunction.isValidDocument(this.document);
    this.nomCreateur = this.getNomCreateurDocument(this.document);
  }

  /*
   * Affiche le nom du créateur du Document
   * */
  getNomCreateurDocument(document: DocumentProjet): string {
    return DocumentHelper.getNomCreateurDocument(document);
  }

  /*
   * Affiche la modale pour supprimer un document
   * */
  onDeleteDocument(document: DocumentProjet): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        description: `<p>Êtes-vous sûr de vouloir supprimer ce document.</p>
                          <p>Cette action est irréversible. </p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
        icon: true,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(this.takeUntilDestroyed())
      .subscribe(result => {
        if (result) {
          this.deleteDocument(document);
        }
      });
  }

  deleteDocument(document: DocumentProjet): void {
    this.projetService
      .deleteDocument(this.projet.id, document.id)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: response => {
          if (response) {
            this.showToastrService.success('Le document a bien été supprimé.');

            if (document.scan === EnumScanDocument.UNSAFE) {
              return;
            }
            if (document.stockerGed) {
              this.deleteDocEmitter.emit(document);
              return;
            }
            const path =
              this.projet.id +
              '/' +
              (document.scope !== EnumScope.PROJET ? document.structureId + '/' : '') +
              document.id +
              '/' +
              document.nom;
            this.uploadDocumentService
              .deleteDocFromS3(path, 'B401', document.id, this.projet.id)
              .pipe(this.takeUntilDestroyed())
              .subscribe({
                next: () => {
                  this.deleteDocEmitter.emit(document);
                },
                error: (err: HttpErrorResponse) => {
                  this.showToastrService.checkCodeError(err?.error);
                },
              });
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  downloadDocument(document: DocumentProjet): void {
    if (document.stockerGed) {
      this.downloadFromGed(document);
      return;
    }
    if (document.type === 'CCUs') {
     this.downloadDocumentCCUs();
      return;
    }
    this.downloadFromS3(document);
  }

  downloadFromGed(document: DocumentProjet) {
    this.uploadDocumentService
      .getDocFromGED(document.id)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (response: HttpResponse<Blob>) => {
          if (response.body) {
            const fileName = document.nom;
            this.downloadUtils.download(response.body, fileName);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  downloadFromS3(document: DocumentProjet) {
    this.uploadDocumentService
      .getValueForDocDownload(document)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (response: HttpResponse<{ url: string }>) => {
          const url = response?.body?.url;
          window.open(url);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  checkChange(): void {
    this.isCheckedChange.emit(this.isChecked);
  }

  private downloadDocumentCCUs() {
    window.open(this.uploadDocumentService.getDocumentCCUsUrl());
  }
}
