import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EvaluateurService } from '@services-candidat/evaluateur.service';
import { UserService } from '@services-candidat/user.service';
import { Evaluateur, Projet, ShowToastrService, SubscriptionDestroyerComponent, Utilisateur } from '@shared-ui';
import { Subscription } from 'rxjs';
import { ProjetService } from '@services-candidat/projet.service';

@Component({
  selector: 'app-espace-selection',
  templateUrl: './espace-selection.component.html',
  styleUrls: ['./espace-selection.component.scss'],
})
export class EspaceSelectionComponent extends SubscriptionDestroyerComponent implements OnInit {
  evaluateur: Evaluateur;
  subscriptions: Subscription[] = [];
  isAllowedOnEval = false;
  user: Utilisateur;
  projectCount: number;

  constructor(
    private router: Router,
    private userService: UserService,
    private evaluateurService: EvaluateurService,
    private projetService: ProjetService,
    private showToastrService: ShowToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getUser();
    this.getProjects();
  }

  getProjects(): void {
    this.projetService.getProjets().subscribe({
      next: (response: HttpResponse<Projet[]>) => {
        const projets = response.body;
        this.projectCount = projets.length;
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  /**
   * Redirige vers la page candidat
   */
  redirectCandidat(): void {
    this.router.navigate(['/projets']);
  }

  /**
   * Redirige vers la page evaluateur
   */
  redirectEvaluateur(): void {
    this.subscriptions.push(this.evaluateurService.goToEvaluateurPage(this.evaluateur));
  }

  private getUser() {
    this.userService
      .getUserObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.getEvaluateur();
        }
      });
  }

  goToProjectCreation(event: MouseEvent) {
    this.router.navigate(['/projet-parcours']);
    event.stopPropagation();
  }

  private getEvaluateur() {
    this.evaluateurService
      .getEvaluateur(this.user.email)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: result => {
          this.evaluateur = result.body;
          this.isAllowedOnEval = true;
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === 401 && err?.error?.message) {
            this.isAllowedOnEval = true;
          } else {
            this.checkRadicalMail();
          }
        },
      });
  }

  private checkRadicalMail() {
    this.evaluateurService
      .checkRadicalMail(this.user.email)
      .pipe(this.takeUntilDestroyed())
      .subscribe(resp => {
        if (resp.body?.length > 0) {
          this.isAllowedOnEval = true;
        }
      });
  }
}
