import { Component, DestroyRef, HostListener, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Aap,
  EnumTypeSignataire,
  ShowToastrService,
  SignataireFormComponent,
  SignataireInterface,
  StructureHelperService,
  Utilisateur,
} from '@shared-ui';
import { StructureService } from '@services-candidat/structure.service';
import { AapService } from '@services-candidat/aap.service';
import { ProjetService } from '@services-candidat/projet.service';
import { UserService } from '@services-candidat/user.service';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import { NgxPermissionsService } from 'ngx-permissions';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-projet-consortium-signataire',
  templateUrl: './projet-consortium-signataire.component.html',
  styleUrls: ['./projet-consortium-signataire.component.scss'],
})
export class ProjetConsortiumSignataireComponent implements OnInit {
  @ViewChild('signataireFormComponent') signataireFormComponent: SignataireFormComponent;
  private destroyRef = inject(DestroyRef);

  projetId!: string;
  structureId!: string;
  signataireId!: string;
  isEdit = false;
  signataire: SignataireInterface;
  isStructureUpdatableByUser = false;
  aap!: Aap;
  user!: Utilisateur;
  isSignataireSelectedInOngoingProcedure!: boolean;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.signataireFormComponent.isFormDirty() || this.signataireFormComponent.isFormValid();
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private structureService: StructureService,
    private projetService: ProjetService,
    private showToastrService: ShowToastrService,
    private sharedFunction: SharedFunction,
    private userService: UserService,
    private permissionsService: NgxPermissionsService,
    private aapService: AapService,
    private structureHelperService: StructureHelperService
  ) {}

  ngOnInit(): void {
    this.projetId = this.route.snapshot.parent.params.id;
    this.structureId = this.route.snapshot.params.structureId;
    this.signataireId = this.route.snapshot.params.signataireId;
    this.isEdit = !!this.signataireId;
    this.loadAap();
    this.loadUser();
    this.loadProjectAndStructure();
    this.loadSignataireAndSyntheseContractualisationStructure();
  }

  private loadAap() {
    this.aapService.loadAapSubject().subscribe({
      next: aapResponse => {
        if (aapResponse) {
          this.aap = aapResponse.body;
        }
      },
    });
  }

  private loadUser() {
    this.userService.getUserObservable().subscribe({
      next: user => {
        this.user = user;
      },
    });
  }

  loadProjectAndStructure() {
    forkJoin([this.projetService.getProjetById(this.projetId), this.structureService.getStructureById(this.structureId)])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ([projetResponse, StructureResponse]) => {
          if (projetResponse.body && StructureResponse.body) {
            const projet = projetResponse.body;
            const structure = StructureResponse.body;
            this.isStructureUpdatableByUser = this.sharedFunction.isStructureUpdatableByUser(
              this.aap,
              projet,
              structure,
              this.user,
              this.permissionsService.getPermissions()
            );
          }
        },
        error: err => {
          this.showToastrService.error(err?.error?.message);
        },
      });
  }

  onGoToConsortiumInfo(): void {
    this.router.navigate(['projet-creation', this.projetId, 'projet-consortium-info', this.structureId]);
  }

  onCancel() {
    this.onGoToConsortiumInfo();
  }

  onSubmit(signataireToSave: SignataireInterface) {
    this.save(signataireToSave);
  }

  save(signataireToSave: SignataireInterface) {
    signataireToSave.id = this.isEdit ? this.signataire.id : null;
    signataireToSave.type = this.isEdit ? this.signataire.type : EnumTypeSignataire.SIGNATAIRE_EXTERNE;
    this.structureService.saveSignataire(this.structureId, signataireToSave).subscribe({
      next: () => {
        this.showToastrService.success('Le signataire a bien été enregistré');
        this.onGoToConsortiumInfo();
      },
      error: err => this.handleError(err),
    });
  }

  loadSignataireAndSyntheseContractualisationStructure() {
    forkJoin({
      signatairesResponse: this.structureService.getSignataires(this.structureId),
      syntheseContractualisationStructureResponse: this.structureService.getSyntheseContractualisationStructures(this.projetId),
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          const syntheseContractualisationStructures = response.syntheseContractualisationStructureResponse.body
            ? response.syntheseContractualisationStructureResponse.body
            : [];
          if (response.signatairesResponse.body) {
            const signataire = response.signatairesResponse.body.find(signataire => signataire.id === this.signataireId);
            const syntheseContractualisationCurrentStructure = syntheseContractualisationStructures.find(
              structure => structure.id === this.structureId
            )!;
            const isProcedureEnCours = this.structureHelperService.isProcedureEnCours(syntheseContractualisationCurrentStructure);
            this.isSignataireSelectedInOngoingProcedure =
              isProcedureEnCours &&
              this.structureHelperService.isUserSelectedInOngoingProcedure(syntheseContractualisationCurrentStructure, this.signataireId);
            this.signataire = signataire;
          }
        },
        error: err => this.handleError(err),
      });
  }

  handleError(err: HttpErrorResponse): Observable<never> {
    this.showToastrService.checkCodeError(err?.error);
    return EMPTY;
  }
}
