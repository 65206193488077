<div
  class="card card--list-file"
  [ngClass]="{
    'card--warning': isScanedDocument && !isValidDocument
  }"
>
  <div class="card__title">
    <div class="me-2">
      <mat-checkbox
        *ngIf="hasCheckbox"
        [checked]="isChecked"
        [(ngModel)]="isChecked"
        (click)="checkChange()"
        [id]="'checkbox__' + document.id"
      ></mat-checkbox>
    </div>
    <lib-pxl-icon class="icon" icon="icon-file-folder-insert-drive-file" aria-hidden="true"></lib-pxl-icon>
    {{ showNomAsType ? document.nom : document.type }}
    <ng-content select="[document-modification-tag]"></ng-content>
  </div>

  <div class="card__text">
    <span class="card__doc--name" *ngIf="!showNomAsType">
      {{ document.nom }}
    </span>
    <ng-container *ngIf="document.dateCreation">
      <br *ngIf="!showNomAsType"/>
      Chargé le {{ document.dateCreation | date : 'dd/MM/yyyy' }} {{ nomCreateur }}
    </ng-container>
  </div>

  <div class="card__actions">
    <ng-content select="[document-actions]"></ng-content>
    <div class="card__action-button-wrapper">
      <button
        type="button"
        id="download-document"
        class="card__action-button"
        [ngClass]="{
          'card__action-button--error': isScanedDocument && !isValidDocument
        }"
        (click)="downloadDocument(document)"
        [disabled]="!isValidDocument || !isScanedDocument"
        *ngIf="showDownload === undefined || showDownload"
      >
        <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
      </button>
      <div class="card__action-button-tooltip" *ngIf="!isScanedDocument">
        <p>Le test antimalware est en cours.</p>
      </div>
      <div class="card__action-button-tooltip" *ngIf="isScanedDocument && !isValidDocument">
        <p>Ce fichier a été identifié comme étant potentiellement dangereux et ne peut donc pas être téléchargé.</p>
      </div>
    </div>
    <div class="card__action-button-wrapper" *ngIf="canUserWrite">
      <button
        type="button"
        id="delete-document"
        class="card__action-button"
        (click)="onDeleteDocument(document)"
        [disabled]="deleteDisabled"
      >
        <lib-pxl-icon class="icon" icon="icon-action-delete"></lib-pxl-icon>
      </button>
      <div class="card__action-button-tooltip" *ngIf="deleteDisabled">
        <p>Vous ne pouvez pas supprimer ce fichier à cette étape.</p>
      </div>
    </div>
  </div>
</div>
