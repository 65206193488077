<div id="card-correction" class="card card-correction">
  <section class="modification-section modification-infos">
    <div class="d-flex flex-wrap correction-header">
      <div class="d-flex flex-wrap">
        <p class="card__title">Demande de document(s) complémentaire(s)</p>
        <div class="d-flex">
          <div class="modification-tag tag--blue">{{ EnumScope.toString(demande.scope, true) }}</div>
        </div>
      </div>
      <span *ngIf="isArchived" [ngClass]="{ valide: getCorrectionStatus(demande) === CORRECTION_VALIDEE_LABEL }">{{
        getCorrectionStatus(demande)
      }}</span>
    </div>
    <p class="card__subtitle">
      <ng-content select="[auteur]"></ng-content>
    </p>
    <p class="card__subtitle" *ngIf="isArchived">
      {{ getCorrectionStatus(demande) === CORRECTION_VALIDEE_LABEL ? 'Validée' : 'Rejetée' }}
      le
      {{ demande.dateModification | date : "dd/MM/yyyy à HH'H'mm" }}
    </p>
    <p class="card__subtitle" *ngIf="demande?.contactsANotifier?.length">Envoyée à {{ getSentToContactsText() }}</p>
    <button class="button-voir-plus" *ngIf="isLongContacts" (click)="afficherPlusContacts(); $event.stopPropagation()">
      Voir {{ readMoreContacts ? 'moins' : 'plus' }}
    </button>
  </section>

  <section class="modification-section modification-structures" *ngIf="demande?.documents?.length">
    <p class="card__title">Nom(s) de(s) document(s) complémentaire(s)</p>
    <p class="doc-item" *ngFor="let document of demande.documents">
      &bull; {{ docComplementaireType.toString(document.type)}}
      <span *ngIf="document.obligatoire" class="ds-input-group__label-asterisk"> *</span>
    </p>
  </section>

  <section class="modification-section modification-structures" *ngIf="demande?.structures?.length">
    <p class="card__title">Structure(s) notifiée(s)</p>
    <p class="card__subtitle">
      {{ getSentToStructuresText() }}
    </p>
    <button class="button-voir-plus" *ngIf="isLongStructures" (click)="afficherPlusStructures(); $event.stopPropagation();">
      Voir {{ readMoreStructures ? 'moins' : 'plus' }}
    </button>
  </section>

  <!-- Section d'actions (champs textes et boutons) -->
  <ng-content select="[action-section]"></ng-content>
  <!-- Fin section d'actions (champs textes et boutons) -->
</div>
