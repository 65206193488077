import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DepensesService } from '@services-candidat/depenses.service';
import { ProjetService } from '@services-candidat/projet.service';
import { UploadDocumentService } from '@services-candidat/upload-document.service';
import { UserService } from '@services-candidat/user.service';
import {
  ConfirmModalComponent,
  Depenses,
  DocumentHelper,
  DocumentProjet,
  EnumScanDocument,
  EnumScope,
  DownloadUtils,
  Projet,
  ShowToastrService,
  SignedUrlResponseModel,
  SubscriptionDestroyerComponent,
  UploadDocumentHttpService,
} from '@shared-ui';
import { concatMap } from 'rxjs';

@Component({
  selector: 'pxl-projet-info-depenses-upload',
  templateUrl: './projet-info-depenses-upload.component.html',
  styleUrls: ['./projet-info-depenses-upload.component.scss'],
})
export class ProjetInfoDepensesUploadComponent extends SubscriptionDestroyerComponent {
  @Input() projet: Projet = new Projet();
  @Input() expenses: Depenses = new Depenses();
  @Input() documentBdd: DocumentProjet = new DocumentProjet();

  // Event emitter
  @Output() docChanged = new EventEmitter<DocumentProjet>();
  @Output() expensesChanged = new EventEmitter<Depenses>();

  readonly SAFE = EnumScanDocument[EnumScanDocument.SAFE.toString()];
  readonly UNSAFE = EnumScanDocument[EnumScanDocument.UNSAFE.toString()];

  fileToUpload: File;
  maxSize = 20000000;

  path = '';

  constructor(
    public projetService: ProjetService,
    private uploadService: UploadDocumentService,
    private uploadDocumentHttpService: UploadDocumentHttpService,
    private downloadUtils: DownloadUtils,
    private showToastrService: ShowToastrService,
    public depensesService: DepensesService,
    public userService: UserService,
    public matDialog: MatDialog
  ) {
    super();
  }

  onSelectDoc(files: FileList): void {
    this.fileToUpload = files.item(0);

    if (this.fileToUpload?.size >= this.maxSize) {
      this.showToastrService.error('Le fichier à importer doit avoir une taille maximale de 10Mo.');
      return;
    }

    if (!['text/csv'].includes(this.fileToUpload.type)) {
      this.showToastrService.error("Le fichier à importer n'est pas au bon format (.csv)");
      return;
    }

    this.uploadDocument(this.fileToUpload);
  }

  uploadDocument(file: File): void {
    this.documentBdd = new DocumentProjet();
    this.documentBdd.nom = file.name;
    this.documentBdd.dateModification = this.projet.dateModification;
    this.documentBdd.projetId = this.projet.id;
    this.documentBdd.typeDoc = 'DEPENSES_PROJET';
    this.documentBdd.scope = EnumScope.PROJET;

    this.projetService
      .upsertDocumentProjet(this.documentBdd)
      .pipe(
        concatMap((rep: HttpResponse<DocumentProjet>) => {
          this.documentBdd = rep.body;
          this.docChanged.emit(this.documentBdd);
          this.path = this.projet.id + '/' + this.documentBdd.id;
          const typeUpload = 'B401';
          return this.uploadService.getValueForDocStructureUpload(
            this.fileToUpload.name,
            this.path,
            typeUpload,
            this.documentBdd.id,
            this.projet.id,
            'DEPENSES_PROJET'
          );
        }),
        concatMap((result: any) => {
          return this.uploadService.upload(result.body.url, this.fileToUpload, this.projet.id, this.documentBdd.id, 'DEPENSES_PROJET');
        }),
        concatMap(() => {
          this.path = this.path + '/' + this.fileToUpload.name;
          return this.depensesService.postCsvDepenses(this.expenses.id, this.path);
        })
      )
      .subscribe({
        next: result => {
          this.showToastrService.success('Le fichier a bien été importé');
          this.docChanged.emit(this.documentBdd);
          this.expensesChanged.emit(result.body);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.error(err?.error?.message);
          this.documentBdd = null;
          this.docChanged.emit(this.documentBdd);
        },
      });
  }

  /*
   * Vérifie si le document uploadé a passé le test antivirus
   * */
  isScanedDocument(document: DocumentProjet): boolean {
    if (document?.scan === this.SAFE || document?.scan === this.UNSAFE) {
      return true;
    }
    return false;
  }

  /*
   * Affiche le nom du créateur du Document
   * */
  getNomCreateurDocument(document: DocumentProjet): string {
    return DocumentHelper.getNomCreateurDocument(document);
  }

  /*
   * Download a document
   * */
  downloadDocument(document: DocumentProjet): void {
    if (document.stockerGed) {
      this.uploadDocumentHttpService
        .getDocFromGED(document.id)
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: (response: HttpResponse<Blob>) => {
            if (response.body) {
              const fileName = document.nom;
              this.downloadUtils.download(response.body, fileName);
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    } else {
      const typeUpload = DocumentHelper.getUploadDocumentType(document);
      const path = DocumentHelper.getS3Path(this.projet, document) + '/' + document.nom;
      this.uploadService
        .getValueForDocProjetDownload(path, typeUpload)
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: (response: HttpResponse<SignedUrlResponseModel>) => {
            const url = response.body.url;
            window.open(url);
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    }
  }

  /*
   * Affiche la modale pour supprimer un document
   * */
  onDeleteDocument(document: DocumentProjet): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        description: `<p>Êtes-vous sûr de vouloir supprimer ce fichier.</p>
                          <p>Cette action est irréversible. </p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
        icon: true,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.projetService.deleteDocument(this.projet.id, document.id).subscribe({
        next: () => {
          this.showToastrService.success('Le fichier a bien été supprimé');
          this.documentBdd = null;
          this.docChanged.emit(this.documentBdd);
          if (document.scan === (EnumScanDocument as any)[EnumScanDocument.UNSAFE.toString()]) {
            return;
          }
          if (document.stockerGed) {
            return;
          }
          const path = this.projet.id + '/' + document.id + '/' + document.nom;
          this.uploadService.deletDocStructureUpload(path, DocumentHelper.TYPE_B401, document.id, this.projet.id).subscribe({
            error: (err: HttpErrorResponse) => {
              this.showToastrService.checkCodeError(err?.error);
            },
          });
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    });
  }
}
