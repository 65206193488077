<div class="card--info card--blue card--form">
  <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
  Déclaration des aides obtenues par votre structure et dans le cas où le projet présenté a été initialement porté par une entité juridique
  non associée à la présente demande d'aide, précisez les aides obtenues dans ce cadre initial
</div>

<form class="form-container" [formGroup]="ficheDemandeAideListeAidesForm">
  <div class="card">
    <div class="questions-container">
      <div>
        <label class="fw-bold">Avez-vous obtenu des aides au cours des trois dernières années ?</label>
        <lib-asterisk></lib-asterisk>
      </div>
      <div>
        <label class="ds-radio-classic" for="aidesObtenues-true">
          <input
            type="radio"
            class="ds-radio__input"
            id="aidesObtenues-true"
            name="obtenuAidesTroisDernieresAnnees"
            formControlName="obtenuAidesTroisDernieresAnnees"
            [value]="true"
          />
          <span class="ds-radio__label"><span>Oui</span></span>
        </label>
        <label class="ds-radio-classic" for="aidesObtenues-false">
          <input
            type="radio"
            class="ds-radio__input"
            id="aidesObtenues-false"
            name="obtenuAidesTroisDernieresAnnees"
            formControlName="obtenuAidesTroisDernieresAnnees"
            [value]="false"
          />
          <span class="ds-radio__label"><span>Non</span></span>
        </label>
      </div>
    </div>
    <ng-container
      id="error-aidesObtenues"
      *ngIf="
        !ficheDemandeAideListeAidesForm.controls['obtenuAidesTroisDernieresAnnees'].valid &&
        ficheDemandeAideListeAidesForm.controls['obtenuAidesTroisDernieresAnnees'].touched
      "
    >
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur.</div>
    </ng-container>
    <ng-container *ngIf="isDisplayAidesObtenuesForm">
      <mat-table
        formArrayName="aidesObtenues"
        [dataSource]="dataSourceAidesObtenues"
        class="table-responsive mat-elevation-z0"
        aria-describedby="tableau des aides passées"
      >
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggleAidesObtenues() : null"
              [checked]="selectionAidesObtenues.hasValue() && isAllSelectedAidesObtenues()"
              [indeterminate]="selectionAidesObtenues.hasValue() && !isAllSelectedAidesObtenues()"
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell id="id" *matCellDef="let element; let i = index" (click)="$event.stopPropagation()">
            <mat-checkbox (change)="$event ? selectionAidesObtenues.toggle(i) : null" [checked]="selectionAidesObtenues.isSelected(i)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="nomProjet">
          <mat-header-cell *matHeaderCellDef>Nom du projet</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <div
              class="ds-input-group"
              [class.ds-input-group--error]="
                aidesObtenuesFormArray.at(i)?.get('nomProjet')?.invalid && aidesObtenuesFormArray.at(i)?.get('nomProjet')?.touched
              "
            >
              <span class="ds-input-group__input">
                <input
                  id="nomProjet"
                  name="nomProjet"
                  maxlength="25"
                  placeholder="Nom du projet"
                  type="text"
                  formControlName="nomProjet"
                  class="ds-input-group__input"
                />
              </span>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="theme">
          <mat-header-cell *matHeaderCellDef>Thème</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <div
              class="ds-input-group"
              [class.ds-input-group--error]="
                aidesObtenuesFormArray.at(i)?.get('theme')?.invalid && aidesObtenuesFormArray.at(i)?.get('theme')?.touched
              "
            >
              <span class="ds-input-group__input">
                <textarea
                  id="theme"
                  name="theme"
                  rows="3"
                  col="0"
                  maxlength="100"
                  class="ds-input-group__textarea"
                  formControlName="theme"
                  placeholder="renseigner une courte description du projet"
                ></textarea>
              </span>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="nature">
          <mat-header-cell *matHeaderCellDef>Nature de l'aide</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <div
              class="ds-input-group"
              [class.ds-input-group--error]="
                aidesObtenuesFormArray.at(i)?.get('nature')?.invalid && aidesObtenuesFormArray.at(i)?.get('nature')?.touched
              "
            >
              <span class="ds-input-group__input">
                <input
                  id="nature"
                  name="nature"
                  maxlength="25"
                  placeholder="Nature de l'aide"
                  type="text"
                  formControlName="nature"
                  class="ds-input-group__input"
                />
              </span>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="organismeFinanceur">
          <mat-header-cell *matHeaderCellDef>Organisme financeur</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <div
              class="ds-input-group"
              [class.ds-input-group--error]="
                aidesObtenuesFormArray.at(i)?.get('organismeFinanceur')?.invalid &&
                aidesObtenuesFormArray.at(i)?.get('organismeFinanceur')?.touched
              "
            >
              <span class="ds-input-group__input">
                <input
                  id="organismeFinanceur"
                  name="organismeFinanceur"
                  maxlength="25"
                  placeholder="Organisme financeur"
                  type="text"
                  formControlName="organismeFinanceur"
                  class="ds-input-group__input"
                />
              </span>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="montant">
          <mat-header-cell *matHeaderCellDef>
            <div>
              Montant (€)
              <span [matTooltip]="tooltipText" matTooltipClass="tooltip-multiline" class="d-inline ms-2">
                <lib-pxl-icon class="icon radio_button_help ml-3" icon="icon-action-info-circle-outline"></lib-pxl-icon>
              </span>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <div
              class="ds-input-group"
              [class.ds-input-group--error]="
                aidesObtenuesFormArray.at(i)?.get('montant')?.invalid && aidesObtenuesFormArray.at(i)?.get('montant')?.touched
              "
            >
              <span class="ds-input-group__input">
                <input
                  id="montant"
                  name="montant"
                  (keypress)="onkeypress($event)"
                  maxlength="15"
                  placeholder="Montant"
                  type="text"
                  formControlName="montant"
                  class="ds-input-group__input"
                />
              </span>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="annee">
          <mat-header-cell *matHeaderCellDef>Année</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <div
              class="ds-input-group"
              [class.ds-input-group--error]="
                aidesObtenuesFormArray.at(i)?.get('annee')?.invalid && aidesObtenuesFormArray.at(i)?.get('annee')?.touched
              "
            >
              <span class="ds-input-group__input">
                <input
                  id="annee"
                  name="annee"
                  (keypress)="onkeypress($event)"
                  maxlength="4"
                  placeholder="Année"
                  type="text"
                  formControlName="annee"
                  class="ds-input-group__input"
                />
              </span>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumnsAidesObtenues"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsAidesObtenues"></mat-row>
      </mat-table>
      <ng-container *ngIf="aidesObtenuesFormArray.touched && aidesObtenuesFormArray.invalid">
        <div id="error-aide-obtenues" class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
      </ng-container>
      <div class="button-container" *ngIf="!ficheDemandeAideListeAidesForm.disabled">
        <a (click)="removeAidesObtenues()">
          <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
          <span class="action-btn">Supprimer ligne(s)</span>
        </a>
        <a (click)="addAidesObtenues()">
          <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
          <span class="action-btn">Ajouter une ligne</span>
        </a>
      </div>
    </ng-container>
  </div>

  <div class="card">
    <div class="questions-container">
      <div>
        <label class="fw-bold">Avez-vous des demandes d'aides déposées et en attente de décision, ou en préparation ?</label>
        <lib-asterisk></lib-asterisk>
      </div>
      <div>
        <label class="ds-radio-classic" for="aidesDeposees-true">
          <input
            type="radio"
            class="ds-radio__input"
            id="aidesDeposees-true"
            name="autresDemandesAidesDeposees"
            formControlName="autresDemandesAidesDeposees"
            [value]="true"
          />
          <span class="ds-radio__label"><span>Oui</span></span>
        </label>
        <label class="ds-radio-classic" for="aidesDeposees-false">
          <input
            type="radio"
            class="ds-radio__input"
            id="aidesDeposees-false"
            name="autresDemandesAidesDeposees"
            formControlName="autresDemandesAidesDeposees"
            [value]="false"
          />
          <span class="ds-radio__label"><span>Non</span></span>
        </label>
      </div>
    </div>
    <ng-container
      id="error-aidesObtenues"
      *ngIf="
        !ficheDemandeAideListeAidesForm.controls['autresDemandesAidesDeposees'].valid &&
        ficheDemandeAideListeAidesForm.controls['autresDemandesAidesDeposees'].touched
      "
    >
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur.</div>
    </ng-container>
    <ng-container *ngIf="isDisplayAidesEnAttenteForm">
      <mat-table
        formArrayName="aidesEnAttente"
        [dataSource]="dataSourceAidesEnAttente"
        class="table-responsive mat-elevation-z0"
        aria-describedby="tableau des actionnaires moraux"
      >
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggleAidesEnAttente() : null"
              [checked]="selectionAidesEnAttente.hasValue() && isAllSelectedAidesEnAttente()"
              [indeterminate]="selectionAidesEnAttente.hasValue() && !isAllSelectedAidesEnAttente()"
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell id="id" *matCellDef="let element; let i = index" (click)="$event.stopPropagation()">
            <mat-checkbox (change)="$event ? selectionAidesEnAttente.toggle(i) : null" [checked]="selectionAidesEnAttente.isSelected(i)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="nomProjet">
          <mat-header-cell *matHeaderCellDef>Nom du projet</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <div
              class="ds-input-group"
              [class.ds-input-group--error]="
                aidesEnAttenteFormArray.at(i)?.get('nomProjet')?.invalid && aidesEnAttenteFormArray.at(i)?.get('nomProjet')?.touched
              "
            >
              <span class="ds-input-group__input">
                <input
                  id="nomProjet"
                  name="nomProjet"
                  maxlength="25"
                  placeholder="Nom du projet"
                  type="text"
                  formControlName="nomProjet"
                  class="ds-input-group__input"
                />
              </span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="theme">
          <mat-header-cell *matHeaderCellDef>Thème</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <div
              class="ds-input-group"
              [class.ds-input-group--error]="
                aidesEnAttenteFormArray.at(i)?.get('theme')?.invalid && aidesEnAttenteFormArray.at(i)?.get('theme')?.touched
              "
            >
              <span class="ds-input-group__input">
                <textarea
                  id="theme"
                  name="theme"
                  rows="5"
                  col="0"
                  maxlength="100"
                  class="ds-input-group__textarea"
                  formControlName="theme"
                  placeholder="renseigner une courte description du projet"
                ></textarea>
              </span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="nature">
          <mat-header-cell *matHeaderCellDef>Nature de l'aide</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <div
              class="ds-input-group"
              [class.ds-input-group--error]="
                aidesEnAttenteFormArray.at(i)?.get('nature')?.invalid && aidesEnAttenteFormArray.at(i)?.get('nature')?.touched
              "
            >
              <span class="ds-input-group__input">
                <input
                  id="nature"
                  name="nature"
                  maxlength="25"
                  placeholder="Nature de l'aide"
                  type="text"
                  formControlName="nature"
                  class="ds-input-group__input"
                />
              </span>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="organismeFinanceur">
          <mat-header-cell *matHeaderCellDef>Organisme financeur</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <div
              class="ds-input-group"
              [class.ds-input-group--error]="
                aidesEnAttenteFormArray.at(i)?.get('organismeFinanceur')?.invalid &&
                aidesEnAttenteFormArray.at(i)?.get('organismeFinanceur')?.touched
              "
            >
              <span class="ds-input-group__input">
                <input
                  id="organismeFinanceur"
                  name="organismeFinanceur"
                  maxlength="25"
                  placeholder="Organisme financeur"
                  type="text"
                  formControlName="organismeFinanceur"
                  class="ds-input-group__input"
                />
              </span>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="montant">
          <mat-header-cell *matHeaderCellDef>
            <div>
              Montant (€)
              <span [matTooltip]="tooltipText" matTooltipClass="tooltip-multiline" class="d-inline ms-2">
                <lib-pxl-icon class="icon radio_button_help ml-3" icon="icon-action-info-circle-outline"></lib-pxl-icon>
              </span>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <div
              class="ds-input-group"
              [class.ds-input-group--error]="
                aidesEnAttenteFormArray.at(i)?.get('montant')?.invalid && aidesEnAttenteFormArray.at(i)?.get('montant')?.touched
              "
            >
              <span class="ds-input-group__input">
                <input
                  id="montant"
                  name="montant"
                  (keypress)="onkeypress($event)"
                  maxlength="15"
                  placeholder="Montant"
                  type="text"
                  formControlName="montant"
                  class="ds-input-group__input"
                />
              </span>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="annee">
          <mat-header-cell *matHeaderCellDef>Année</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <div
              class="ds-input-group"
              [class.ds-input-group--error]="
                aidesEnAttenteFormArray.at(i)?.get('annee')?.invalid && aidesEnAttenteFormArray.at(i)?.get('annee')?.touched
              "
            >
              <span class="ds-input-group__input">
                <input
                  id="annee"
                  name="annee"
                  (keypress)="onkeypress($event)"
                  maxlength="4"
                  placeholder="Année"
                  type="text"
                  formControlName="annee"
                  class="ds-input-group__input"
                />
              </span>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumnsAidesEnAttente"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsAidesEnAttente"></mat-row>
      </mat-table>
      <ng-container *ngIf="aidesEnAttenteFormArray.touched && aidesEnAttenteFormArray.invalid">
        <div id="error-aide-en-attente" class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
      </ng-container>
      <div class="button-container" *ngIf="!ficheDemandeAideListeAidesForm.disabled">
        <a (click)="removeAidesEnAttente()">
          <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
          <span class="action-btn">Supprimer ligne(s)</span>
        </a>
        <a (click)="addAidesEnAttente()">
          <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
          <span class="action-btn">Ajouter une ligne</span>
        </a>
      </div>
    </ng-container>
  </div>
</form>
