import { enumKeys } from '../../utils/_public_utils';
import { EnumProjetStatut } from './enum.projetStatut';

export enum EnumProjetEtape {
  PRE_DEPOT = 'PRE_DEPOT',
  DEPOT = 'DEPOT',
  // TODO : Remove EVALUATION and AUDITION steps
  EVALUATION = 'EVALUATION',
  AUDITION = 'AUDITION',
  INSTRUCTION = 'INSTRUCTION',
  CONTRACTUALISATION = 'CONTRACTUALISATION',
}

const sousEtapes = [EnumProjetEtape.EVALUATION, EnumProjetEtape.AUDITION];

export namespace EnumProjetEtape {
  export function toString(etape?: EnumProjetEtape): string {
    switch (etape) {
      case EnumProjetEtape.PRE_DEPOT:
        return 'Pré-dépôt';
      case EnumProjetEtape.DEPOT:
        return 'Dépôt';
      case EnumProjetEtape.EVALUATION:
        return 'Évaluation';
      case EnumProjetEtape.AUDITION:
        return 'Audition';
      case EnumProjetEtape.INSTRUCTION:
        return 'Instruction approfondie';
      case EnumProjetEtape.CONTRACTUALISATION:
        return 'Contractualisation';
      default:
        return '';
    }
  }

  export function fullFormat(etape: EnumProjetEtape, statut: EnumProjetStatut) {
    if (!etape || !statut) return '';
    const isNomEtapeFeminin = hasNomFeminin(etape);
    return EnumProjetEtape.toString(etape) + ' - ' + EnumProjetStatut.toString(statut, isNomEtapeFeminin);
  }

  export function hasNomFeminin(etape: EnumProjetEtape): boolean {
    return [EnumProjetEtape.AUDITION, EnumProjetEtape.INSTRUCTION, EnumProjetEtape.EVALUATION, EnumProjetEtape.CONTRACTUALISATION].includes(
      etape
    );
  }

  export function all(): EnumProjetEtape[] {
    return (enumKeys(EnumProjetEtape) as unknown as EnumProjetEtape[]).filter(etape => !sousEtapes.includes(etape));
  }
}

export enum EnumNotationEtape {
  AUDITION = 'AUDITION',
  EVALUATION = 'EVALUATION',
  INSTRUCTION = 'INSTRUCTION',
}

export namespace EnumNotationEtape {
  // toString function
  export function toString(value: EnumNotationEtape): string {
    switch (value) {
      case EnumNotationEtape.AUDITION:
        return 'Audition';
      case EnumNotationEtape.EVALUATION:
        return 'Evaluation';
      case EnumNotationEtape.INSTRUCTION:
        return 'Instruction';
      default:
        return '';
    }
  }
}
