import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Aap, ConfirmModalComponent, Projet } from '@shared-ui';
import { FicheCom } from '../../../shared/models/fiche-com.model';
import { SharedFunction } from '../../../utils/sharedFunction';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable } from 'rxjs';
import { DerogationDatePublicationFicheCom } from '../../../shared/models/derogation-date-publication-fiche-com.model';

@Component({
  selector: 'lib-fiche-com-demande-derogation',
  templateUrl: './fiche-com-demande-derogation.component.html',
  styleUrls: ['./fiche-com-demande-derogation.component.scss'],
})
export class FicheComDemandeDerogationComponent implements OnInit {
  @Input() aap: Aap;
  @Input() projet: Projet;
  @Input() derogationForm: FormGroup;
  @Input() ficheCom: FicheCom = new FicheCom();
  @Input() canUserWrite: boolean;
  @Output() saveFicheCom = new EventEmitter();
  savedDerogation: DerogationDatePublicationFicheCom;
  showDatePublication: boolean;
  formSubmitted: boolean;
  @Input() isValideFicheCom = false;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.derogationForm.dirty;
  }

  constructor(public matDialog: MatDialog, private sharedFunction: SharedFunction) {}

  ngOnInit(): void {
    this.setForm();
    this.feedDerogationForm();
  }

  private setForm(): void {
    this.derogationForm = new FormGroup({
      derogation: new FormControl<string>({ value: '', disabled: !this.canUserWrite || this.isValideFicheCom }),
      datePublication: new FormControl<string>({ value: '', disabled: !this.canUserWrite || this.isValideFicheCom }),
    });
  }

  feedDerogationForm(): void {
    this.derogationForm.patchValue(this.ficheCom?.derogationDatePublication);
    if (this.ficheCom?.derogationDatePublication?.derogation) {
      this.showDatePublication = true;
      this.derogationForm.get('datePublication')?.setValidators(Validators.required);
    }
    this.savedDerogation = this.derogationForm.getRawValue();
  }

  showValidIcon(): boolean {
    return this.sharedFunction.isDisabledFormValid(this.derogationForm) && !!this.savedDerogation?.datePublication;
  }

  onDemandeDerogationSubmitted() {
    this.formSubmitted = true;
    this.derogationForm.markAllAsTouched();

    if (this.derogationForm.valid) {
      this.savedDerogation = this.derogationForm.getRawValue();
      this.ficheCom.derogationDatePublication = this.savedDerogation;
      this.saveFicheCom.emit(this.ficheCom);
      this.derogationForm.markAsPristine();
    }
  }

  cancel(): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: 'Annuler les modifications en cours',
        description: `<p>En confirmant l'action, Vous allez perdre vos modification en cours.<br>
        Confirmez-vous l’action ?</p>`,
        textGoButton: 'Confirmer',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (this.savedDerogation) {
          if (this.savedDerogation.derogation) {
            this.showDatePublication = true;
          }
          this.derogationForm.setValue(this.savedDerogation);
          this.derogationForm.markAsPristine();
          return;
        }
        this.derogationForm.reset({
          derogationDatePublication: false,
        });
      }
    });
  }

  isFormDirty(): boolean {
    return this.derogationForm.dirty;
  }
  onCheckboxChange($event: MatCheckboxChange) {
    if ($event.checked) {
      this.showDatePublication = $event.checked;
      this.derogationForm.get('datePublication')?.setValidators(Validators.required);
    } else {
      this.showDatePublication = $event.checked;
      this.derogationForm.get('datePublication')?.setValidators(null);
      this.derogationForm.get('datePublication')?.setValue(null);
      this.formSubmitted = false;
    }
  }
}
