import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SiretService } from '../../shared/services/siret.service';
import { SubscriptionDestroyerComponent } from '../../shared/subscription-destroyer/subscription-destroyer.abstract';
import { InformationSiege } from '../../shared/models/information-siege.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnumMotifNonRattachement } from '../../shared/enums/enum.motifNonRattachement';
import { ShowToastrService } from '../../shared/services/show-toastr.service';
import { siretValidator } from '../../shared/directives/siret.validator';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'lib-structure-searcher',
  templateUrl: './structure-searcher.component.html',
  styleUrls: ['./structure-searcher.component.scss'],
})
export class StructureSearcherComponent extends SubscriptionDestroyerComponent implements OnInit {
  @Input() motifNonRattachement?: EnumMotifNonRattachement;
  @Input() displayCreationEnCoursOption = false;
  @Input() maStructureNotListedButtonVisibleOnEmptyList = false;

  @Output() structuresFound = new EventEmitter<InformationSiege[] | null>();
  @Output() structureSelected = new EventEmitter<InformationSiege | null>();
  @Output() motifNonRattachementSelected = new EventEmitter<EnumMotifNonRattachement>();
  @Output() maStructureNotListed = new EventEmitter<void>();

  form: FormGroup;
  structures: InformationSiege[] | null;
  selectedStructure: InformationSiege | null;
  maStructureNotListedButtonVisible = false;
  private previousMotif: EnumMotifNonRattachement | null;

  constructor(private fb: FormBuilder, public siretService: SiretService, public showToastrService: ShowToastrService) {
    super();
    this.form = this.fb.group({
      siret: [null, [Validators.required, siretValidator()]],
      motifNonRattachement: [null],
    });
  }

  ngOnInit() {
    // restore state before form changes
    if (this.motifNonRattachement) {
      this.previousMotif = this.motifNonRattachement;
      this.form.get('motifNonRattachement')?.setValue(this.motifNonRattachement);
      this.form.get('siret')?.disable();
    }
    this.formChange();
  }

  formChange() {
    this.form
      .get('motifNonRattachement')
      ?.valueChanges.pipe(distinctUntilChanged(), this.takeUntilDestroyed())
      .subscribe(motif => {
        this.previousMotif = motif;
        this.motifNonRattachementSelected.emit(motif);
        if (motif) {
          this.form.get('siret')?.disable();
          this.structures = null;
          this.selectedStructure = null;
          this.structuresFound.emit(null);
          this.structureSelected.emit(null);
          this.maStructureNotListedButtonVisible = false;
        } else {
          this.form.get('siret')?.enable();
        }
      });
    this.form
      .get('siret')
      ?.valueChanges.pipe(distinctUntilChanged(), this.takeUntilDestroyed())
      .subscribe(siret => {
        if (siret) {
          this.form.get('motifNonRattachement')?.disable();
        } else {
          this.form.get('motifNonRattachement')?.enable();
        }
      });
  }

  search(): void {
    if (this.form.get('siret')?.valid) {
      this.siretService
        .searchSiret(this.form.get('siret')?.value)
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: sieges => {
            const structures = sieges || [];
            this.structures = structures;
            this.maStructureNotListedButtonVisible = structures.length > 0 ? true : this.maStructureNotListedButtonVisibleOnEmptyList;
            this.structuresFound.emit(this.structures);
          },
          error: () => {
            this.showToastrService.error(`Le service est indisponible pour l'instant. Veuillez réessayer ultérieurement. Merci`);
            this.structures = [];
            this.maStructureNotListedButtonVisible = this.maStructureNotListedButtonVisibleOnEmptyList;
            this.structuresFound.emit([]);
          },
        });
    } else {
      this.form.get('siret')?.markAsTouched();
    }
  }

  onMotifChange(event: Event): void {
    // make radio button behave like a checkbox
    const motif = (event.target as HTMLInputElement).value as EnumMotifNonRattachement;
    if (motif === this.previousMotif) {
      this.form.get('motifNonRattachement')?.reset();
    } else {
      this.form.get('motifNonRattachement')?.setValue(motif);
    }
  }

  selectStructure(siege: InformationSiege | null): void {
    this.selectedStructure = siege;
    this.structureSelected.emit(siege);
  }

  onMaStructureNotListedClick() {
    this.selectStructure(null);
    this.maStructureNotListed.emit();
  }
}
