<h2 class="step__title">{{ structureEnCreation ? 'Adresse personnelle' : 'Information du siège' }}</h2>

<form class="step__content form__content">
  <ng-container *ngIf="structureIdentifiedByRET; else notIdentified">
    <h4 class="card__title">A propos de {{ structureForm.get('raisonSocial')?.value }}</h4>
    <p class="fw-normal mt-3">
      Voici les informations concernant votre entreprise.
      <span class="d-block fw-bold">
        Avant de continuer, veuillez vérifier que celles-ci sont correctes et correspondent au contenu de vos documents.
      </span>
    </p>
  </ng-container>
  <ng-template #notIdentified>
    <p>Veuillez saisir manuellement les informations suivantes:</p>
  </ng-template>

  <lib-information-siege
    [structureForm]="structureForm"
    [isPersonnePhysique]="structureEnCreation"
    [readOnly]="structureIdentifiedByRET"
    [readOnlyExceptions]="['typeStructure']"
    [canRequestRectification]="true"
    (rectificationRequested)="onNextButtonClick()"
    (rectificationCanceled)="onRectificationCanceled($event)"
  >
  </lib-information-siege>
</form>

<div class="step__buttons">
  <button id="back-avertissement" class="ds-btn ds-btn--secondary" (click)="onPreviousButtonClick()">Rechercher autre SIRET</button>
  <button id="next-contact" class="ds-btn ds-btn--primary" (click)="onNextButtonClick()">
    {{ structureIdentifiedByRET ? "C'est bien ça" : 'Suivant' }}
  </button>
</div>
