import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentProjet, EnumProjetStatut, EnumScope, Projet, Utilisateur } from "@shared-ui";

@Component({
  selector: 'lib-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent {
  @Input() scope: EnumScope;
  @Input() documents: DocumentProjet[];
  @Input() projet: Projet;
  @Input() projectEtapeName: any;
  @Input() canUserDeleteDocument: boolean;
  @Input() utilisateur: Utilisateur;
  @Input() showTag: boolean;

  @Output() documentsChange = new EventEmitter<DocumentProjet[]>();


  canDownload(document: DocumentProjet): boolean {
    return (this.scope !== EnumScope.STRUCTURE && this.scope !== EnumScope.STRUCTURE_ACTIONARIAL) || this.utilisateur.matricule === document.createur;
  }

  onDeleteDocument(document: DocumentProjet) {
    this.documents = this.documents.filter(doc => doc.id !== document.id);
    this.documentsChange.emit(this.documents);
  }

  protected readonly EnumProjetStatut = EnumProjetStatut;
}
