<ng-container id="signataires" *ngFor="let signataire of signataires">
    <!-- Carte 'signataires' -->
    <div
      id="signataire"
      class="card card--signataire card--signataire-overwrite"
      [lang]="!isStructureUpdatableByUser"
      [class.card--inactive]="!isStructureUpdatableByUser"
      (click)="onGoToSignataire(signataire)"
    >
      <h4 id="signataire-title" class="card__title card__title_overwrite">
        <p class="card__email_overwrite">{{ signataire.nom }} {{ signataire.prenom }}</p>
      </h4>
      <div id="signataire-tags" class="signataire-tags">
        <div class="card__tag card__tag--blue card__tag_overwrite" *ngIf="signataire.representantLegal">Représentant légal</div>
        <div class="card__tag card__tag--yellow card__tag_overwrite" *ngIf="signataire.isFromFicheDemandeAide">
          Fiche de demande d’aide
        </div>
        <div
          class="card__tag card__tag--blue card__tag_overwrite"
          *ngIf="!signataire.isFromFicheDemandeAide && !signataire.representantLegal"
        >
          Signataire
        </div>
      </div>

      <div id="signataire-email" class="card__email card__email_overwrite">{{ signataire.email }}</div>
      <div id="signataire-telephone" class="card__telephone">{{ parsePhoneNumber(signataire.telephone) }}</div>

      <lib-pxl-icon
        class="card__arrow"
        icon="icon-arrow-arrow-forward-ios"
        aria-hidden="true"
        *ngIf="isStructureUpdatableByUser"
      ></lib-pxl-icon>
    </div>
    <!-- FIN de Carte 'signataires' -->
</ng-container>
