<div class="sidenav-projet">
  <div class="sidenav-projet__header">
    <a id="aap-nom" routerLink="/projets" class="sidenav-projet__back">
      <pxl-icon class="icon" icon="icon-arrow-arrow-back"></pxl-icon>
      Tous mes projets
    </a>
    <h1 id="projet-nom" class="sidenav-projet__title" *ngIf="projet.nom">{{ projet.acronyme }}</h1>
    <h1 id="no-projet-nom" class="sidenav-projet__title" *ngIf="!projet.nom">Nouveau projet</h1>

    <div class="sidenav-projet__tags">
      <div id="etape-projet" class="sidenav-projet__tag">{{ EnumProjetEtape.toString(getEtapeProjet().nom) }}</div>
      <div id="statut-projet" class="sidenav-projet__tag">
        {{ EnumProjetStatut.toString(projet.statut, EnumProjetEtape.hasNomFeminin(getEtapeProjet().nom)) }}
      </div>
      <div *ngIf="modificationTag" class="sidenav-projet__tag sidenav-projet__tag__light">{{ modificationTag }}</div>
    </div>

    <pxl-sidebar-button class="sidenav-projet__burger"></pxl-sidebar-button>
  </div>

  <nav class="sidenav-projet__nav">
    <ul class="ds-menu__group sidenav__menu">
      <li routerLinkActive="sidenav__link-wrapper-active">
        <a routerLink="projet-apercu" class="sidenav__link">
          <pxl-icon class="icon" icon="icon-action-check-circle"></pxl-icon>
          <div class="sidenav__link-label">Mes tâches à réaliser</div>
          <!-- Icon displayed when the sidebar is closed -->
          <div class="sidenav__icon--sidebar-closed">
            <pxl-icon class="icon" icon="icon-action-check-circle"></pxl-icon>
          </div>
        </a>
      </li>

      <li routerLinkActive="sidenav__link-wrapper-active">
        <a routerLink="projet-info" class="sidenav__link">
          <pxl-icon class="icon" icon="icon-menu-folder-in-personal"></pxl-icon>
          <div class="sidenav__link-label">Projet</div>
          <!-- Icon displayed when the sidebar is closed -->
          <div class="sidenav__icon--sidebar-closed">
            <pxl-icon class="icon" icon="icon-menu-folder-in-personal"></pxl-icon>
          </div>
        </a>
        <a routerLink="projet-info-general" class="sidenav__link" style="display: none"></a>
      </li>

      <li routerLinkActive="sidenav__link-wrapper-active">
        <a routerLink="projet-consortium" class="sidenav__link">
          <pxl-icon class="icon" icon="icon-human-supervised-user-circle"></pxl-icon>
          <div *ngIf="projet.partenaireType === EnumTypePartenaire.MULTI" class="sidenav__link-label">Consortium</div>
          <div *ngIf="projet.partenaireType === EnumTypePartenaire.INDIV" class="sidenav__link-label">Structure</div>
          <!-- Icon displayed when the sidebar is closed -->
          <div class="sidenav__icon--sidebar-closed">
            <pxl-icon class="icon" icon="icon-human-supervised-user-circle"></pxl-icon>
          </div>
        </a>
        <a routerLink="projet-consortium-creation" class="sidenav__link" style="display: none"></a>
        <a routerLink="projet-consortium-info" class="sidenav__link" style="display: none"></a>
      </li>
    </ul>
  </nav>

  <div id="aap-logo" class="sidenav-projet__footer">
    <div *ngIf="aap.logo" class="sidenav-projet__logo">
      <img alt="logo aap" src="{{ this.originUrl + aap.logo }}" />
    </div>

    <div id="aap-contacts" *ngIf="aap.bal" class="sidenav-projet__footer-link">
      <a [href]="emailString">Contacts</a>
    </div>
    <div id="aap-documentation" *ngIf="aap.lienDocumentation" class="sidenav-projet__footer-link">
      <a target="_blank" [attr.href]="aap.lienDocumentation">Détails AAP</a>
    </div>
  </div>
</div>
