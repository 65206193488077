import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Contact,
  DemandeComplement,
  EnumDocumentComplementaireType,
  EnumRoleStructure,
  EnumScope,
  EnumStatutDemandeDocumentComplementaire,
  Projet,
  Structure,
} from '@shared-ui';

@Component({
  selector: 'lib-demande-document-complementaire-container',
  templateUrl: './demande-document-complementaire-container.component.html',
  styleUrls: ['./demande-document-complementaire-container.component.scss'],
})
export class DemandeDocumentComplementaireContainerComponent implements OnInit {
  @Input() demande: DemandeComplement;
  @Input() canWrite: boolean;
  @Input() isArchived: boolean;
  @Input() projet: Projet;
  @Output() updateStatutDemandeComplementEmitter = new EventEmitter();

  contactsText: string;
  structuresText: string;

  readMoreContacts = false;
  readMoreStructures = false;
  isLongContacts = false;
  isLongStructures = false;

  readonly MAX_TEXT_LENGTH = 250;

  readonly CORRECTION_VALIDEE_LABEL = EnumStatutDemandeDocumentComplementaire.VALIDEE.toString();
  readonly docComplementaireType = EnumDocumentComplementaireType;

  constructor() {}

  ngOnInit(): void {
    this.contactsText = this.demande.contactsANotifier?.map((contact: Contact) => `${contact.prenom} ${contact.nom}`).join(', ') ?? '';
    this.isLongContacts = this.contactsText.length > this.MAX_TEXT_LENGTH;
    this.structuresText =
      this.demande.structures
        ?.map((structure: Structure) => `${structure.raisonSocial} (${EnumRoleStructure.toString(structure.role)})`)
        .join(', ') ?? '';
    this.isLongStructures = this.structuresText.length > this.MAX_TEXT_LENGTH;
  }

  getSentToContactsText(): string {
    return !this.readMoreContacts && this.isLongContacts ? this.contactsText.slice(0, this.MAX_TEXT_LENGTH) + '...' : this.contactsText;
  }

  getSentToStructuresText(): string {
    return !this.readMoreStructures && this.isLongStructures
      ? this.structuresText.slice(0, this.MAX_TEXT_LENGTH) + '...'
      : this.structuresText;
  }

  getSentToDocumentsText(): string {
    return !this.readMoreStructures && this.isLongStructures
      ? this.structuresText.slice(0, this.MAX_TEXT_LENGTH) + '...'
      : this.structuresText;
  }

  getCorrectionStatus(demande: DemandeComplement): string {
    return (EnumStatutDemandeDocumentComplementaire as any)[demande.statut];
  }

  afficherPlusContacts(): void {
    this.readMoreContacts = !this.readMoreContacts;
  }

  afficherPlusStructures(): void {
    this.readMoreStructures = !this.readMoreStructures;
  }

  protected readonly EnumScope = EnumScope;
}
