<div>
  <div class="card--info">
    <pxl-icon class="icon" icon="icon-action-info"></pxl-icon>
    Votre mode de connexion à PICXEL a évolué pour une meilleure expérience. Vos identifiants sont les mêmes.
  </div>
  <div class="welcome">
    <div class="welcome__image">
      <img src="../assets/image-accueil.png" alt="" />
    </div>
    <div class="welcome__text">
      <div class="card-accueil" (click)="connexion()">
        <a class="lien-background">Se connecter</a>
        <pxl-icon class="icon-right" icon="icon-arrow-chevron-right" aria-hidden="true"></pxl-icon>
      </div>
      <div class="card-accueil" (click)="inscription()">
        <a>Créer un compte</a>
        <pxl-icon class="icon-right" icon="icon-arrow-chevron-right" aria-hidden="true"></pxl-icon>
      </div>
      <h3 class="welcome__title"><span>Projets innovants</span> collaboratifs et&nbsp;expertises en ligne</h3>
      <p>
        Bpifrance coordonne en lien avec l’Etat et les différentes directions de Bpifrance de nombreux programmes d’aide à l’innovation pour
        financer vos projets innovants.
      </p>
      <p>Inscrivez-vous pour concourir.</p>
    </div>
  </div>
</div>
<pxl-footer></pxl-footer>
