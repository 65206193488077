<h2 id="modal-title" class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>

<div id="input-typeDoc" class="modal__content">
  <div class="card--info mb-3" *ngIf="!modalData.isProjetInfoPage">
    <pxl-icon class="icon" icon="icon-action-info"></pxl-icon>
    Au sein d'une structure seule la personne qui upload le fichier est en mesure de le télécharger.
  </div>
  <div class="card--info mb-3">
    <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
    Tous les documents de type Kbis, CNI, Statuts, Table de capitalisation et Activités avec des pays sanctionnés, doivent être fournis dans
    l'un des formats suivants : .pdf, .gif, .png, .jpg, .jpeg, .bmp, .tif, .tiff, .msg
  </div>
  <label for="typeDoc" class="">
    <span class="sub_label">Type de fichier</span>
  </label>
  <div class="ds-input-group__select ds-input-group__select--with-icon">
    <select id="typeDoc" name="typeDoc" [(ngModel)]="chosenType" (change)="onSelected()">
      <option value="null" selected disabled>Selectionnez une valeur</option>
      <option *ngFor="let item of modalData.docList" [ngValue]="item">{{ item.nom }}</option>
    </select>
  </div>
</div>

<div id="modal-btn" class="modal__actions">
  <button *ngIf="modalData.textReturnButton" class="ds-btn ds-btn--secondary" mat-dialog-close>
    {{ modalData.textReturnButton }}
  </button>
  <button class="ds-btn ds-btn--primary" *ngIf="modalData.isAuthorized" (click)="files.click()" [disabled]="!chosenType">
    {{ modalData.textGoButton }}
  </button>
</div>

<div class="input-file">
  <input type="file" id="files" (change)="onUpload($event)" [accept]="accept" #files />
</div>
