import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnumRoleStructure } from '../../../shared/enums/enum.roleStructure';
import { EnumStatutDemandeCorrection, EnumStatutDemandeCorrectionTech } from '../../../shared/enums/enum.statutDemandeCorrection';
import { Projet } from '../../../shared/models/projet.model';
import { DemandeCorrection } from '../../../shared/models/demande-correction.model';
import { DocumentDemandeModification } from '../../../shared/models/document-demande-modification.model';
import { ShowToastrService } from '../../../shared/services/show-toastr.service';
import { UploadDocumentHttpService } from '../../../shared/services/upload-document.http.service';
import { EnumScanDocument } from '../../../shared/enums/enum.scanDocument';
import { EnumScope } from '../../../shared/enums/enum.scope';

@Component({
  selector: 'lib-demande-modification-container',
  templateUrl: './demande-modification-container.component.html',
  styleUrls: ['./demande-modification-container.component.scss'],
})
export class DemandeModificationContainerComponent implements OnInit {
  @Input() demande: DemandeCorrection;
  @Input() isArchived: boolean;
  @Input() projet: Projet;
  @Output() updateStatutCorrectionEmitter = new EventEmitter();

  contactsText: string;
  structuresText: string;

  readMoreContacts = false;
  readMoreStructures = false;
  isLongContacts = false;
  isLongStructures = false;

  readonly MAX_TEXT_LENGTH = 250;
  readonly VALIDE = EnumStatutDemandeCorrectionTech.VALIDE;
  readonly REJETE = EnumStatutDemandeCorrectionTech.REJETE;
  readonly CORRIGE = EnumStatutDemandeCorrectionTech.CORRIGE;
  readonly CORRECTION_REJETE_LABEL = EnumStatutDemandeCorrection.REJETE.toString();
  readonly CORRECTION_VALIDE_LABEL = EnumStatutDemandeCorrection.VALIDE.toString();

  constructor(public uploadDocumentService: UploadDocumentHttpService, public showToastrService: ShowToastrService) {}

  ngOnInit(): void {
    this.contactsText = this.demande.contactsANotifier?.map(contact => `${contact.prenom} ${contact.nom}`).join(', ') ?? '';
    this.isLongContacts = this.contactsText.length > this.MAX_TEXT_LENGTH;
    this.structuresText =
      this.demande.structures?.map(structure => `${structure.raisonSocial} (${EnumRoleStructure.toString(structure.role)})`).join(', ') ??
      '';
    this.isLongStructures = this.structuresText.length > this.MAX_TEXT_LENGTH;
  }

  getSentToContactsText(): string {
    return !this.readMoreContacts && this.isLongContacts ? this.contactsText.slice(0, this.MAX_TEXT_LENGTH) + '...' : this.contactsText;
  }

  getSentToStructuresText(): string {
    return !this.readMoreStructures && this.isLongStructures
      ? this.structuresText.slice(0, this.MAX_TEXT_LENGTH) + '...'
      : this.structuresText;
  }

  /*
   * Vérifie si le document uploadé a passé le test antivirus
   * */
  isValidDocument(document: DocumentDemandeModification): boolean {
    if (!!document?.nom && document?.scan === EnumScanDocument.SAFE) {
      return true;
    }
    return false;
  }

  isScannedDocument(document: DocumentDemandeModification): boolean {
    if (document?.scan === EnumScanDocument.SAFE || document?.scan === EnumScanDocument.UNSAFE) {
      return true;
    }
    return false;
  }

  downloadDocument(document: DocumentDemandeModification): void {
    if (this.isValidDocument(document) && this.isScannedDocument(document)) {
      this.uploadDocumentService.getValueForDocModificationDownload(this.projet, document).subscribe({
        next: (response: any) => {
          const url = response.body.url;
          window.open(url);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    }
  }

  getCorrectionStatus(demande: DemandeCorrection): string {
    return (EnumStatutDemandeCorrection as any)[demande.statut];
  }

  afficherPlusContacts(): void {
    this.readMoreContacts = !this.readMoreContacts;
  }

  afficherPlusStructures(): void {
    this.readMoreStructures = !this.readMoreStructures;
  }

  protected readonly EnumScope = EnumScope;
}
