// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const IS_LOCAL = false;
export const MOCK_TOKEN =
  'eyJraWQiOiJPQzNmeEhTcDloanRDSFhhMTBWZUltM2wwc2sxeFgxS0UwNkhQTkd2UnFVIiwiYWxnIjoiUlMyNTYifQ.eyJncm91cHMiOiJQWExfQURNSU5fR1JPVVBFIiwicnRfaGFzaCI6Ik91NFRWdXpMS1Npamk1S21HRWVBY2ciLCJub25jZSI6ImZiMzhjNDQyOTZlZTJjNGJlOTcxYTExMWFlZDVlYzVlZTY5N1NmNUZmIiwiZW1haWxBZGRyZXNzIjoieWFzc2luZS50YWhyaUBleHQuYnBpZnJhbmNlLmZyIiwiaWF0IjoxNjY5Nzk5NDE5LCJpc3MiOiJodHRwczovL2F1dGhtb2Eud2ViLmJwaWZyYW5jZS5mciIsImF0X2hhc2giOiJyMDNrd0kwbDBpTUU4UGhNNkFJc1hBIiwic3ViIjoibTEwNDkyIiwibGFzdE5hbWUiOiJUQUhSSSIsIm1hdCI6Ik0xMDQ5MiIsImZpcnN0TmFtZSI6Illhc3NpbmUiLCJleHAiOjE2Njk4MDAzMTksImF1ZCI6IjVsSTJRam1tNFdzbEFJT1Q2NVk1In0.LYAyUat-heZ0I3GAMO4NRxDUADyBG_-z8pxwj-QI5hfq9isslgHYQM2nYVROANlETbfCfY3UWpRGI9fwBY6t5K_58Zd-7o_Ea6nNV08P9g9GZ29HjU4NqGLfq--gkad4G42cItrUZS1OInSrIMXj2WakPzlaQpxIlO4SynInOQm3tpZClcqoMGCSHloWfbnO027DqIDZ4n4zEbjPztCtUym0Gq6aKTyz6DzDG0h59JYRmvJUJGIbr_J1di9-1XKTceowmUbnurjpaW2qQAFXNdwAjpeRQ9fl_PILlW_gs6EZcJSzV-5XHw74UwU9RdHB1eHO3JK_yovokwX4yATKmw';

export const environment = {
  production: false,
  name: (window as any)['env']['name'] || 'local',
  isLocal: (window as any)['env']['isLocal'] || IS_LOCAL,
  mockToken: (window as any)['env']['mockToken'] || MOCK_TOKEN,
  apiUrl:
    (window as any)['env']['apiUrl'] || (IS_LOCAL ? 'http://localhost:8083/api/' : 'https://pxl.dev.api-at.cloud.bpifrance.fr/main/v1/'),
  apiNotif: 'http://localhost:8082/api/',
  isam: {
    stsServer: (window as any)['env']['stsServer'] || 'https://authmoa.web.bpifrance.fr',
    authWellknownEndpoint:
      (window as any)['env']['authWellknownEndpoint'] || 'https://authmoa.web.bpifrance.fr/mga/sps/oauth/oauth20/metadata/OIDCP_All-Prof',
    clientId: (window as any)['env']['clientId'] || '5lI2Qjmm4WslAIOT65Y5',
    scopes: 'openid profile email api offline_access',
  },
  mcb: {
    enabled: (window as any)['env']['mcbEnabled'] || true,
    stsServer: (window as any)['env']['stsServerMCB'] || 'https://mst.mcb-nprd.cloud.bpifrance.fr:443/am/oauth2/mcb',
    authWellknownEndpoint:
      (window as any)['env']['authWellknownEndpointMCB'] ||
      'https://mst.mcb-nprd.cloud.bpifrance.fr/am/oauth2/realms/root/realms/mcb/.well-known/openid-configuration',
    clientId: (window as any)['env']['clientIdMCB'] || 'picxel-spa',
    scopes: 'openid profile email',
    createAccountUrl:
      (window as any)['env']['createAccountUrl'] || 'https://mst.mcb-nprd.cloud.bpifrance.fr/am/oauth2/realms/root/realms/mcb/authorize',
    profil:
      (window as any)['env']['profilMCB'] ||
      'https://mst.mcb-nprd.cloud.bpifrance.fr/am/oauth2/realms/root/realms/mcb/authorize?client_id=picxel-spa&redirect_uri=https://mst.mcb-nprd.cloud.bpifrance.fr/profile&scope=openid%20profile%20email&response_type=code',
  },
  response_type: (window as any)['env']['response_type'] || 'code',
  espaceEval: (window as any)['env']['espaceEval'] || 'https://evaluateur.dev.pxl-nprd.cloud.bpifrance.fr/',
  docPublicBaseUrl: (window as any)['env']['docPublicBaseUrl'] || 'https://evaluateur.dev.pxl-nprd.cloud.bpifrance.fr/document-public/',
  docLogosPublicBaseUrl: (window as any)['env']['docLogosPublicBaseUrl'] || 'https://candidat.dev.pxl-nprd.cloud.bpifrance.fr/logos/',
  stub: false,
  stubUsers: false,
  stubAaps: false,
  stubProjets: false,
  stubAuth: false,
  stubDoc: true,
};
