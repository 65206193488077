import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AideDemandeAide, EnumTypeAide, FicheDemandeAide } from '@shared-ui';

@Component({
  selector: 'lib-fiche-demande-aide-liste-aides',
  templateUrl: './fiche-demande-aide-liste-aides.component.html',
  styleUrls: ['./fiche-demande-aide-liste-aides.component.scss'],
})
export class FicheDemandeAideListeAidesComponent implements OnInit {
  @Input() ficheDemandeAideListeAidesForm: FormGroup;
  @Input() ficheDemandeAide: FicheDemandeAide;

  selectionAidesObtenues = new SelectionModel<number>(true, []);
  selectionAidesEnAttente = new SelectionModel<number>(true, []);

  displayedColumnsAidesObtenues: string[] = ['select', 'nomProjet', 'theme', 'nature', 'organismeFinanceur', 'montant', 'annee'];
  dataSourceAidesObtenues = new MatTableDataSource<AideDemandeAide>();
  dataSourceAidesObtenuesArray: AideDemandeAide[] = [];

  displayedColumnsAidesEnAttente: string[] = ['select', 'nomProjet', 'theme', 'nature', 'organismeFinanceur', 'montant', 'annee'];
  dataSourceAidesEnAttente = new MatTableDataSource<AideDemandeAide>();
  dataSourceAidesEnAttenteArray: AideDemandeAide[] = [];

  isDisplayAidesObtenuesForm = false;
  isDisplayAidesEnAttenteForm = false;

  tooltipText = 'Précisez les sommes des montants perçus et des montants restant à percevoir';

  get aidesObtenuesFormArray(): FormArray {
    return this.ficheDemandeAideListeAidesForm.get('aidesObtenues') as FormArray;
  }

  get aidesEnAttenteFormArray(): FormArray {
    return this.ficheDemandeAideListeAidesForm.get('aidesEnAttente') as FormArray;
  }

  constructor() {}

  ngOnInit(): void {
    const aidesObtenues = this.ficheDemandeAide.aides?.filter(aide => aide.type === 'OBTENU');
    const aidesEnAttente = this.ficheDemandeAide.aides?.filter(aide => aide.type === 'EN_ATTENTE');

    aidesObtenues?.forEach(aide => {
      (this.ficheDemandeAideListeAidesForm.get('aidesObtenues') as FormArray).push(this.createFormGroup(EnumTypeAide.OBTENU, aide));
    });

    aidesEnAttente?.forEach(aide => {
      (this.ficheDemandeAideListeAidesForm.get('aidesEnAttente') as FormArray).push(this.createFormGroup(EnumTypeAide.EN_ATTENTE, aide));
    });

    this.isDisplayAidesObtenuesForm = this.ficheDemandeAideListeAidesForm.get('obtenuAidesTroisDernieresAnnees')?.value;
    this.isDisplayAidesEnAttenteForm = this.ficheDemandeAideListeAidesForm.get('autresDemandesAidesDeposees')?.value;

    if ((this.ficheDemandeAideListeAidesForm.get('aidesObtenues') as FormArray).length === 0 && this.isDisplayAidesObtenuesForm) {
      this.addAidesObtenues();
    }
    if ((this.ficheDemandeAideListeAidesForm.get('aidesEnAttente') as FormArray).length === 0 && this.isDisplayAidesEnAttenteForm) {
      this.addAidesEnAttente();
    }
    this.updateTable();

    this.checkAides();
  }

  checkAides(): void {
    this.ficheDemandeAideListeAidesForm?.get('obtenuAidesTroisDernieresAnnees')?.valueChanges.subscribe(value => {
      if (value) {
        this.ficheDemandeAideListeAidesForm.get('aidesObtenues')?.enable();
        if ((this.ficheDemandeAideListeAidesForm.get('aidesObtenues') as FormArray)?.length === 0) {
          this.addAidesObtenues();
        }
        this.isDisplayAidesObtenuesForm = true;
      } else {
        (this.ficheDemandeAideListeAidesForm.get('aidesObtenues') as FormArray)?.clear();
        this.ficheDemandeAideListeAidesForm.get('aidesObtenues')?.disable();
        this.isDisplayAidesObtenuesForm = false;
      }
    });

    this.ficheDemandeAideListeAidesForm?.get('autresDemandesAidesDeposees')?.valueChanges.subscribe(value => {
      if (value) {
        this.ficheDemandeAideListeAidesForm.get('aidesEnAttente')?.enable();
        if ((this.ficheDemandeAideListeAidesForm.get('aidesEnAttente') as FormArray)?.length === 0) {
          this.addAidesEnAttente();
        }
        this.isDisplayAidesEnAttenteForm = true;
      } else {
        (this.ficheDemandeAideListeAidesForm.get('aidesEnAttente') as FormArray)?.clear();
        this.ficheDemandeAideListeAidesForm.get('aidesEnAttente')?.disable();
        this.isDisplayAidesEnAttenteForm = false;
      }
    });
  }

  updateTable(): void {
    this.dataSourceAidesObtenuesArray = this.ficheDemandeAideListeAidesForm.get('aidesObtenues')?.value || [];

    this.dataSourceAidesObtenues = new MatTableDataSource<AideDemandeAide>(this.dataSourceAidesObtenuesArray);

    this.dataSourceAidesEnAttenteArray = this.ficheDemandeAideListeAidesForm.get('aidesEnAttente')?.value || [];

    this.dataSourceAidesEnAttente = new MatTableDataSource<AideDemandeAide>(this.dataSourceAidesEnAttenteArray);
  }

  addAidesObtenues(): void {
    const aidesObtenuesArray = this.ficheDemandeAideListeAidesForm.get('aidesObtenues') as FormArray;

    aidesObtenuesArray.push(this.createFormGroup(EnumTypeAide.OBTENU));

    this.updateTable();
  }

  removeAidesObtenues(): void {
    const aidesObtenuesArray = this.ficheDemandeAideListeAidesForm.get('aidesObtenues') as FormArray;

    if (this.selectionAidesObtenues.selected.length > 0) {
      this.selectionAidesObtenues.selected.forEach((selectedindex, index) => {
        aidesObtenuesArray.removeAt(selectedindex - index);
      });
      this.selectionAidesObtenues.clear();
    } else if (aidesObtenuesArray.length > 0) {
      aidesObtenuesArray.removeAt(aidesObtenuesArray.length - 1);
    }

    if (aidesObtenuesArray.length === 0) {
      this.addAidesObtenues();
    }

    this.updateTable();
  }

  addAidesEnAttente(): void {
    const aidesEnAttenteArray = this.ficheDemandeAideListeAidesForm.get('aidesEnAttente') as FormArray;

    aidesEnAttenteArray.push(this.createFormGroup(EnumTypeAide.EN_ATTENTE));

    this.updateTable();
  }

  removeAidesEnAttente(): void {
    const aidesEnAttenteArray = this.ficheDemandeAideListeAidesForm.get('aidesEnAttente') as FormArray;

    if (this.selectionAidesEnAttente.selected.length > 0) {
      this.selectionAidesEnAttente.selected.forEach((selectedindex, index) => {
        aidesEnAttenteArray.removeAt(selectedindex - index);
      });
      this.selectionAidesEnAttente.clear();
    } else if (aidesEnAttenteArray.length > 0) {
      aidesEnAttenteArray.removeAt(aidesEnAttenteArray.length - 1);
    }

    if (aidesEnAttenteArray.length === 0) {
      this.addAidesEnAttente();
    }

    this.updateTable();
  }

  isAllSelectedAidesObtenues(): boolean {
    const numSelected = this.selectionAidesObtenues.selected.length;
    const numRows = this.dataSourceAidesObtenues.data.length;
    return numSelected === numRows;
  }

  masterToggleAidesObtenues(): void {
    this.isAllSelectedAidesObtenues()
      ? this.selectionAidesObtenues.clear()
      : this.dataSourceAidesObtenues.data.forEach((_row, index) => this.selectionAidesObtenues.select(index));
  }

  isAllSelectedAidesEnAttente(): boolean {
    const numSelected = this.selectionAidesEnAttente.selected.length;
    const numRows = this.dataSourceAidesEnAttente.data.length;
    return numSelected === numRows;
  }

  masterToggleAidesEnAttente(): void {
    this.isAllSelectedAidesEnAttente()
      ? this.selectionAidesEnAttente.clear()
      : this.dataSourceAidesEnAttente.data.forEach((_row, index) => this.selectionAidesEnAttente.select(index));
  }

  onkeypress($event: KeyboardEvent): boolean {
    if ($event.key) {
      if (/[^\d]/.test($event.key)) {
        return false;
      }
    }
    return true;
  }

  createFormGroup(type: EnumTypeAide, aide?: AideDemandeAide): FormGroup {
    return new FormGroup({
      nomProjet: new FormControl(aide?.nomProjet || '', Validators.required),
      theme: new FormControl(aide?.theme || '', Validators.required),
      nature: new FormControl(aide?.nature || '', Validators.required),
      organismeFinanceur: new FormControl(aide?.organismeFinanceur || '', Validators.required),
      montant: new FormControl(aide?.montant || '', Validators.required),
      annee: new FormControl(aide?.annee || '', Validators.required),
      type: new FormControl(type, Validators.required),
    });
  }
}
