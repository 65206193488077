<!-- Entête de la page -->
<header id="header-structure" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToProjetInfo()">
    <pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></pxl-icon>
    Projet
  </a>
  <h2 class="page-header__title">Enquête projet</h2>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<main class="page-content">
  <div class="card--info card--blue card--readonly" *ngIf="!isProjectUpdatableByUser">
    <pxl-icon class="icon" icon="icon-action-info"></pxl-icon>
    Vous êtes en lecture seule sur cette page. Vous ne pouvez pas éditer son contenu.
  </div>
  <form id="projet-form" class="form" [formGroup]="enqueteForm" (ngSubmit)="saveEnqueteProjet()">
    <!-- Section connaissance -->
    <section class="form-step" [formGroup]="enqueteForm.controls['connaissance']">
      <div
        [class.ds-input-group--error]="
          enqueteForm.controls['connaissance'].get('value').invalid && enqueteForm.controls['connaissance'].get('value').touched
        "
      >
        <div class="ds-input-group__select ds-input-group__select--with-icon">
          <div class="toggle-title-container">
            <label for="connaissance-value" class="ds-input-group__label">
              Comment avez-vous eu connaissance de l'ouverture de cette nouvelle session d'inscription au concours
            </label>
            <span class="ds-input-group__label-asterisk"> *</span>
          </div>
          <select id="connaissance-value" name="connaissance-value" formControlName="value" #connaissanceValue>
            <option [ngValue]="null" selected disabled hidden>Sélectionnez une option</option>
            <option *ngFor="let item of enumConnaissance | keyvalue : valueOrder" value="{{ item.key }}">{{ item.value }}</option>
          </select>
        </div>

        <!-- Icône et message d'erreur -->
        <ng-container
          *ngIf="enqueteForm.controls['connaissance'].get('value').invalid && enqueteForm.controls['connaissance'].get('value').touched"
        >
          <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
        </ng-container>
      </div>

      <div
        [class.ds-input-group--error]="
          enqueteForm.controls['connaissance'].get('site').invalid && enqueteForm.controls['connaissance'].get('site').touched
        "
      >
        <div
          class="ds-input-group__select ds-input-group__select--with-icon"
          *ngIf="connaissanceValue.value === enumEnqueteConnaissanceTech[enumEnqueteConnaissanceTech.SITE_INTERNET]"
          id="site-select"
        >
          <label for="connaissance-site" class="ds-input-group__label">Quel site ?</label>
          <span class="ds-input-group__label-asterisk"> *</span>
          <select id="connaissance-site" name="connaissance-site" formControlName="site">
            <option [ngValue]="null" selected disabled hidden>Sélectionnez une option</option>
            <option *ngFor="let item of enumConnaissanceSite | keyvalue : valueOrder" value="{{ item.key }}">{{ item.value }}</option>
          </select>
        </div>

        <!-- Icône et message d'erreur -->
        <ng-container
          *ngIf="enqueteForm.controls['connaissance'].get('site').invalid && enqueteForm.controls['connaissance'].get('site').touched"
        >
          <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
        </ng-container>
      </div>
    </section>
    <!-- Fin section connaissance -->

    <!-- Section recherche publique -->
    <pxl-enquete-radio-toggle
      (toggled)="helperService.toggleValidation($event)"
      id="recherche-publique"
      title="Votre projet repose-t-il sur l'exploitation de résultats de la recherche publique ?"
      [radioToggleGroup]="enqueteForm.get('recherchePublique')"
    >
      <div class="form-container" [formGroup]="enqueteForm.controls['recherchePublique']">
        <div
          [class.ds-input-group--error]="
            enqueteForm.controls['recherchePublique'].get('value').invalid && enqueteForm.controls['recherchePublique'].get('value').touched
          "
        >
          <div class="ds-input-group__select ds-input-group__select--with-icon">
            <label for="connaissance-value" class="ds-input-group__label">Veuillez indiquer l'organisme</label>
            <span class="ds-input-group__label-asterisk"> *</span>
            <select id="connaissance-value" name="connaissance-value" formControlName="value">
              <option [ngValue]="null" selected disabled hidden>Sélectionnez une option</option>
              <option *ngFor="let item of enumRecherchePublique | keyvalue : valueOrder" value="{{ item.key }}">{{ item.value }}</option>
            </select>
          </div>

          <!-- Icône et message d'erreur -->
          <ng-container
            *ngIf="
              enqueteForm.controls['recherchePublique'].get('value').invalid &&
              enqueteForm.controls['recherchePublique'].get('value').touched
            "
          >
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
          </ng-container>
        </div>

        <div
          [class.ds-input-group--error]="
            enqueteForm.controls['recherchePublique'].get('description').invalid &&
            enqueteForm.controls['recherchePublique'].get('description').touched
          "
        >
          <div class="form-step__description ds-input-group" id="rp-description">
            <label for="rp-description" class="ds-input-group__label"
              >Indiquez le nom, l'adresse et l'appartenance du (ou des) laboratoire(s)</label
            >
            <span class="ds-input-group__label-asterisk"> *</span>
            <textarea
              id="creation_projet_description"
              name="description"
              rows="5"
              col="0"
              [maxlength]="TEXTAREA_MAX_LENGTH"
              class="ds-input-group__textarea"
              formControlName="description"
              #rpDescriptionInput
            ></textarea>
            <div
              class="form-input__length-hint"
              [class.form-input__length-hint--full]="rpDescriptionInput.value?.length === TEXTAREA_MAX_LENGTH"
            >
              <span>Caractères autorisés {{ rpDescriptionInput.value?.length || 0 }}/{{ TEXTAREA_MAX_LENGTH }}</span>
            </div>
          </div>

          <!-- Icône et message d'erreur -->
          <ng-container
            *ngIf="
              enqueteForm.controls['recherchePublique'].get('description').invalid &&
              enqueteForm.controls['recherchePublique'].get('description').touched
            "
          >
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
          </ng-container>
        </div>
      </div>
    </pxl-enquete-radio-toggle>
    <!-- Fin section recherche publique -->

    <!-- Section etablissement public -->
    <section class="form-step" [formGroup]="enqueteForm.controls['etablissementPublic']">
      <div
        [class.ds-input-group--error]="
          enqueteForm.controls['etablissementPublic'].get('value').invalid &&
          enqueteForm.controls['etablissementPublic'].get('value').touched
        "
      >
        <div class="title-container">
          <div>
            <label class="toggle-title">Le brevet a-t-il été déposé par un établissement public ?</label>
            <span class="ds-input-group__label-asterisk"> *</span>
          </div>
          <div>
            <label class="ds-radio-classic" for="false">
              <input type="radio" class="ds-radio__input" id="false" name="value" formControlName="value" value="NON" />
              <span class="ds-radio__label"><span>Non</span></span>
            </label>
            <label class="ds-radio-classic" for="true">
              <input type="radio" class="ds-radio__input" id="true" name="value" formControlName="value" value="OUI" />
              <span class="ds-radio__label"><span>Oui</span></span>
            </label>
            <label class="ds-radio-classic" for="sans_objet">
              <input type="radio" class="ds-radio__input" id="sans_objet" name="value" formControlName="value" value="SANS_OBJET" />
              <span class="ds-radio__label"><span>Sans objet</span></span>
            </label>
          </div>
        </div>

        <!-- Icône et message d'erreur -->
        <ng-container
          *ngIf="
            enqueteForm.controls['etablissementPublic'].get('value').invalid &&
            enqueteForm.controls['etablissementPublic'].get('value').touched
          "
        >
          <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
        </ng-container>
      </div>
    </section>
    <!-- Fin section etablissement public -->

    <!-- Section autre recherche publique -->
    <pxl-enquete-radio-toggle
      (toggled)="helperService.toggleValidation($event)"
      id="autre-recherche-publique"
      title="Votre projet entretient-il une autre relation avec la recherche publique ?"
      [radioToggleGroup]="enqueteForm.get('autreRecherchePublique')"
    >
      <div
        [class.ds-input-group--error]="
          enqueteForm.controls['autreRecherchePublique'].get('value').invalid &&
          enqueteForm.controls['autreRecherchePublique'].get('value').touched
        "
      >
        <div class="form-container" [formGroup]="enqueteForm.controls['autreRecherchePublique']">
          <div class="ds-input-group">
            <label for="label" class="ds-input-group__label"
              >Veuillez préciser
              <span class="ds-input-group__label-asterisk"> *</span>
            </label>
            <span class="ds-input-group__input">
              <input
                id="label"
                name="label"
                type="text"
                class="ds-input-group__input"
                [maxlength]="INPUT_TEXT_MAX_LENGTH"
                formControlName="value"
                #autreRpInput
              />
            </span>
            <div
              class="form-input__length-hint"
              [class.form-input__length-hint--full]="autreRpInput.value?.length === INPUT_TEXT_MAX_LENGTH"
            >
              <span>Caractères autorisés {{ autreRpInput.value?.length || 0 }}/{{ INPUT_TEXT_MAX_LENGTH }}</span>
            </div>
          </div>
        </div>

        <!-- Icône et message d'erreur -->
        <ng-container
          *ngIf="
            enqueteForm.controls['autreRecherchePublique'].get('value').invalid &&
            enqueteForm.controls['autreRecherchePublique'].get('value').touched
          "
        >
          <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
        </ng-container>
      </div>
    </pxl-enquete-radio-toggle>
    <!-- Fin section autre recherche publique -->

    <!-- Section incubateur public -->
    <pxl-enquete-radio-toggle
      (toggled)="helperService.toggleValidation($event)"
      id="incubateur-public"
      title="Votre projet est-il ou a-t-il été incubé dans un incubateur public ?"
      [radioToggleGroup]="enqueteForm.get('incubateurPublic')"
    >
      <div
        [class.ds-input-group--error]="
          enqueteForm.controls['incubateurPublic'].get('value').invalid && enqueteForm.controls['incubateurPublic'].get('value').touched
        "
      >
        <div class="form-container" [formGroup]="enqueteForm.controls['incubateurPublic']">
          <div class="ds-input-group__select ds-input-group__select--with-icon">
            <label for="incubateur-public-value" class="ds-input-group__label">Veuillez indiquer lequel</label>
            <span class="ds-input-group__label-asterisk"> *</span>
            <select id="incubateur-public-value" name="incubateur-public-value" formControlName="value">
              <option [ngValue]="null" selected disabled hidden>Sélectionnez une option</option>
              <option *ngFor="let item of enumIncubateurPublic | keyvalue : valueOrder" value="{{ item.key }}">{{ item.value }}</option>
            </select>
          </div>
        </div>

        <!-- Icône et message d'erreur -->
        <ng-container
          *ngIf="
            enqueteForm.controls['incubateurPublic'].get('value').invalid && enqueteForm.controls['incubateurPublic'].get('value').touched
          "
        >
          <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
        </ng-container>
      </div>
    </pxl-enquete-radio-toggle>
    <!-- Fin section incubateur public -->

    <!-- Section incubateur prive -->
    <pxl-enquete-radio-toggle
      (toggled)="helperService.toggleValidation($event)"
      id="incubateur-prive"
      title="Votre projet est-il ou a-t-il été incubé dans un incubateur privé ?"
      [radioToggleGroup]="enqueteForm.get('incubateurPrive')"
    >
      <div
        [class.ds-input-group--error]="
          enqueteForm.controls['incubateurPrive'].get('value').invalid && enqueteForm.controls['incubateurPrive'].get('value').touched
        "
      >
        <div class="form-container" [formGroup]="enqueteForm.controls['incubateurPrive']">
          <div class="ds-input-group">
            <label for="label" class="ds-input-group__label"
              >Veuillez préciser lequel
              <span class="ds-input-group__label-asterisk"> *</span>
            </label>
            <span class="ds-input-group__input">
              <input
                id="label"
                name="label"
                type="text"
                class="ds-input-group__input"
                maxlength="65"
                formControlName="value"
                #incubateurPriveInput
              />
            </span>
            <div class="form-input__length-hint" [class.form-input__length-hint--full]="incubateurPriveInput.value?.length === 65">
              <span>Caractères autorisés {{ incubateurPriveInput.value?.length || 0 }}/65</span>
            </div>
          </div>
        </div>

        <!-- Icône et message d'erreur -->
        <ng-container
          *ngIf="
            enqueteForm.controls['incubateurPrive'].get('value').invalid && enqueteForm.controls['incubateurPrive'].get('value').touched
          "
        >
          <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
        </ng-container>
      </div>
    </pxl-enquete-radio-toggle>
    <!-- Fin section incubateur prive -->

    <!-- Section satt -->
    <pxl-enquete-radio-toggle
      (toggled)="helperService.toggleValidation($event)"
      id="satt"
      title="Votre projet a-t-il été maturé dans une SATT ?"
      [radioToggleGroup]="enqueteForm.get('satt')"
    >
      <div
        [class.ds-input-group--error]="
          enqueteForm.controls['satt'].get('value').invalid && enqueteForm.controls['satt'].get('value').touched
        "
      >
        <div class="form-container" [formGroup]="enqueteForm.controls['satt']">
          <div class="ds-input-group__select ds-input-group__select--with-icon">
            <label for="satt-value" class="ds-input-group__label">Veuillez indiquer laquelle</label>
            <span class="ds-input-group__label-asterisk"> *</span>
            <select id="satt-value" name="satt-value" formControlName="value">
              <option [ngValue]="null" selected disabled hidden>Sélectionnez une option</option>
              <option *ngFor="let item of enumSATT | keyvalue : valueOrder" value="{{ item.key }}">{{ item.value }}</option>
            </select>
          </div>
        </div>

        <!-- Icône et message d'erreur -->
        <ng-container *ngIf="enqueteForm.controls['satt'].get('value').invalid && enqueteForm.controls['satt'].get('value').touched">
          <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
        </ng-container>
      </div>
    </pxl-enquete-radio-toggle>
    <!-- Fin section satt -->

    <!-- Section financement -->
    <pxl-enquete-radio-toggle
      (toggled)="helperService.toggleValidation($event)"
      id="financement"
      title="Votre projet a-t-il fait l'objet d'un financement ?"
      [radioToggleGroup]="enqueteForm.get('financement')"
    >
      <div
        [class.ds-input-group--error]="
          enqueteForm.controls['financement'].get('value').invalid && enqueteForm.controls['financement'].get('value').touched
        "
      >
        <div class="form-container" [formGroup]="enqueteForm.controls['financement']">
          <div class="ds-input-group__select ds-input-group__select--with-icon">
            <label for="financement-value" class="ds-input-group__label">Veuillez indiquer le type</label>
            <span class="ds-input-group__label-asterisk"> *</span>
            <select id="financement-value" name="financement-value" formControlName="value">
              <option [ngValue]="null" selected disabled hidden>Sélectionnez une option</option>
              <option *ngFor="let item of enumFinancement | keyvalue : valueOrder" value="{{ item.key }}">{{ item.value }}</option>
            </select>
          </div>
        </div>

        <!-- Icône et message d'erreur -->
        <ng-container
          *ngIf="enqueteForm.controls['financement'].get('value').invalid && enqueteForm.controls['financement'].get('value').touched"
        >
          <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
        </ng-container>
      </div>
    </pxl-enquete-radio-toggle>
    <!-- Fin section financement -->

    <!-- Section developpement durable -->
    <pxl-enquete-radio-toggle
      (toggled)="helperService.toggleValidation($event)"
      id="developpementDurable"
      [title]="'Votre projet présente-t-il une dimension &quot;développement durable&quot; ?'"
      [radioToggleGroup]="enqueteForm.get('developpementDurable')"
    >
      <div
        [class.ds-input-group--error]="
          enqueteForm.controls['developpementDurable'].invalid && enqueteForm.controls['developpementDurable'].touched
        "
      >
        <div class="form-container" [formGroup]="enqueteForm.controls['developpementDurable']">
          <span class="section_title">Energies renouvelables</span>
          <div class="div_checkboxs_container">
            <label *ngFor="let item of enumEnergiesRenouvelables | keyvalue : valueOrder">
              <input
                class="btnlike"
                type="checkbox"
                [value]="item.key"
                (change)="onCheckboxChange($event, energiesRenouvelablesControl)"
                [checked]="helperService.isCheckboxChecked(item.key, energiesRenouvelablesControl)"
                [disabled]="!isProjectUpdatableByUser"
              />
              <span class="unselectable">{{ item.value }}</span>
            </label>
          </div>

          <span class="section_title">Gestion de la pollution</span>
          <div class="div_checkboxs_container">
            <label *ngFor="let item of enumGestionPollution | keyvalue : valueOrder">
              <input
                class="btnlike"
                type="checkbox"
                [value]="item.key"
                (change)="onCheckboxChange($event, gestionPollutionControl)"
                [checked]="helperService.isCheckboxChecked(item.key, gestionPollutionControl)"
                [disabled]="!isProjectUpdatableByUser"
              />
              <span class="unselectable">{{ item.value }}</span>
            </label>
          </div>

          <span class="section_title">Optimisation des consommations de ressources naturelles</span>
          <div class="div_checkboxs_container">
            <label *ngFor="let item of enumRessourcesNaturelles | keyvalue : valueOrder">
              <input
                class="btnlike"
                type="checkbox"
                [value]="item.key"
                (change)="onCheckboxChange($event, optimisationConsommationsControl)"
                [checked]="helperService.isCheckboxChecked(item.key, optimisationConsommationsControl)"
                [disabled]="!isProjectUpdatableByUser"
              />
              <span class="unselectable">{{ item.value }}</span>
            </label>
          </div>
        </div>

        <!-- Icône et message d'erreur -->
        <ng-container *ngIf="enqueteForm.controls['developpementDurable'].invalid && enqueteForm.controls['developpementDurable'].touched">
          <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
        </ng-container>
      </div>
    </pxl-enquete-radio-toggle>
    <!-- Fin developpement durable -->

    <div class="form-actions">
      <button
        id="creation_projet_btn_annuler"
        type="button"
        class="ds-btn ds-btn--secondary"
        (click)="onGoToProjetInfo()"
        *ngIf="isProjectUpdatableByUser"
      >
        Annuler
      </button>
      <button
        id="creation_projet_btn_ajouter"
        type="submit"
        class="ds-btn ds-btn--primary"
        *ngIf="isProjectUpdatableByUser"
        [disabled]="false"
      >
        <span>Enregistrer</span>
      </button>
    </div>
  </form>
</main>
