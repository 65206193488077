<div
  id="add-document"
  class="card card--alert"
  *ngIf="isProjectUpdatableByUser && !disabled"
  [class.card--document]="cardDocument"
  [class.card--document--override]="cardDocumentOveride"
  [class.is-valid]="isValid"
  [class.is-invalid]="isInvalid"
  [class.card--inactive]="cardInactive"
  [class.disabled]="carddisabled"
  role="button"
  aria-label="Ajouter un fichier"
  (click)="onUploadDocument()"
>
  <h4 id="add-document-label" class="card__title">Ajouter un fichier</h4>

  <lib-pxl-icon class="card__arrow" icon="icon-arrow-arrow-forward-ios" aria-hidden="true"></lib-pxl-icon>
</div>
