import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EnqueteRP, EnumTypeNiveauFormationTech, EnumTypeSecteurPublicTech, EnumTypeSituationPro } from '@shared-ui';

@Injectable({
  providedIn: 'root',
})
export class ProjetConsortiumMembreEquipeHelperService {
  constructor(public formBuilder: UntypedFormBuilder) {}

  /**
   * Enable ou disable champ
   * @param first  select modifié
   * @param second champ supplementaire
   */
  toggleForm(first: AbstractControl, second: AbstractControl, third?: AbstractControl, fourth?: AbstractControl): void {
    if (
      first.value === EnumTypeSituationPro.EMPLOYE_DU_SECTEUR_PUBLIC ||
      first.value === EnumTypeSecteurPublicTech[EnumTypeSecteurPublicTech.CHERCHEUR] ||
      first.value === EnumTypeSecteurPublicTech[EnumTypeSecteurPublicTech.ENSEIGNANT_CHERCHEUR] ||
      first.value === EnumTypeSituationPro.ETUDIANT_DOCTORANT ||
      first.value === true ||
      first.value === EnumTypeSituationPro.AUTRE ||
      first.value === EnumTypeNiveauFormationTech[EnumTypeNiveauFormationTech.AUTRE]
    ) {
      second.enable();
      if (third) third.enable();
      if (fourth) fourth.enable();
    } else {
      second.reset();
      second.disable();
      if (third) {
        third.reset();
        third.disable();
      }
      if (fourth) {
        fourth.reset();
        fourth.disable();
      }
    }
  }

  /**
   * Enable ou disable le formulaire selon le choix du toggle
   * @param formGroup Form group où le champ "oui/non" a été modifié
   */
  toggleValidation(formGroup: FormGroup): void {
    for (const controlName in formGroup.controls) {
      // On ne change pas la valeur du validator de radioToggle
      if (controlName === 'radioToggle') {
        continue;
      }

      if (formGroup.controls['radioToggle'].value) {
        formGroup.controls[controlName].enable();
      } else {
        formGroup.controls[controlName].disable();
        formGroup.controls[controlName].setValue(undefined);
      }
    }
  }

  addEnqueteResponsableProjetForms(contactForm: UntypedFormGroup) {
    contactForm.addControl('situationProfessionnelle', new FormControl(null, Validators.required));
    contactForm.addControl('detailEmploiActuel', new FormControl(null));
    contactForm.addControl('niveauformation', new FormControl(null, Validators.required));
    contactForm.addControl('anneeObtentionDiplome', new FormControl(null, Validators.required));
    contactForm.addControl('autreDiplome', new FormControl(null));
    contactForm.addControl(
      'dejaCandidat',
      this.formBuilder.group({
        radioToggle: [null, Validators.required],
        value: [{ value: null, disabled: !contactForm?.controls['dejaCandidat'] }, Validators.required],
      })
    );
    contactForm.addControl(
      'laureatPEPITETEE',
      this.formBuilder.group({
        radioToggle: [null, Validators.required],
        value: [{ value: null, disabled: !contactForm?.controls['laureatPEPITETEE'] }, Validators.required],
      })
    );
    contactForm.addControl('laureatEmergenceOuBeneficieBourse', new FormControl(null, Validators.required));
    contactForm.addControl(
      'laureatEmergenceAutreProjet',
      this.formBuilder.group({
        radioToggle: [null, Validators.required],
        value: [{ value: null, disabled: !contactForm?.controls['laureatEmergenceAutreProjet'] }, Validators.required],
      })
    );
    contactForm.addControl('laureatEmergenceAutreProjet', new FormControl(null, Validators.required));
    contactForm.addControl('laureatCreationDev', new FormControl(null, Validators.required));
    contactForm.addControl('acceptTransmissionConcoursNETVA', new FormControl(null, Validators.required));
  }

  populateEnqueteResponsableProjetForm(parcoursRp, contactForm: UntypedFormGroup) {
    if (parcoursRp != null) {
      contactForm.get('situationProfessionnelle').setValue(parcoursRp.situationProfessionnelle);
      contactForm.get('emploiSecteurPublic')?.setValue(parcoursRp.emploiSecteurPublic);
      contactForm.get('organismeRecherche')?.setValue(parcoursRp.organismeRecherche);
      contactForm.get('financementsPublic')?.setValue(parcoursRp.financementsPublic);
      contactForm.get('typeFinancementsPublic')?.setValue(parcoursRp.typeFinancementsPublic);
      contactForm.get('autreSituationProfessionnelle')?.setValue(parcoursRp.autreSituationProfessionnelle);
      contactForm.get('detailEmploiActuel').setValue(parcoursRp.detailEmploiActuel);
      contactForm.get('niveauformation').setValue(parcoursRp.niveauformation);
      contactForm.get('anneeObtentionDiplome').setValue(parcoursRp.anneeObtentionDiplome);
      contactForm.get('detailDiplome')?.setValue(parcoursRp.detailDiplome);
      contactForm.get('autreDiplome').setValue(parcoursRp.autreDiplome);
      contactForm.get('dejaCandidat').setValue({
        radioToggle: parcoursRp.dejaCandidat,
        value: parcoursRp.anneeDepotCandidature,
      });
      contactForm.get('laureatPEPITETEE').setValue({
        radioToggle: parcoursRp.laureatPEPITETEE,
        value: parcoursRp.anneeLaureatPEPITETEE,
      });
      contactForm.get('laureatEmergenceOuBeneficieBourse').setValue(parcoursRp.laureatEmergenceOuBeneficieBourse);
      contactForm.get('anneeLaureatEmergenceOuBeneficieBourse')?.setValue(parcoursRp.anneeLaureatEmergenceOuBeneficieBourse);
      contactForm.get('entrepriseCree')?.setValue(parcoursRp.entrepriseCree);
      contactForm.get('raisonSocialeEntrepriseCree')?.setValue(parcoursRp.raisonSocialeEntrepriseCree);
      contactForm.get('dateCreationEntrepriseCree')?.setValue(parcoursRp.dateCreationEntrepriseCree);
      contactForm.get('sirenEntrepriseCree')?.setValue(parcoursRp.sirenEntrepriseCree);
      contactForm.get('laureatEmergenceAutreProjet').setValue({
        radioToggle: parcoursRp.laureatEmergenceAutreProjet,
        value: parcoursRp.anneeLaureatEmergenceAutreProjet,
      });
      contactForm.get('laureatCreationDev').setValue(parcoursRp.laureatCreationDev);
      contactForm.get('anneeLaureatCreationDev')?.setValue(parcoursRp.anneeLaureatCreationDev);
      contactForm.get('entrepriseCreeAutreProjet')?.setValue(parcoursRp.entrepriseCreeAutreProjet);
      contactForm.get('raisonSocialeEntrepriseCreeAutreProjet')?.setValue(parcoursRp.raisonSocialeEntrepriseCreeAutreProjet);
      contactForm.get('dateCreationEntrepriseCreeAutreProjet')?.setValue(parcoursRp.dateCreationEntrepriseCreeAutreProjet);
      contactForm.get('sirenEntrepriseCreeAutreProjet')?.setValue(parcoursRp.sirenEntrepriseCreeAutreProjet);
      contactForm.get('acceptTransmissionConcoursNETVA')?.setValue(parcoursRp.acceptTransmissionConcoursNETVA);
    }
  }

  updateEnqueteResponsableProjetForm(newMembreEquipe, contactForm: UntypedFormGroup) {
    newMembreEquipe.parcoursRP = new EnqueteRP();
    newMembreEquipe.parcoursRP.situationProfessionnelle = contactForm.controls['situationProfessionnelle'].value;
    newMembreEquipe.parcoursRP.emploiSecteurPublic = contactForm.controls['emploiSecteurPublic']?.value || null;
    newMembreEquipe.parcoursRP.organismeRecherche = contactForm.controls['organismeRecherche']?.value || null;
    newMembreEquipe.parcoursRP.financementsPublic = contactForm.controls['financementsPublic']?.value || null;
    newMembreEquipe.parcoursRP.typeFinancementsPublic = contactForm.controls['typeFinancementsPublic']?.value || null;
    newMembreEquipe.parcoursRP.autreSituationProfessionnelle = contactForm.controls['autreSituationProfessionnelle']?.value || null;
    newMembreEquipe.parcoursRP.detailEmploiActuel = contactForm.controls['detailEmploiActuel'].value;
    newMembreEquipe.parcoursRP.niveauformation = contactForm.controls['niveauformation'].value;
    newMembreEquipe.parcoursRP.anneeObtentionDiplome = contactForm.controls['anneeObtentionDiplome']?.value;
    newMembreEquipe.parcoursRP.detailDiplome = contactForm.controls['detailDiplome']?.value;
    newMembreEquipe.parcoursRP.autreDiplome = contactForm.controls['autreDiplome'].value;
    newMembreEquipe.parcoursRP.dejaCandidat = contactForm.controls['dejaCandidat'].value.radioToggle;
    newMembreEquipe.parcoursRP.anneeDepotCandidature = contactForm.controls['dejaCandidat']?.get('value').value;
    newMembreEquipe.parcoursRP.laureatPEPITETEE = contactForm.controls['laureatPEPITETEE'].value['radioToggle'];
    newMembreEquipe.parcoursRP.anneeLaureatPEPITETEE = contactForm.controls['laureatPEPITETEE']?.get('value').value;
    newMembreEquipe.parcoursRP.laureatEmergenceOuBeneficieBourse = contactForm.controls['laureatEmergenceOuBeneficieBourse'].value;
    newMembreEquipe.parcoursRP.anneeLaureatEmergenceOuBeneficieBourse =
      contactForm.controls['anneeLaureatEmergenceOuBeneficieBourse']?.value || null;
    newMembreEquipe.parcoursRP.entrepriseCree = contactForm.controls['entrepriseCree']?.value || null;
    newMembreEquipe.parcoursRP.raisonSocialeEntrepriseCree = contactForm.controls['raisonSocialeEntrepriseCree']?.value || null;
    newMembreEquipe.parcoursRP.dateCreationEntrepriseCree = contactForm.controls['dateCreationEntrepriseCree']?.value || null;
    newMembreEquipe.parcoursRP.sirenEntrepriseCree = contactForm.controls['sirenEntrepriseCree']?.value || null;
    newMembreEquipe.parcoursRP.laureatEmergenceAutreProjet = contactForm.controls['laureatEmergenceAutreProjet'].value['radioToggle'];
    newMembreEquipe.parcoursRP.anneeLaureatEmergenceAutreProjet = contactForm.controls['laureatEmergenceAutreProjet']?.get('value').value;
    newMembreEquipe.parcoursRP.laureatCreationDev = contactForm.controls['laureatCreationDev'].value;
    newMembreEquipe.parcoursRP.anneeLaureatCreationDev = contactForm.controls['anneeLaureatCreationDev']?.value;
    newMembreEquipe.parcoursRP.entrepriseCreeAutreProjet = contactForm.controls['entrepriseCreeAutreProjet']?.value || null;
    newMembreEquipe.parcoursRP.raisonSocialeEntrepriseCreeAutreProjet =
      contactForm.controls['raisonSocialeEntrepriseCreeAutreProjet']?.value || null;
    newMembreEquipe.parcoursRP.dateCreationEntrepriseCreeAutreProjet =
      contactForm.controls['dateCreationEntrepriseCreeAutreProjet']?.value || null;
    newMembreEquipe.parcoursRP.sirenEntrepriseCreeAutreProjet = contactForm.controls['sirenEntrepriseCreeAutreProjet']?.value || null;
    newMembreEquipe.parcoursRP.acceptTransmissionConcoursNETVA = contactForm.controls['acceptTransmissionConcoursNETVA'].value;
  }

  deleteEnqueteResponsableProjetForms(contactForm: UntypedFormGroup) {
    contactForm.controls['situationProfessionnelle'].disable();
    contactForm.controls['emploiSecteurPublic']?.disable();
    contactForm.controls['organismeRecherche']?.disable();
    contactForm.controls['financementsPublic']?.disable();
    contactForm.controls['typeFinancementsPublic']?.disable();
    contactForm.controls['autreSituationProfessionnelle']?.disable();
    contactForm.controls['detailEmploiActuel'].disable();
    contactForm.controls['niveauformation'].disable();
    contactForm.controls['anneeObtentionDiplome'].disable();
    contactForm.controls['detailDiplome']?.disable();
    contactForm.controls['autreDiplome'].disable();
    contactForm.controls['dejaCandidat'].disable();
    contactForm.controls['laureatPEPITETEE'].disable();
    contactForm.controls['anneeLaureatEmergenceAutreProjet']?.disable();
    contactForm.controls['laureatEmergenceOuBeneficieBourse'].disable();
    contactForm.controls['anneeLaureatEmergenceOuBeneficieBourse']?.disable();
    contactForm.controls['entrepriseCree']?.disable();
    contactForm.controls['raisonSocialeEntrepriseCree']?.disable();
    contactForm.controls['dateCreationEntrepriseCree']?.disable();
    contactForm.controls['sirenEntrepriseCree']?.disable();
    contactForm.controls['laureatEmergenceAutreProjet'].disable();
    contactForm.controls['laureatCreationDev'].disable();
    contactForm.controls['anneeLaureatCreationDev']?.disable();
    contactForm.controls['entrepriseCreeAutreProjet']?.disable();
    contactForm.controls['raisonSocialeEntrepriseCreeAutreProjet']?.disable();
    contactForm.controls['dateCreationEntrepriseCreeAutreProjet']?.disable();
    contactForm.controls['sirenEntrepriseCreeAutreProjet']?.disable();
    contactForm.controls['acceptTransmissionConcoursNETVA'].disable();
  }

  enableAllFormFields(contactForm: UntypedFormGroup) {
    contactForm.controls['situationProfessionnelle'].enable();
    contactForm.controls['detailEmploiActuel'].enable();
    contactForm.controls['niveauformation'].enable();
    contactForm.controls['anneeObtentionDiplome'].enable();
    contactForm.controls['autreDiplome'].enable();
    contactForm.controls['dejaCandidat'].enable();
    contactForm.controls['laureatPEPITETEE'].enable();
    contactForm.controls['laureatEmergenceOuBeneficieBourse'].enable();
    contactForm.controls['laureatEmergenceAutreProjet'].enable();
    contactForm.controls['laureatCreationDev'].enable();
    contactForm.controls['acceptTransmissionConcoursNETVA'].enable();
    this.toggleValidation(contactForm.controls['laureatPEPITETEE'] as UntypedFormGroup);
    this.toggleValidation(contactForm.controls['laureatEmergenceAutreProjet'] as UntypedFormGroup);
    this.toggleValidation(contactForm.controls['dejaCandidat'] as UntypedFormGroup);
  }
}
