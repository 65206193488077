<!-- Carte upload 'Document' -->
<p class="info-size-message">
  <span class="fw-bolder">Dépenses du projet (sous le format CSV)<lib-asterisk></lib-asterisk></span><br />Poids maximum par fichier 20Mo
</p>
<div class="card card--alert card--task card--clickable" *ngIf="!documentBdd?.id" id="add-document" aria-label="Dépenses du projet">
  <p class="card__title d-flex">
    <pxl-icon class="icon me-2" icon="icon-file-folder-insert-drive-file" aria-hidden="true"></pxl-icon>
    Dépenses du projet
  </p>

  <div></div>

  <button type="button" class="ds-btn ds-btn--secondary mb-0" (click)="files.click()">
    <pxl-icon class="icon" icon="icon-action-add" aria-hidden="true"></pxl-icon>
    Déposer les dépenses du projet
  </button>
  <div class="input-file">
    <input type="file" id="files" (change)="onSelectDoc($event.target.files)" accept=".csv" #files />
  </div>
</div>
<!-- FIN de Carte upload 'Document' -->

<div
  class="card card--list-file"
  *ngIf="documentBdd?.id"
  [ngClass]="{ 'card--warning': isScanedDocument(documentBdd) && documentBdd?.scan !== SAFE }"
>
  <p class="card__title">
    <pxl-icon class="icon" icon="icon-file-folder-insert-drive-file" aria-hidden="true"></pxl-icon>
    Dépenses du projet
  </p>

  <div class="card__text">
    <span class="card__doc--name card--document--name--overwrite">
      {{ documentBdd?.nom }}
    </span>

    <br />
    Chargé le {{ documentBdd?.dateCreation | date : 'dd/MM/yyyy' }} {{ getNomCreateurDocument(documentBdd) }}
  </div>
  <div class="card--actions">
    <div class="card__action-button-wrapper">
      <button
        class="card__action-button"
        [ngClass]="{
          'card__action-button--error': isScanedDocument(documentBdd) && documentBdd?.scan !== SAFE
        }"
        (click)="downloadDocument(documentBdd)"
        [disabled]="documentBdd?.scan !== SAFE || !isScanedDocument(documentBdd)"
      >
        <pxl-icon class="icon" style="height: 23px" icon="icon-action-save-alt" aria-hidden="true"></pxl-icon>
      </button>
      <div class="card__action-button-tooltip" *ngIf="!isScanedDocument(documentBdd)">
        <p>Le test antimalware est en cours.</p>
      </div>
      <div class="card__action-button-tooltip" *ngIf="isScanedDocument(documentBdd) && documentBdd?.scan !== SAFE">
        <p>Ce fichier a été identifié comme étant potentiellement dangereux et ne peut donc pas être téléchargé.</p>
      </div>
    </div>
    <div class="card__action-button-wrapper action-button-delete_overwrite">
      <button class="card__action-button" (click)="onDeleteDocument(documentBdd)">
        <pxl-icon class="icon" icon="icon-action-delete" aria-hidden="true"></pxl-icon>
      </button>
    </div>
  </div>
</div>
