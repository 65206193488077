import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { ProjetService } from '@services-candidat/projet.service';
import { StructureService } from '@services-candidat/structure.service';
import { UserService } from '@services-candidat/user.service';
import { ConfirmModalComponent } from '@shared-candidat/components/modals/confirm-modal/confirm-modal.component';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  Aap,
  DemandeRectification,
  EnumMotifNonRattachement,
  EnumRoleContact,
  EnumRoleStructure,
  EnumTypePartenaire,
  EnumTypeStructure,
  InformationSiege,
  Projet,
  ShowToastrService,
  siretValidator,
  Structure,
  SubscriptionDestroyerComponent,
  Utilisateur,
} from '@shared-ui';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { EMPTY, Observable, switchMap } from 'rxjs';

@Component({
  selector: 'app-projet-consortium-creation',
  templateUrl: './projet-consortium-creation.component.html',
  styleUrls: ['./projet-consortium-creation.component.scss'],
})
export class ProjetConsortiumCreationComponent extends SubscriptionDestroyerComponent implements OnInit {
  structureForm: UntypedFormGroup;
  structureId: string;
  projetId: string;
  aap: Aap = new Aap();
  projet: Projet = new Projet();
  currentStructure: Structure = new Structure();
  user: Utilisateur;
  userPermissions: NgxPermissionsObject;
  openedStructures: Structure[] = [];
  structures: Structure[] = [];
  isRoleSelectable = true;
  structureEnCreation = false;
  structureIdentifiedByRET = false;
  mandataireRoleSelectionInfo = `Vous ne pouvez pas changer votre rôle comme Mandataire. Veuillez contacter le support pour faire cette action`;
  rectificationCanceled = false;
  rectificationRequested = false;
  isStructureUpdatableByUser: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private projetService: ProjetService,
    private structureService: StructureService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    public sharedFunction: SharedFunction,
    private aapService: AapService,
    private permissionsService: NgxPermissionsService,
    private userService: UserService
  ) {
    super();
    this.createForms();
  }

  ngOnInit(): void {
    this.projetId = this.route.snapshot.parent.params.id;
    this.structureId = this.route.snapshot.params.structureId;
    this.loadCurrentUser();
    this.loadAap();
    this.loadProjet();
    this.removeSiegeControlForMandataire();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.structureForm.dirty && !this.rectificationRequested;
  }

  createForms(): void {
    this.structureForm = this.formBuilder.group({
      role: [null, Validators.required],
      typeStructure: [null, Validators.required],
      siret: [null, [Validators.required, siretValidator()]],
      raisonSiret: [null, Validators.required],
      raisonSocial: [null, Validators.required],
      activite: [null, Validators.required],
      organisationPId: [null],
      numeroCP: [null],
      effectifSiege: [null, Validators.required],
      effectifGroupe: [null, Validators.required],
      adresse: this.formBuilder.group({
        codePays: [null, Validators.required],
        pays: [null, Validators.required],
        cp: [null, Validators.required],
        ville: [null, Validators.required],
        mentionDistribution: [null],
        hameau: [null],
        numero: [null, Validators.required],
        complement: [null],
        typeVoie: [null, Validators.required],
        voie: [null, Validators.required],
        complementInfo: [null],
        divisionAdministrative: [null],
        demandeRectification: this.formBuilder.group({
          commentaire: [null],
          demandeTraitee: [null],
        }),
      }),
    });
  }

  loadCurrentUser(): void {
    this.userService
      .getUserObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.userPermissions = this.permissionsService.getPermissions();
        }
      });
  }

  loadAap(): void {
    this.aapService
      .loadAapSubject()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: responseAap => {
          if (responseAap) {
            this.aap = responseAap.body;
            this.isStructureUpdatableByUser = this.sharedFunction.isStructureUpdatableByUser(
              this.aap,
              this.projet,
              this.currentStructure,
              this.user,
              this.userPermissions
            );
            this.mandataireRoleSelectionInfo = `Vous ne pouvez pas changer votre rôle comme Mandataire. Veuillez contacter le support ${this.aap.bal} pour faire cette action`;
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  loadProjet(): void {
    this.projetService
      .getProjetObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe(responseProjet => {
        if (responseProjet) {
          this.projet = responseProjet;
          this.loadProjetStructures();
        }
      });
  }

  loadProjetStructures(): void {
    this.structureService
      .getStructuresListObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe(response => {
        if (response) {
          this.structures = response;
          this.openedStructures = response.filter((structure: Structure) => !structure.closed);
          this.currentStructure = this.openedStructures.find(structure => structure.id === this.structureId);
          this.isStructureUpdatableByUser = this.sharedFunction.isStructureUpdatableByUser(
            this.aap,
            this.projet,
            this.currentStructure,
            this.user,
            this.userPermissions
          );

          if (this.projet.partenaireType === EnumTypePartenaire.INDIV) {
            this.handleProjetIndiv();
          }

          // TODO: find a better way to set motif non rattachement state (TIR)
          if (this.currentStructure.raisonSiret) {
            this.structureForm.get('raisonSiret')?.enable();
          } else {
            this.structureForm.get('raisonSiret')?.reset();
            this.structureForm.get('raisonSiret')?.disable();
          }
          this.structureEnCreation = this.currentStructure.raisonSiret === EnumMotifNonRattachement.EN_COURS_DE_CREATION;

          if (this.structureEnCreation) {
            this.resetForm();
          }
          this.structureForm.patchValue({
            ...this.currentStructure,
            siret: this.currentStructure?.siret?.replace(/\s/g, ''), // TODO: make sure that siret is never formatted in model and use pipe instead
            organisationPId: this.currentStructure?.informationsReferentiel?.organisationPId,
            numeroCP: this.currentStructure?.informationsReferentiel?.numeroCP,
          });
          // organisationPid and numeroCP are only provided by RET. So if they are present, it means that the structure is from RET
          this.structureIdentifiedByRET = Boolean(
            this.currentStructure?.informationsReferentiel?.organisationPId || this.currentStructure?.informationsReferentiel?.numeroCP
          );
        }
      });
  }

  handleProjetIndiv(): void {
    this.isRoleSelectable = false;
  }

  private removeSiegeControlForMandataire(): void {
    if (this.currentStructure.role === EnumRoleStructure.MANDATAIRE) {
      this.structureForm.removeControl('effectifSiege');
      this.structureForm.removeControl('effectifGroupe');
    }
  }

  submitForm(): void {
    if (this.structureForm.valid) {
      const formValue = this.structureForm.getRawValue();
      const chefDeFileExistant = this.openedStructures.find(e => e.role === EnumRoleStructure.CHEF_DE_FILE);
      const mandataireExistant = this.openedStructures.find(e => e.role === EnumRoleStructure.MANDATAIRE);

      if (formValue.role === EnumRoleStructure.CHEF_DE_FILE && chefDeFileExistant && this.structureId !== chefDeFileExistant.id) {
        this.confirmChefDeFileRoleChange(chefDeFileExistant).subscribe(() => {
          this.updateStructure(formValue);
        });
      } else if (formValue.role === EnumRoleStructure.MANDATAIRE && mandataireExistant && this.structureId !== mandataireExistant.id) {
        this.confirmMandataireRoleChange(mandataireExistant).subscribe(() => {
          this.currentStructure.contacts.forEach(contact => {
            contact.dateNaissance = null;
            contact.villeNaissance = null;
            contact.paysNaissance = null;
            contact.codePaysNaissance = null;
            contact.roles = [EnumRoleContact[EnumRoleContact.AUTRE.toString()]];
          });
          this.updateStructure(formValue);
        });
      } else if (formValue.role === EnumRoleStructure.MANDATAIRE && !mandataireExistant) {
        this.currentStructure.contacts.forEach(contact => {
          contact.dateNaissance = null;
          contact.villeNaissance = null;
          contact.paysNaissance = null;
          contact.codePaysNaissance = null;
          contact.roles = [EnumRoleContact[EnumRoleContact.AUTRE.toString()]];
        });
        this.updateStructure(formValue);
      } else {
        this.updateStructure(formValue);
      }
    } else {
      this.structureForm.markAllAsTouched();
    }
  }

  confirmChefDeFileRoleChange(oldChefDeFile: Structure): Observable<void> {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Changement de chef de file',
        description: `<p>Dans la structure du consortium, il ne peut y avoir qu'un seul chef de file.</p>
                    <p>Un chef de file a déjà été désigné. Si vous souhaitez le remplacer, cliquer sur le bouton "Oui". </p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Annuler',
      },
    });

    return dialogRef.afterClosed().pipe(
      switchMap(result => {
        if (result) {
          oldChefDeFile.role = EnumRoleStructure.CANDIDAT;
          return this.structureService.updateStructure(oldChefDeFile);
        } else {
          this.structureForm.controls.role.reset();
          return EMPTY;
        }
      }),
      this.takeUntilDestroyed()
    );
  }

  confirmMandataireRoleChange(oldMandataire: Structure): Observable<void> {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Changement de mandataire',
        description: `<p>Dans la structure du consortium, il ne peut y avoir qu'un seul mandataire.</p>
                    <p>Un mandataire a déjà été désigné. Si vous souhaitez le remplacer, cliquer sur le bouton "Oui". </p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Annuler',
      },
    });

    return dialogRef.afterClosed().pipe(
      switchMap(result => {
        if (result) {
          oldMandataire.role = EnumRoleStructure.CANDIDAT;
          return this.structureService.updateStructure(oldMandataire);
        } else {
          this.structureForm.controls.role.reset();
          return EMPTY;
        }
      }),
      this.takeUntilDestroyed()
    );
  }

  private refreshCurrentStructure(structureForm: any): void {
    Object.assign(this.currentStructure, structureForm);
    if (this.rectificationCanceled) {
      this.currentStructure.adresse.demandeRectification.demandeTraitee = true;
    }
    if (this.structureEnCreation) {
      this.currentStructure.adresse = null;
      this.currentStructure.typeStructure = EnumTypeStructure.PERSONNE_PHYSIQUE;
    }
    this.currentStructure.informationsReferentiel = {
      numeroCP: structureForm.numeroCP,
      organisationPId: structureForm.organisationPId,
    };
    if (this.currentStructure.lieuRD?.lieuRDisSiege) {
      this.currentStructure.lieuRD.lieuRDisSiege = false;
    }
  }

  updateStructure(structure: any): void {
    this.refreshCurrentStructure(structure);
    this.structureService
      .updateStructure(this.currentStructure)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: resp => {
          if (resp) {
            this.showToastrService.success('La structure ' + this.currentStructure.raisonSocial + ' a bien été modifiée');
            this.structureService.setUniqueStructureListObservable(resp.body, this.structures);
            this.structureForm.reset();

            // update current user (maybe the permissions have changed because of the structure role change)
            this.userService
              .getUtilisateur()
              .pipe(this.takeUntilDestroyed())
              .subscribe(() => {
                this.structureForm.markAsPristine();
                this.rectificationRequested = false;
                this.onGoToConsortium();
                //Update ProjetcObservable if structure is updated
                if (this.projet && this.projet.structures && this.projet.structures.length > 0) {
                  this.updateProjetObservable(resp.body as Structure);
                }
              });
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  //Update ProjetcObservable if structure is updated
  updateProjetObservable(updatedStructure: Structure) {
    const indexstructure = this.projet.structures.findIndex(structure => structure.id === updatedStructure.id);
    if (indexstructure !== -1) {
      this.projet.structures[indexstructure] = updatedStructure;
    }
  }

  onGoToConsortium(): void {
    this.router.navigate(['projet-creation', this.projetId, 'projet-consortium-info', this.structureId]);
  }

  onMotifNonRattachementSelected(motif: EnumMotifNonRattachement) {
    if (motif) {
      this.structureForm.get('raisonSiret')?.enable();
      this.structureForm.get('raisonSiret')?.setValue(motif);
    } else {
      this.structureForm.get('raisonSiret')?.reset();
      this.structureForm.get('raisonSiret')?.disable();
    }
    this.structureEnCreation = motif === EnumMotifNonRattachement.EN_COURS_DE_CREATION;
  }

  onStructureSelected(siege: InformationSiege) {
    if (siege) {
      this.structureForm.patchValue({ ...siege, adresse: siege.address, raisonSocial: siege.raisonSociale });
      this.structureIdentifiedByRET = true;
    } else {
      this.resetForm();
      this.structureIdentifiedByRET = false;
    }
  }

  onMaStructureNotListed() {
    this.resetForm();
    this.structureIdentifiedByRET = false;
  }

  resetForm() {
    this.structureForm.reset({
      role: this.structureForm.get('role')?.value,
      typeStructure: this.structureForm.get('typeStructure')?.value,
      raisonSiret: this.structureForm.get('raisonSiret')?.value,
      effectifSiege: this.structureForm.get('effectifSiege')?.value,
      effectifGroupe: this.structureForm.get('effectifGroupe')?.value,
      adresse: {
        demandeRectification: this.structureForm.get('adresse.demandeRectification')?.value,
        codePays: this.structureForm.get('adresse.codePays')?.value,
        pays: this.structureForm.get('adresse.pays')?.value,
      },
    });
    if (this.structureEnCreation) {
      this.structureForm.disable();
      this.structureForm.get('role')?.enable();
    }
  }

  submitDemandeRectification(demandeRectification: DemandeRectification) {
    demandeRectification.demandeTraitee = false;
    this.structureForm.get('adresse.demandeRectification.demandeTraitee')?.setValue(false);
    this.currentStructure.adresse.demandeRectification = demandeRectification;
    this.rectificationRequested = true;
  }

  protected readonly EnumRoleStructure = EnumRoleStructure;
}
