<div
  class="card--info"
  [ngClass]="{
    'card--blue card--readonly': color === 'blue',
    'card--red': color === 'red',
    'card--yellow': color === 'yellow',
    'disabled-card': disabled
  }"
>
  <lib-pxl-icon class="icon" [icon]="icon"></lib-pxl-icon>
  <div>
    {{ message }}
    <div *ngIf="content" class="content">{{ content }}</div>
  </div>
</div>
