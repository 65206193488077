import { EnumScope } from "../enums/enum.scope";
import { DemandeComplementInterface } from '../interfaces/demande-complement.interface';
import { Auteur } from './auteur.model';
import { Contact } from './contact.model';
import { DocumentDemandeModification } from './document-demande-modification.model';
import { Structure } from './structure.model';

export class DemandeComplement implements DemandeComplementInterface {
  id: string;
  statut: string;
  dateCreation?: Date;
  dateModification?: Date;
  projetId: string;
  auteur?: Auteur;
  scope: EnumScope;
  structures?: Structure[] = [];
  contactsANotifier?: Contact[] = [];
  documents: DocumentDemandeModification[] = [];
  archived: boolean;
  commentaire: string;
}
