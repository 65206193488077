import { AfterViewInit, Component, Input } from '@angular/core';

declare const dsLib: any;

@Component({
  selector: 'lib-ds-progress-bar',
  templateUrl: './ds-progress-bar.component.html',
  styleUrls: ['./ds-progress-bar.component.scss'],
})
export class DsProgressBarComponent implements AfterViewInit {
  @Input() progressBarId: string;
  @Input() progress: number;
  @Input() total: number;

  ngAfterViewInit(): void {
    new dsLib.ProgressBarChart(this.progressBarId, new dsLib.Dimension(10, 500), Math.round((this.progress / this.total) * 100), {
      color: 'secondary',
    });
  }
}
