import { Injectable } from '@angular/core';
import { DocumentProjet, DownloadUtils, ShowToastrService, UploadDocumentHttpService } from '@shared-ui';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentFileManagerService {
  constructor(
    public uploadDocumentService: UploadDocumentHttpService,
    private showToastrService: ShowToastrService,
    private downloadUtils: DownloadUtils
  ) {}

  downloadDocument(document: DocumentProjet, isSiteAdmin?: boolean): Observable<void> {
    if (document.stockerGed) {
      return this.downloadDocumentFromGed(document, isSiteAdmin);
    } else {
      return this.downloadFromS3(document);
    }
  }

  downloadFromS3(document: DocumentProjet): Observable<void> {
    return this.uploadDocumentService.getValueForDocDownload(document).pipe(
      switchMap(response => {
        if (!response.body || !response.body.url) {
          return throwError(() => new Error("Erreur lors de la récupération de l'url signée"));
        }
        window.open(response.body.url);
        return EMPTY;
      }),
      catchError((err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
        return throwError(() => err);
      })
    );
  }

  downloadDocumentFromGed(document: DocumentProjet, isSiteAdmin?: boolean): Observable<void> {
    return this.uploadDocumentService.getDocFromGED(document.id, isSiteAdmin).pipe(
      switchMap(response => {
        if (!response.body) {
          return throwError(() => new Error('Erreur lors de la récupération du document depuis la GED'));
        }
        this.downloadUtils.download(response.body, document.nom);
        return EMPTY;
      }),
      catchError((err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
        return throwError(() => err);
      })
    );
  }
}
