import { enumKeys } from "../../utils/enum-utils";

export enum EnumTypeStructure {
  PETITE_ENTREPRISE_NON_COTEE = 'PETITE_ENTREPRISE_NON_COTEE',
  PETITE_ENTREPRISE_COTEE = 'PETITE_ENTREPRISE_COTEE',
  MOYENNE_ENTREPRISE_NON_COTEE = 'MOYENNE_ENTREPRISE_NON_COTEE',
  MOYENNE_ENTREPRISE_COTEE = 'MOYENNE_ENTREPRISE_COTEE',
  GRANDE_ENTREPRISE_NON_COTEE = 'GRANDE_ENTREPRISE_NON_COTEE',
  GRANDE_ENTREPRISE_COTEE = 'GRANDE_ENTREPRISE_COTEE',
  GROUPEMENT_ET_COOPERATIVE = 'GROUPEMENT_ET_COOPERATIVE',
  ASSOCIATION_ET_FONDATION = 'ASSOCIATION_ET_FONDATION',
  ETABLISSEMENT_PUBLIC_LABO_ETABLISSEMENT_RECHERCHE = 'ETABLISSEMENT_PUBLIC_LABO_ETABLISSEMENT_RECHERCHE',
  PERSONNE_PHYSIQUE = 'PERSONNE_PHYSIQUE',
  COLLECTIVITE_LOCALE = 'COLLECTIVITE_LOCALE',
}

export namespace EnumTypeStructure {
  export function toString(typeStructure: EnumTypeStructure): string {
    switch (typeStructure) {
      case EnumTypeStructure.PETITE_ENTREPRISE_NON_COTEE:
        return 'Petite Entreprise non cotée';
      case EnumTypeStructure.PETITE_ENTREPRISE_COTEE:
        return 'Petite Entreprise cotée sur un marché réglementé';
      case EnumTypeStructure.MOYENNE_ENTREPRISE_NON_COTEE:
        return 'Moyenne Entreprise non cotée';
      case EnumTypeStructure.MOYENNE_ENTREPRISE_COTEE:
        return 'Moyenne Entreprise cotée sur un marché réglementé';
      case EnumTypeStructure.GRANDE_ENTREPRISE_NON_COTEE:
        return 'Grande Entreprise (dont ETI) non cotée';
      case EnumTypeStructure.GRANDE_ENTREPRISE_COTEE:
        return 'Grande Entreprise (dont ETI) cotée sur un marché réglementé';
      case EnumTypeStructure.GROUPEMENT_ET_COOPERATIVE:
        return 'Groupement et coopérative';
      case EnumTypeStructure.ASSOCIATION_ET_FONDATION:
        return 'Association ou fondation';
      case EnumTypeStructure.ETABLISSEMENT_PUBLIC_LABO_ETABLISSEMENT_RECHERCHE:
        return 'Etablissement public, laboratoire ou établissement de recherche';
      case EnumTypeStructure.COLLECTIVITE_LOCALE:
        return 'Collectivité locale';
      case EnumTypeStructure.PERSONNE_PHYSIQUE:
        return 'Personne physique';
    }
  }

  export function all(): EnumTypeStructure[] {
    return enumKeys(EnumTypeStructure) as unknown as EnumTypeStructure[];
  }

  export function forPersonneMoral(): EnumTypeStructure[] {
    return EnumTypeStructure.all().filter(type => type !== EnumTypeStructure.PERSONNE_PHYSIQUE);
  }
}
