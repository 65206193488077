import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FicheCom } from 'libs/shared-ui/src/lib/shared/models/fiche-com.model';
import { Observable } from 'rxjs';
import { environment } from '@environments-candidat/environment';
import { Projet } from '@shared-ui';

@Injectable()
export class FicheCommunicationService {
  constructor(private httpClient: HttpClient) {}

  createFicheCommunication(fiche: FicheCom, idProjet: string): Observable<HttpResponse<Projet>> {
    return this.httpClient.put<Projet>(environment.apiUrl + 'projets/' + idProjet +'/fiche-communication', JSON.stringify(fiche), {
      observe: 'response',
    });
  }
}
