import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ProjetInfoThematiqueComponent } from './projet-info-thematique.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ProjetInfoThematiqueComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    SharedModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
})
export class ProjetInfoThematiqueModule {}
