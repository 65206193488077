import { enumKeys } from '../../utils/enum-utils';

export enum EnumProjetStatut {
  EN_COURS = 'EN_COURS',
  ENVOYE = 'ENVOYE',
  EN_ANALYSE = 'EN_ANALYSE',
  REJETE = 'REJETE',
  VALIDE = 'VALIDE',
  SELECTIONNE = 'SELECTIONNE',
  COMPLEMENT_ENVOYE = 'COMPLEMENT_ENVOYE',
  COMPLEMENT_DEMANDE = 'COMPLEMENT_DEMANDE',
  ANALYSE_VALIDE = 'ANALYSE_VALIDE',
  ANALYSE_REJETE = 'ANALYSE_REJETE',
  EN_EVALUATION = 'EN_EVALUATION',
  EN_AUDITION = 'EN_AUDITION',
  EN_INSTRUCTION = 'EN_INSTRUCTION',
  EVALUATION_VALIDE = 'EVALUATION_VALIDE',
  EVALUATION_REJETE = 'EVALUATION_REJETE',
  AUDITION_VALIDE = 'AUDITION_VALIDE',
  AUDITION_REJETE = 'AUDITION_REJETE',
  TERMINE = 'TERMINE',
}

const undisplayableEnums = [
  EnumProjetStatut.REJETE,
  EnumProjetStatut.VALIDE,
  EnumProjetStatut.SELECTIONNE,
  EnumProjetStatut.COMPLEMENT_ENVOYE,
  EnumProjetStatut.COMPLEMENT_DEMANDE,
  EnumProjetStatut.EN_INSTRUCTION,
];
export namespace EnumProjetStatut {
  export function toString(status: EnumProjetStatut, feminine: boolean = false): string {
    switch (status) {
      case EnumProjetStatut.EN_COURS:
        return 'En cours';
      case EnumProjetStatut.ENVOYE:
        return feminine ? 'Envoyée' : 'Envoyé';
      case EnumProjetStatut.EN_ANALYSE:
        return 'En analyse';
      case EnumProjetStatut.REJETE:
        return feminine ? 'Rejetée' : 'Rejeté';
      case EnumProjetStatut.VALIDE:
        return feminine ? 'Validée' : 'Validé';
      case EnumProjetStatut.SELECTIONNE:
        return feminine ? 'Sélectionée' : 'Sélectioné';
      case EnumProjetStatut.COMPLEMENT_ENVOYE:
        return 'Complément(s) envoyé(s)';
      case EnumProjetStatut.COMPLEMENT_DEMANDE:
        return 'Complément(s) demandé(s)';
      case EnumProjetStatut.ANALYSE_VALIDE:
        return 'Analyse validée';
      case EnumProjetStatut.ANALYSE_REJETE:
        return 'Analyse rejetée';
      case EnumProjetStatut.EN_EVALUATION:
        return 'En évaluation';
      case EnumProjetStatut.EN_AUDITION:
        return 'En audition';
      case EnumProjetStatut.EN_INSTRUCTION:
        return 'En instruction';
      case EnumProjetStatut.EVALUATION_VALIDE:
        return 'Evaluation validée';
      case EnumProjetStatut.EVALUATION_REJETE:
        return 'Evaluation rejetée';
      case EnumProjetStatut.AUDITION_VALIDE:
        return 'Audition validée';
      case EnumProjetStatut.AUDITION_REJETE:
        return 'Audition rejetée';
      case EnumProjetStatut.TERMINE:
        return feminine ? 'Terminée' : 'Terminé';
      default:
        return '';
    }
  }

  export function all(): EnumProjetStatut[] {
    return enumKeys(EnumProjetStatut) as unknown as EnumProjetStatut[];
  }

  export function displayableStatus(): EnumProjetStatut[] {
    return all().filter(statut => !undisplayableEnums.includes(statut));
  }
}
