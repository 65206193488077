import { EnumScanDocument } from '../enums/enum.scanDocument';
import { EnumScope } from '../enums/enum.scope';
import { DocumentProjetInterface } from '../interfaces/documentProjet.interface';
import { CompletudeSecondNiveauDocument } from './completude-second-niveau-document.model';
import { Eligibilite } from './eligibilite.model';
import { Verrouillage } from './verrouillage.model';
import { EnumProjetEtape } from '../enums/enumProjetEtape';

export class DocumentProjet implements DocumentProjetInterface {
  id: string;
  nom: string;
  type: string;
  typeDoc: string;
  scope: EnumScope;
  scan: EnumScanDocument;
  raisonRefus: string;
  createur: string;
  projetId: string;
  poleId: string;
  structureId: string;
  dateCreation: Date;
  dateModification: Date;
  nomCreateur: string;
  etape: EnumProjetEtape;
  verrouillage: Verrouillage = new Verrouillage();
  eligibilite: Eligibilite = new Eligibilite();
  file: File;
  demandeComplementId?: string;
  informationBancaireId?: string;
  completudeSecondNiveauDocument: CompletudeSecondNiveauDocument = new CompletudeSecondNiveauDocument();
  codeGED: string;
  stockerGed: boolean;
}
