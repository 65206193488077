import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { ProjetInfoFicheComComponent } from './projet-info-fiche-com.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedUiModule, UploadDocumentHttpService } from '@shared-ui';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ProjetInfoFicheComComponent],
  providers: [UploadDocumentHttpService],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    SharedUiModule,
    SharedModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
})
export class ProjetInfoFicheComModule {}
