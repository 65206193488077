import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ProjetCreationComponent } from '@features-candidat/projet-creation/projet-creation.component';
import { ProjetApercuComponent } from '@features-candidat/projet-creation/component/projet-apercu/projet-apercu.component';
import { ProjetInfoComponent } from '@features-candidat/projet-creation/component/projet-info/projet-info.component';
import { ProjetInfoGeneralComponent } from '@features-candidat/projet-creation/component/projet-info-general/projet-info-general.component';
import { ProjetConsortiumComponent } from './component/projet-consortium/projet-consortium.component';
import { ProjetConsortiumCreationComponent } from './component/projet-consortium-creation/projet-consortium-creation.component';
import { ProjetConsortiumInfoComponent } from './component/projet-consortium-info/projet-consortium-info.component';
import { ProjetConsortiumContactComponent } from './component/projet-consortium-contact/projet-consortium-contact.component';
import { ProjetInfoBudgetComponent } from './component/projet-info-budget/projet-info-budget.component';
import { ProjetConsortiumBudgetComponent } from './component/projet-consortium-budget/projet-consortium-budget.component';
import { ProjetInfoPolesComponent } from './component/projet-info-poles/projet-info-poles.component';
import { ProjetConsortiumAdresseRDComponent } from './component/projet-consortium-adresse-rd/projet-consortium-adresse-rd.component';
import { ProjetInfoSecteursComponent } from './component/projet-info-secteurs/projet-info-secteurs.component';
import { ProjetInfoDomainesComponent } from './component/projet-info-domaines/projet-info-domaines.component';
import { ProjetInfoThematiqueComponent } from './component/projet-info-thematique/projet-info-thematique.component';
import { ProjetInfoEnqueteComponent } from './component/projet-info-enquete/projet-info-enquete.component';
import { ProjetConsortiumMembreEquipeComponent } from './component/projet-consortium-membre-equipe/projet-consortium-membre-equipe.component';
import { CanDeactivateGuard } from '../../guards/can-deactivate-guard';
import { ProjetInfoGrilleImpactsComponent } from './component/projet-info-grille-impact/projet-info-grille-impacts.component';
import { ProjetConsortiumGrilleImpactsComponent } from './component/projet-consortium-grille-impact/projet-consortium-grille-impacts.component';
import { ProjetInfoDepensesComponent } from './component/projet-info-depenses/projet-info-depenses.component';
import { ProjetInfoFicheComComponent } from './component/projet-info-fiche-com/projet-info-fiche-com.component';
import { ProjetConsortiumFicheDemandeAideComponent } from './component/projet-consortium-fiche-demande-aide/projet-consortium-fiche-demande-aide.component';
import { ProjetConsortiumInformationBancaireComponent } from '@features-candidat/projet-creation/component/projet-consortium-information-bancaire/projet-consortium-information-bancaire.component';
import { ProjetConsortiumSignataireComponent } from '@features-candidat/projet-creation/component/projet-consortium-signataire/projet-consortium-signataire.component';

export const ProjetCreationRoutes: Routes = [
  {
    path: '',
    component: ProjetCreationComponent,
    children: [
      {
        path: '',
        redirectTo: 'projet-apercu',
        pathMatch: 'full',
      },
      {
        path: 'projet-apercu',
        component: ProjetApercuComponent,
        data: { title: 'Apercu projet - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-info',
        component: ProjetInfoComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Infos projet - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-info-budget',
        component: ProjetInfoBudgetComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Budget projet - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-info-poles',
        component: ProjetInfoPolesComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Pôles de compétitivité - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-info-general',
        component: ProjetInfoGeneralComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Modifier infos projet - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-consortium',
        component: ProjetConsortiumComponent,
        data: { title: 'Page consortium - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-consortium-info/:structureId',
        component: ProjetConsortiumInfoComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Page structure - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-consortium-creation',
        component: ProjetConsortiumCreationComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Créer une structure - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-info-secteurs',
        component: ProjetInfoSecteursComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Pôles de compétitivité - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-info-domaines',
        component: ProjetInfoDomainesComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Domaines technologiques - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-info-thematique',
        component: ProjetInfoThematiqueComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Thématique - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-info-enquete',
        component: ProjetInfoEnqueteComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Enquête - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-info-grille-impacts',
        component: ProjetInfoGrilleImpactsComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: "Grille d'impacts projet - Picxel | Extranet des Projets Innovants Collaboratifs" },
      },
      {
        path: 'projet-info-depenses',
        component: ProjetInfoDepensesComponent,
        data: { title: 'Dépenses du projet - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-info-fiche-com',
        component: ProjetInfoFicheComComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Fiche de communication - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-consortium-creation/:structureId',
        component: ProjetConsortiumCreationComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Modifier la structure - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-consortium-lieu-rd/:structureId',
        component: ProjetConsortiumAdresseRDComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Modifier le lieu de Recherche - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-consortium-info/:structureId/projet-consortium-contact/:contactId',
        component: ProjetConsortiumContactComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Modifier le contact - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-consortium-info/:structureId/projet-consortium-contact',
        component: ProjetConsortiumContactComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Créer un contact - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-consortium-info/:structureId/projet-consortium-membre-equipe/:membreId',
        component: ProjetConsortiumMembreEquipeComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: "Modifier le membre d'équipe - Picxel | Extranet des Projets Innovants Collaboratifs" },
      },
      {
        path: 'projet-consortium-info/:structureId/projet-consortium-membre-equipe',
        component: ProjetConsortiumMembreEquipeComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: "Créer un membre d'équipe - Picxel | Extranet des Projets Innovants Collaboratifs" },
      },
      {
        path: 'projet-consortium-info/:structureId/projet-consortium-budget',
        component: ProjetConsortiumBudgetComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Ajouter Budget - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-consortium-info/:structureId/projet-consortium-information-bancaire',
        component: ProjetConsortiumInformationBancaireComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Ajouter RIB/IBAN - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-consortium-info/:structureId/projet-consortium-fiche-demande-aide',
        component: ProjetConsortiumFicheDemandeAideComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: "Ajouter une fiche de demande d'aide - Picxel | Extranet des Projets Innovants Collaboratifs" },
      },
      {
        path: 'projet-consortium-info/:structureId/projet-consortium-grille-impacts',
        component: ProjetConsortiumGrilleImpactsComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: "Grille d'impacts structure - Picxel | Extranet des Projets Innovants Collaboratifs" },
      },
      {
        path: 'projet-consortium-info/:structureId/projet-consortium-signataire',
        component: ProjetConsortiumSignataireComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Ajouter un signataire du contrat - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-consortium-info/:structureId/projet-consortium-signataire/:signataireId',
        component: ProjetConsortiumSignataireComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Modifier un signataire du contrat - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(ProjetCreationRoutes)],
  exports: [RouterModule],
})
export class ProjetCreationRoutingModule {}
