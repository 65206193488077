import { enumKeys } from "../../utils/enum-utils";

export enum EnumCategorieStructure {
  PE = 'PE',
  ME = 'ME',
  GE = 'GE',
  LABO = 'LABO',
}

export namespace EnumCategorieStructure {
  export function toString(value: EnumCategorieStructure): string {
    switch (value) {
      case EnumCategorieStructure.PE:
        return 'PE';
      case EnumCategorieStructure.ME:
        return 'ME';
      case EnumCategorieStructure.GE:
        return 'GE';
      case EnumCategorieStructure.LABO:
        return 'Labo';
    }
  }

  export function all(): EnumCategorieStructure[] {
    return enumKeys(EnumCategorieStructure) as unknown as EnumCategorieStructure[];
  }
}