import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments-candidat/environment';
import { Evaluateur, ShowToastrService } from '@shared-ui';
import { Observable, Subscription } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class EvaluateurService {
  evaluateursUrl = environment.apiUrl + 'evaluateurs';

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private showToastrService: ShowToastrService,
    private userService: UserService
  ) { }

  getEvaluateur(email: string): Observable<HttpResponse<Evaluateur>> {
    return this.httpClient.get<Evaluateur>(this.evaluateursUrl + '/' + email, {
      observe: 'response',
    });
  }

  /** Fonctions de redirection vers la page évaluateur */
  goToEvaluateurPage(evaluateur?: Evaluateur): Subscription {
    let sub: Subscription;

    if (!evaluateur) {
      sub = this.userService.getUserObservable().subscribe(user => {
        this.getEvaluateur(user.email).subscribe({
          next: () => {
            this.redirectToEvalPage();
          },
          error: (err: HttpErrorResponse) => {
            if (err.status === 404) {
              this.checkRadicalMail(user.email).subscribe({
                next: resp => {
                  if (resp.body?.length > 0) {
                    this.router.navigate(['/rejoindre-projet-partenaire']);
                  } else {
                    this.showEvaluateurError();
                  }
                },
                error: () => {
                  this.showEvaluateurError();
                },
              });
            } else if (err.status === 401) {
              this.redirectToEvalPage();
            } else {
              this.showToastrService.checkCodeError(err?.error);
            }
          },
        });
      });
    } else {
      this.redirectToEvalPage();
    }
    return sub;
  }

  redirectToEvalPage(): void {
    const idp = localStorage.getItem('idp') ? '?idp=' + localStorage.getItem('idp') : '';
    window.location.href = environment.espaceEval + idp;
  }

  checkRadicalMail(mail: string): Observable<HttpResponse<any>> {
    const radical = mail.split('@').pop();
    return this.httpClient.get<any>(environment.apiUrl + 'organismes?radicalmail=@' + radical, {
      observe: 'response',
    });
  }

  demandeInscription(formulaireDemande: any): Observable<HttpResponse<any>> {
    return this.httpClient.post<any>(environment.apiUrl + 'demandes-inscriptions', JSON.stringify(formulaireDemande), {
      observe: 'response',
    });
  }

  private showEvaluateurError(): void {
    this.showToastrService.error(
      'Si vous avez reçu une invitation dans votre boite mail, merci de cliquer sur le lien dans le mail pour finaliser votre inscription et pouvoir accéder aux projets',
      "Vous ne pouvez pas accéder à l'espace évaluateur.",
      {
        disableTimeOut: true,
      }
    );
  }
}
