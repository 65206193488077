import { MembreEquipeInterface } from '../interfaces/membreEquipe.interface';
import { Formation } from './formation.model';
import { Identity } from './identity.model';
import { InfoProjetStructure } from './infoProjetStructure.model';
import { EnqueteRP } from './enqueteRP.model';

export class MembreEquipe implements MembreEquipeInterface {
  index: string;
  identite: Identity = new Identity();
  infosProjet: InfoProjetStructure = new InfoProjetStructure();
  formation: Formation = new Formation();
  emailContact: string;
  idDocumentCV: string;
  parcoursRP: EnqueteRP;
  projetId: string;
}
