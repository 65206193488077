import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { DemandeDeDocumentComplementaireService } from '@services-candidat/demandeDeDocumentComplementaire.service';
import { FicheDemandeAideService } from '@services-candidat/fiche-demande-aide.service';
import { ProjetService } from '@services-candidat/projet.service';
import { StructureService } from '@services-candidat/structure.service';
import { UploadDocumentService } from '@services-candidat/upload-document.service';
import { UserService } from '@services-candidat/user.service';
import { ConfirmModalComponent } from '@shared-candidat/components/modals/confirm-modal/confirm-modal.component';
import { InvitationContactModalComponent } from '@shared-candidat/components/modals/invitation-contact-modal/invitation-contact-modal.component';
import { SelectDocumentModalComponent } from '@shared-candidat/components/modals/select-document-modal/select-document-modal.component';
import { URL_PUBLIC_DOC } from '@shared-candidat/utils/constants';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  Aap,
  Contact,
  DemandeComplements,
  DocumentAapModel,
  DocumentHelper,
  DocumentProjet,
  EnumDocumentComplementaireType,
  EnumFeatureFlipping,
  EnumMotifNonRattachement,
  EnumProjetEtape,
  EnumQualifRue,
  EnumRoleContact,
  EnumRoleStructure,
  EnumScanDocument,
  EnumScope,
  EnumStatutDemandeAide,
  EnumStatutSignataire,
  EnumTypeDocument,
  EnumTypePartenaire,
  EnumTypeStructure,
  EnumTypeVoie,
  FicheDemandeAidePresent,
  FILE_SIZE,
  GrilleImpactHelperService,
  GrilleImpactHttpService,
  InformationBancaire,
  InformationsBancairesHttpService,
  Invitation,
  MembreEquipe,
  Projet,
  ShowToastrService,
  Signataire,
  StatutGrilleImpacts,
  Structure,
  StructureValidationFunction,
  SubscriptionDestroyerComponent,
  UploadDocModalDocumentModel,
  Utilisateur,
} from '@shared-ui';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { concatMap, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-projet-consortium-info',
  templateUrl: './projet-consortium-info.component.html',
  styleUrls: ['./projet-consortium-info.component.scss'],
})
export class ProjetConsortiumInfoComponent extends SubscriptionDestroyerComponent implements OnInit, OnDestroy {
  readonly RESPONSABLE_PROJET = EnumRoleContact[EnumRoleContact.RESPONSABLE_PROJET.toString()];
  readonly REPRESENTANT_LEGAL = EnumRoleContact[EnumRoleContact.REPRESENTANT_LEGAL.toString()];
  readonly RESPONSABLE_ADMINISTRATIF = EnumRoleContact[EnumRoleContact.RESPONSABLE_ADMINISTRATIF.toString()];
  readonly AUTRE = EnumRoleContact[EnumRoleContact.AUTRE.toString()];
  EnumStatutDemandeAide = EnumStatutDemandeAide;

  readonly CONNAISSANCE_CLIENT_FILE_TYPES = [
    'application/pdf', // .pdf
    'image/gif', // .gif
    'image/png', // .png
    'image/jpeg', // .jpg, .jpeg
    'image/bmp', // .bmp
    'image/tiff', // .tif, .tiff
    'application/vnd.ms-outlook', // .msg
  ];
  readonly DEFAULT_FILE_TYPES = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
  ];

  readonly FILE_SIZE_LIMIT = FILE_SIZE;
  readonly FICHE_DMD_AIDE_FILE_EXTENSION = '.pdf';

  dossier: string;
  projet: Projet = new Projet();
  structure: Structure;
  structures: Structure[] = [];
  allStructures: Structure[] = [];
  utilisateur: Utilisateur;
  userPermissions: NgxPermissionsObject;
  responsableProjet: Contact;
  representantLegaux: Contact[] = [];
  autresContacts: Contact[] = [];
  autresContactsDeleted: Contact[] = [];
  contacts: Contact[] = [];
  membresEquipe: MembreEquipe[] = [];
  structureId: string;
  aap: Aap = new Aap();
  invitation: Invitation = new Invitation();
  documentStructure: DocumentProjet = new DocumentProjet();
  etapeProjet: EnumProjetEtape;
  listDocumentStructure: DocumentProjet[] = [];
  ficheDmdAideDocuments: DocumentProjet[] = [];
  signataires: Signataire[] = [];
  signatairesValide: Signataire[] = [];
  signatairesInvalide: Signataire[] = [];

  subscriptions: Subscription[] = [];
  listDocAapStructure: DocumentAapModel[] = [];
  listDocAapStructureActio: DocumentAapModel[] = [];
  demandesComplements: DemandeComplements[] = [];
  demandesComplementsEncours: DemandeComplements[] = [];
  documentsComplementaires: DocumentProjet[] = [];
  isDocumentsComplementairesInvalid = false;
  remainingDocumentsComplementaires: UploadDocModalDocumentModel[] = [];
  fileTooBig = false;
  fileNotSupported = false;
  fileActioTooBig = false;
  fileActioNotSupported = false;
  fileComplTooBig = false;
  fileComplNotSupported = false;
  connaissanceClientFileNotSupported = false;
  connaissanceClientFileActioNotSupported = false;
  connaissanceClientFileComplNotSupported = false;
  allowDownload = false;

  responsableProjetBoolean = false;
  responsableProjetEquipeBoolean = false;
  responsableAdministratifBoolean = false;

  structureType = EnumTypeStructure;
  public enumRaisonSiret = EnumMotifNonRattachement;

  isUploading = false;
  isKpiStructureNeeded = false;

  isStructureUpdatableByUser = false;
  isStatutCompleted = false;
  isGrilleImpactsAccessible = false;
  isFicheDemandeAccessible = false;
  isFicheDemandeAuthorized = false;
  demandeDeCorrectionExist = false;
  demandeDeCorrectionTreated = false;

  isFicheDemandeAidePresent = true;
  isSectionsRenseignees = false;
  isInfosGeneralesRenseignees = false;
  isStructureMandataire = false;
  ficheDemandeAideStatut: EnumStatutDemandeAide;
  ficheDemandeAideNeeded = true;

  selectedInformationBancaire?: InformationBancaire;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public projetService: ProjetService,
    private structureService: StructureService,
    private showToastrService: ShowToastrService,
    private aapService: AapService,
    public userService: UserService,
    public uploadDocumentService: UploadDocumentService,
    private permissionsService: NgxPermissionsService,
    public sharedFunction: SharedFunction,
    public structureValidationFunction: StructureValidationFunction,
    private grilleImpactHttpService: GrilleImpactHttpService,
    private demandeComplementService: DemandeDeDocumentComplementaireService,
    private ficheDemandeAideService: FicheDemandeAideService,
    public informationsBancairesHttpService: InformationsBancairesHttpService
  ) {
    super();
    this.structureId = this.route.snapshot.params.structureId;
  }

  ngOnInit(): void {
    this.getProjetIfExist();
    this.loadInformationsBancaires();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.isUploading;
  }

  /*
   * Cette méthode récupère le projet si l'id est renseigné
   * */
  getProjetIfExist(): void {
    this.subscriptions.push(
      this.projetService
        .getProjetObservable()
        .pipe(
          mergeMap(responseProjet => {
            if (responseProjet) {
              this.projet = responseProjet;
              this.etapeProjet = this.sharedFunction.getProjectEtapeName(this.projet);

              this.subscriptions.push(
                this.userService.getUserObservable().subscribe(response => {
                  if (response) {
                    this.utilisateur = response;
                    this.userPermissions = this.permissionsService.getPermissions();
                  }
                })
              );

              return this.aapService.loadAapSubject();
            }
          })
        )
        .subscribe({
          next: responseAap => {
            if (responseAap) {
              this.aap = responseAap.body;
              this.isKpiStructureNeeded = this.aap.kpis?.filter(kpi => kpi.scope === 'STRUCTURE').length > 0;
              this.isFicheDemandeAidePresent = this.checkPresenceFicheDemandeAide(this.aap.ficheDemandeAidePresent, this.etapeProjet);
              this.dossier = this.aap.lienDocumentation;
              if (this.structureId != null) {
                this.getStructDocuments();
                this.getProjetStructures();
              }
              this.updateGrilleImpactsStatus();
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        })
    );
  }

  getProjetStructures(): void {
    this.subscriptions.push(
      this.structureService.getStructureById(this.structureId).subscribe(response => {
        if (response) {
          this.subscriptions.push(
            this.structureService.getStructuresListObservable().subscribe(structures => {
              this.allStructures = structures;
              this.structures = structures.filter(structure => !structure.closed);
            })
          );
          this.structureService.setUniqueStructureListObservable(response.body, this.allStructures);
          this.structure = response.body;

          this.isStructureUpdatableByUser = this.sharedFunction.isStructureUpdatableByUser(
            this.aap,
            this.projet,
            this.structure,
            this.utilisateur,
            this.userPermissions
          );
          this.isGrilleImpactsAccessible = this.isFicheDemandeAccessible =
            this.sharedFunction.isStructureNotMandantaireAndHasUserInContacts(this.structure, this.utilisateur) ||
            this.sharedFunction.isUserMandataire(this.projet.id, this.utilisateur);

          this.isFicheDemandeAccessible &&= this.isStructureUpdatableByUser;

          this.listDocAapStructure = this.sharedFunction.getDocStructureAap(this.aap, this.structure, this.projet);
          this.listDocAapStructureActio = this.sharedFunction.getDocActioStructureAap(this.aap, this.structure, this.projet);

          this.listDocAapStructure.sort((a, b) => (a.nomDocument.toLowerCase() > b.nomDocument.toLowerCase() ? 1 : -1));
          this.listDocAapStructureActio.sort((a, b) => (a.nomDocument.toLowerCase() > b.nomDocument.toLowerCase() ? 1 : -1));

          this.formatSiretStructure();
          this.responsableProjet = this.structure.contacts.filter(contact => contact.roles.includes(this.RESPONSABLE_PROJET))[0];
          this.representantLegaux = this.structure.contacts.filter(contact => contact.roles.includes(this.REPRESENTANT_LEGAL));
          this.contacts = this.structure.contacts.filter(contact => !contact.inactif);
          this.autresContacts = this.structure.contacts.filter(
            contact => !contact.roles.includes(this.REPRESENTANT_LEGAL) || contact.roles.length === 0
          );
          this.autresContactsDeleted = this.autresContacts.filter(contact => contact.inactif);
          this.autresContacts = this.autresContacts.filter(contact => !contact.inactif);
          this.membresEquipe = this.structure.equipe;
          this.responsableProjetBoolean = this.structureValidationFunction.checkExistanceResponsable(
            this.structure,
            EnumRoleContact.RESPONSABLE_PROJET
          );
          this.responsableAdministratifBoolean = this.structureValidationFunction.checkExistanceResponsable(
            this.structure,
            EnumRoleContact.RESPONSABLE_ADMINISTRATIF
          );
          this.responsableProjetEquipeBoolean = this.structureValidationFunction.checkExistanceResponsableProjetEquipe(this.structure);
          this.demandeDeCorrectionExist = this.sharedFunction.isDemandeRectificationExist(this.structure);
          this.demandeDeCorrectionTreated = this.sharedFunction.isDemandeRectificationTreated(this.structure);

          this.isSectionsRenseignees = this.checkSectionsRensigneesForDisplayFicheDemandeAide();
          this.isInfosGeneralesRenseignees = !this.checkInfosGeneralesNotRenseignees();
          this.isStructureMandataire = this.checkIsStructureMandataire();
          if (this.isFicheDemandeAidePresent) {
            this.getFicheDemandeAideStatut();
          }
          this.ficheDemandeAideNeeded = this.checkFicheDemandeAideNeeded();
          this.loadSignataires();
        }
      })
    );
  }

  getStructDocuments(): void {
    const fork = forkJoin({
      documents: this.projetService.getDocumentsStructure(this.projet.id, this.structureId, [
        EnumTypeDocument.STRUCTURE,
        EnumTypeDocument.STRUCTURE_ACTIONARIAL,
        EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE,
        EnumTypeDocument.FICHE_DEMANDE_AIDE,
      ]),
      demandesComplements: this.demandeComplementService.getDemandesComplements(this.projet.id, this.structureId),
    });
    this.subscriptions.push(
      fork.subscribe({
        next: resp => {
          this.listDocumentStructure = resp.documents.body.filter(
            (doc: DocumentProjet) =>
              doc.typeDoc !== EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE && doc.typeDoc !== EnumTypeDocument.FICHE_DEMANDE_AIDE
          );
          this.demandesComplements = resp.demandesComplements.body;
          this.demandesComplementsEncours = this.demandesComplements.filter((d: DemandeComplements) =>
            ['ENVOYEE', 'COMPLETEE'].includes(d.statut)
          );
          this.documentsComplementaires = resp.documents.body.filter(
            (doc: DocumentProjet) => doc.typeDoc === EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE
          );
          this.ficheDmdAideDocuments = resp.documents.body.filter(
            (doc: DocumentProjet) => doc.typeDoc === EnumTypeDocument.FICHE_DEMANDE_AIDE
          );
          this.documentsComplementaires.forEach(
            doc => (doc.type = EnumDocumentComplementaireType.toString(doc.type as EnumDocumentComplementaireType))
          );
          this.updateDocumentsComplementaires();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      })
    );
  }

  loadInformationsBancaires(): void {
    this.informationsBancairesHttpService
      .getInformationsBancaires(this.structureId)
      .pipe(
        this.takeUntilDestroyed(),
        map(response => response.body ?? [])
      )
      .subscribe((informationsBancaires: InformationBancaire[]) => {
        this.selectedInformationBancaire = informationsBancaires.filter(info => info.selectionne)[0];
      });
  }

  updateDocumentsComplementaires() {
    this.remainingDocumentsComplementaires = [];
    this.isDocumentsComplementairesInvalid = false;
    this.demandesComplementsEncours.forEach(d => {
      d.documents.forEach(doc => {
        if (
          !this.documentsComplementaires.some(
            document => document.demandeComplementId === d.id && document.type === EnumDocumentComplementaireType.toString(doc.type)
          )
        ) {
          this.remainingDocumentsComplementaires.push({
            nom: EnumDocumentComplementaireType.toString(doc.type),
            demandeComplementId: d.id,
          });
          if (doc.obligatoire) {
            this.isDocumentsComplementairesInvalid = true;
          }
        }
      });
    });
  }

  private updateGrilleImpactsStatus() {
    if (!this.isKpiStructureNeeded) {
      this.isStatutCompleted = true;
      return;
    }
    const sub = this.projetService
      .getProjetObservable()
      .pipe(
        concatMap(projet => this.grilleImpactHttpService.getProjectAndStructuresKpisStatus(projet?.id)),
        map(grilleImpactsStatuts => GrilleImpactHelperService.getGIStructureStatut(grilleImpactsStatuts, this.structureId))
      )
      .subscribe(this.setGrilleImpactsStatus.bind(this));
    this.subscriptions.push(sub);
  }

  private setGrilleImpactsStatus(statut: StatutGrilleImpacts) {
    this.isStatutCompleted = GrilleImpactHelperService.isGrilleImpactsValid(statut);
  }

  uploadButtonIsActive(): boolean {
    return this.isStructureUpdatableByUser && this.needsDocuments();
  }

  checkIfUserNotInStructure(): boolean {
    const contactMatchUser = this.structure?.contacts.filter(
      contact => contact.matricule?.toUpperCase() === this.utilisateur.matricule?.toUpperCase()
    );
    return contactMatchUser.length === 0;
  }
  /*
   * Supprime la structure
   * */
  deleteStructure(): void {
    if (this.isStructureUpdatableByUser) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          description: `<p>Êtes-vous sûr de vouloir supprimer cette structure.</p>
                        <p>Tous les accès des contacts associés vont être révoqués.</p>
                            <p>Cette action est irréversible. </p>`,
          textGoButton: 'Oui',
          textReturnButton: 'Non',
          icon: true,
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const nameStructureToDelete = this.structure.raisonSocial;
          this.structure.closed = true;
          this.subscriptions.push(
            this.structureService.deleteStructure(this.structureId, this.structure).subscribe({
              next: (response: any) => {
                if (response?.status === 206) {
                  // status 206 notification n'est pas envoyée
                  this.showToastrService.error("Une erreur s'est produite. Aucun contact dans la structure n'a été notifié.");
                } else {
                  this.showToastrService.success('La structure ' + nameStructureToDelete + ' a bien été supprimée');
                }
                this.onGoToConsortium();
              },
              error: (err: HttpErrorResponse) => {
                this.showToastrService.checkCodeError(err?.error);
              },
            })
          );
        }
      });
    }
  }

  /*
   *  Reformat le Siret affiché
   * */
  formatSiretStructure(): void {
    if (this.structure.siret) {
      this.structure.siret = this.structure.siret.replace(/.{13}(?=.)/, m => m.replace(/.{10}(?=.)/, n => n.replace(/.{3}(?=.)/g, '$& ')));
    }
    if (this.structure.lieuRD?.siret) {
      this.structure.lieuRD.siret = this.structure.lieuRD.siret.replace(/.{13}(?=.)/, m =>
        m.replace(/.{10}(?=.)/, n => n.replace(/.{3}(?=.)/g, '$& '))
      );
    }
  }

  /*
   * Permet de retourner à la page d'information consortium
   * */
  onGoToConsortium(): void {
    this.router.navigate(['/projet-creation', this.route.snapshot.parent.params.id, 'projet-consortium']);
  }

  /*
   * Permet d'aller a la page modification contact
   * */
  onGoToContact(contact: Contact, role?: string): void {
    if (this.isStructureUpdatableByUser) {
      let contactId = '';
      if (role) {
        contactId = role;
      } else if (contact) {
        contactId = this.structure.contacts.indexOf(contact).toString();
      }

      this.router.navigate(['projet-consortium-contact/' + contactId], { relativeTo: this.route });
    }
  }

  onGoToProjetBudget(): void {
    if (this.isStructureUpdatableByUser) {
      this.router.navigate(['projet-consortium-budget'], { relativeTo: this.route });
    }
  }

  onGoToRibIban(): void {
    if (this.isStructureUpdatableByUser) {
      this.router.navigate(['projet-consortium-information-bancaire'], { relativeTo: this.route });
    }
  }

  onGoToGrilleImpacts(roleStructure: EnumRoleStructure): void {
    if (this.isGrilleImpactsAccessible && this.checkFinancement()) {
      this.router.navigate(['projet-consortium-grille-impacts'], { relativeTo: this.route, state: { role: roleStructure } });
    }
  }

  /*
   * Affiche la modale pour ajouter un document
   * */
  onUploadDocument(docs: UploadDocModalDocumentModel[]): void {
    if (this.isStructureUpdatableByUser && !this.disableDoc() && this.uploadButtonIsActive()) {
      this.handleUpload(docs, EnumScope.STRUCTURE);
    }
  }

  onGoToFicheDemandeAide(): void {
    if (this.isFicheDemandeAuthorized) {
      this.router.navigate(['projet-consortium-fiche-demande-aide'], { relativeTo: this.route });
    } else {
      this.showToastrService.error(
        'Vous n’êtes pas habilité.e à ajouter, visualiser ou supprimer la fiche de demande d’aide, vous pouvez vous synchroniser avec les autres contacts de votre structure ou de la structure mandataire si présente pour pouvoir être habilité.e.'
      );
    }
  }

  handleUpload(docs: UploadDocModalDocumentModel[], scope: EnumScope): void {
    const dialogRef = this.dialog.open(SelectDocumentModalComponent, {
      data: {
        title: 'Ajouter un fichier',
        docList: docs,
        projet: this.projet,
        isAuthorized: this.isStructureUpdatableByUser,
        textGoButton: 'Ajouter',
        textReturnButton: 'Annuler',
        isProjetInfoPage: false,
        accept: '.pdf, .csv, .xls, .xlsx, .doc, .docx, .ppt, .pptx',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.allowDownload = true;
        this.documentStructure.type = result[0];
        this.documentStructure.etape = this.sharedFunction.getProjectEtapeName(this.projet);
        this.onUpload(result[1], scope, result[2]);
      }
    });
  }

  onUploadFicheDemandeAide(event: any): void {
    const fileInput: HTMLInputElement = event.target as HTMLInputElement;
    const file: File = fileInput.files[0];
    if (!file) {
      return;
    }
    fileInput.value = null;
    if (!file.name.toLowerCase().endsWith(this.FICHE_DMD_AIDE_FILE_EXTENSION)) {
      this.showToastrService.error(
        `le type de fichier sélectionné n'est pas supporté, le seul format accepté est ${this.FICHE_DMD_AIDE_FILE_EXTENSION}`
      );
      return;
    }
    if (!this.isFileSizeValid(file)) {
      this.showToastrService.error(`Le fichier importé est trop volumineux, la taille maximale autorisée est de ${FILE_SIZE}Mo`);
      return;
    }
    const document = new DocumentProjet();
    document.nom = file.name;
    document.typeDoc = 'FICHE_DEMANDE_AIDE';
    document.scope = EnumScope.STRUCTURE;
    document.projetId = this.projet.id;
    document.structureId = this.structureId;
    document.dateModification = this.projet.dateModification;
    document.etape = this.sharedFunction.getProjectEtapeName(this.projet);
    this.isUploading = true;
    this.projetService
      .upsertDocumentProjet(document)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (rep: any) => {
          const typeUpload = 'B401';
          const path = this.projet.id + '/' + this.structureId + '/' + rep.body.id;
          this.uploadDocumentService
            .getValueForDocStructureUpload(file.name, path, typeUpload, rep.body.id, this.projet.id)
            .pipe(this.takeUntilDestroyed())
            .subscribe({
              next: (reponse: any) => {
                const url = reponse.body.url;
                this.uploadDocumentService
                  .upload(url, file, this.projet.id, rep.body.id, typeUpload)
                  .pipe(this.takeUntilDestroyed())
                  .subscribe({
                    next: () => {
                      this.showToastrService.success('Le fichier a bien été ajouté');
                      this.isUploading = false;
                      this.ficheDmdAideDocuments.push(rep.body);
                    },
                    error: (err: HttpErrorResponse) => {
                      this.showToastrService.checkCodeError(err?.error);
                      this.isUploading = false;
                      this.deleteDocumentStructure(document, true);
                    },
                  });
              },
              error: (err: HttpErrorResponse) => {
                this.showToastrService.checkCodeError(err?.error);
                this.isUploading = false;
                this.deleteDocumentStructure(document, true);
              },
            });
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
          this.isUploading = false;
        },
      });
  }

  onDeleteFicheDmdAideDocument(document: DocumentProjet): void {
    this.ficheDmdAideDocuments = this.ficheDmdAideDocuments.filter(doc => doc.id !== document.id);
  }

  /*
   * Affiche le nom du créateur du Document
   * */
  getNomCreateurDocument(document: DocumentProjet): string {
    return DocumentHelper.getNomCreateurDocument(document);
  }

  /*
   * Affiche la modale pour ajouter un document Actionnariale
   * */
  onUploadDocumentActio(): void {
    if (
      this.isStructureUpdatableByUser &&
      !(this.disableDoc() || this.listDocAapStructureActio.length < 1) &&
      this.uploadButtonIsActive()
    ) {
      this.handleUpload(this.toUploadDocModalDocumentModel(this.listDocAapStructureActio), EnumScope.STRUCTURE_ACTIONARIAL);
    }
  }

  isFileSizeValid(file: File): boolean {
    return !(file.size && file.size > FILE_SIZE * 1048576);
  }

  isFileTypeValid(file: File, supportedTypesExtensions: string[]): boolean {
    return supportedTypesExtensions.includes(file.type);
  }

  setFileInvalidityFlags(
    document: UploadDocModalDocumentModel,
    scope: EnumScope,
    isFileSizeValid: boolean,
    isFileTypeValid: boolean
  ): void {
    const isConnaissanceClient = !!document.connaissanceClient;
    const isDocumentComplementaire = !!document.demandeComplementId;
    const isDocumentStructure = !isDocumentComplementaire && scope === EnumScope.STRUCTURE;
    const isDocumentStructureActionariale = !isDocumentComplementaire && scope === EnumScope.STRUCTURE_ACTIONARIAL;

    this.fileTooBig = isDocumentStructure && !isFileSizeValid;
    this.fileActioTooBig = isDocumentStructureActionariale && !isFileSizeValid;
    this.fileComplTooBig = isDocumentComplementaire && !isFileSizeValid;

    this.fileNotSupported = isDocumentStructure && !isConnaissanceClient && !isFileTypeValid;
    this.fileActioNotSupported = isDocumentStructureActionariale && !isConnaissanceClient && !isFileTypeValid;
    this.fileComplNotSupported = isDocumentComplementaire && !isConnaissanceClient && !isFileTypeValid;

    this.connaissanceClientFileNotSupported = isDocumentStructure && isConnaissanceClient && !isFileTypeValid;
    this.connaissanceClientFileActioNotSupported = isDocumentStructureActionariale && isConnaissanceClient && !isFileTypeValid;
    this.connaissanceClientFileComplNotSupported = isDocumentComplementaire && isConnaissanceClient && !isFileTypeValid;
  }

  resetFileInvalidityFlags(): void {
    this.fileTooBig = false;
    this.fileActioTooBig = false;
    this.fileComplTooBig = false;

    this.fileNotSupported = false;
    this.fileActioNotSupported = false;
    this.fileComplNotSupported = false;

    this.connaissanceClientFileNotSupported = false;
    this.connaissanceClientFileActioNotSupported = false;
    this.connaissanceClientFileComplNotSupported = false;
  }

  onUpload($event: any, scope: EnumScope, document: UploadDocModalDocumentModel): void {
    this.resetFileInvalidityFlags();
    if (this.allowDownload) {
      this.allowDownload = false;
      const typeUpload = 'B401';

      const isConnaissanceClient = !!document.connaissanceClient;
      const fileToUpload: File = $event.item(0);
      const fileTypes = isConnaissanceClient ? this.CONNAISSANCE_CLIENT_FILE_TYPES : this.DEFAULT_FILE_TYPES;
      const isFileSizeValid = this.isFileSizeValid(fileToUpload);
      const isFileTypeValid = this.isFileTypeValid(fileToUpload, fileTypes);

      if (fileToUpload && isFileSizeValid && isFileTypeValid) {
        this.uploadFile(fileToUpload, scope, document, typeUpload);
      } else {
        this.setFileInvalidityFlags(document, scope, isFileSizeValid, isFileTypeValid);
      }
    }
  }

  private uploadFile(file: File, scope: EnumScope, document: UploadDocModalDocumentModel, typeUpload: string) {
    this.documentStructure.nom = file.name;
    this.documentStructure.dateModification = this.projet.dateModification;
    this.documentStructure.projetId = this.projet.id;
    this.documentStructure.scope = scope;
    this.documentStructure.typeDoc = document.demandeComplementId ? 'DOCUMENT_COMPLEMENTAIRE' : scope;
    this.documentStructure.structureId = this.structureId;
    this.documentStructure.demandeComplementId = document.demandeComplementId;
    if (this.documentStructure.typeDoc === EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE) {
      this.documentStructure.type = document?.nom ? (EnumDocumentComplementaireType.toKey(document?.nom) as string) : '';
    } else {
      this.documentStructure.type = document?.nom ?? '';
    }

    this.isUploading = true;
    this.subscriptions.push(
      this.projetService.upsertDocumentProjet(this.documentStructure).subscribe({
        next: (rep: any) => {
          this.documentStructure = rep.body;
          if (document.demandeComplementId) {
            this.documentsComplementaires.push({
              ...this.documentStructure,
              type: EnumDocumentComplementaireType.toString(this.documentStructure.type as EnumDocumentComplementaireType),
            });
            this.updateDocumentsComplementaires();
          } else {
            this.listDocumentStructure.push(this.documentStructure);
          }
          const path = this.projet.id + '/' + this.structureId + '/' + this.documentStructure.id;
          this.subscriptions.push(
            this.uploadDocumentService
              .getValueForDocStructureUpload(file.name, path, typeUpload, this.documentStructure.id, this.projet.id)
              .subscribe({
                next: (reponse: any) => {
                  const url = reponse.body.url;
                  this.subscriptions.push(
                    this.uploadDocumentService.upload(url, file, this.projet.id, this.documentStructure.id, typeUpload).subscribe({
                      next: () => {
                        this.documentStructure = new DocumentProjet();
                        this.showToastrService.success('Le fichier a bien été ajouté');
                        this.isUploading = false;
                        this.getStructDocuments();
                      },
                      error: (err: HttpErrorResponse) => {
                        this.showToastrService.checkCodeError(err?.error);
                        this.isUploading = false;
                        this.deleteDocumentStructure(this.documentStructure, true);
                      },
                    })
                  );
                },
                error: (err: HttpErrorResponse) => {
                  this.showToastrService.checkCodeError(err?.error);
                  this.isUploading = false;
                  this.deleteDocumentStructure(this.documentStructure, true);
                },
              })
          );
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
          this.isUploading = false;
        },
      })
    );
  }

  /*
   * Download a document
   * */
  canDownload(document: DocumentProjet): boolean {
    return this.utilisateur.matricule === document.createur;
  }

  deleteDocumentStructure(document: DocumentProjet, keepInS3?: boolean): void {
    this.subscriptions.push(
      this.projetService.deleteDocument(this.projet.id, document.id).subscribe({
        next: response => {
          if (response) {
            this.listDocumentStructure = this.listDocumentStructure.filter(doc => doc.id !== document.id);
            this.documentsComplementaires = this.documentsComplementaires.filter(doc => doc.id !== document.id);
            this.updateDocumentsComplementaires();
            if (keepInS3) {
              return;
            }
            this.showToastrService.success('Le fichier a bien été supprimé');

            if (document.scan === EnumScanDocument.UNSAFE) {
              return;
            }
            if (document.stockerGed) {
              return;
            }
            const path = this.projet.id + '/' + this.structureId + '/' + document.id + '/' + document.nom;
            this.subscriptions.push(
              this.uploadDocumentService.deletDocStructureUpload(path, 'B401', document.id, this.projet.id).subscribe({
                error: (err: HttpErrorResponse) => {
                  this.showToastrService.checkCodeError(err?.error);
                },
              })
            );
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      })
    );
  }

  /*
   * Affiche le role du contact selon l'enum
   * */
  displayedRoleContact(role: EnumRoleContact): string {
    if (role === this.RESPONSABLE_PROJET) {
      return 'Responsable projet';
    }
    if (role === this.RESPONSABLE_ADMINISTRATIF) {
      return 'Responsable administratif et financier';
    }
    return '';
  }
  /*
   * Affiche le numero de telephone au bon format
   * */
  parsePhoneNumber(phone: string): string {
    phone = phone?.replace(/\d{2}(?=.)/g, '$& ');
    return phone;
  }

  /*
   * Cette fonction retourne l'adresse du siége et le lieu de réalisation
   * */
  getAdresseSiege(SiegeLieuRD: string): string {
    let structureAdresse;
    if (SiegeLieuRD === 'siege') {
      structureAdresse = this.structure;
    } else {
      structureAdresse = this.structure.lieuRD;
    }
    if (structureAdresse.adresse?.codePays === 'FR') {
      const qualifRue = (EnumQualifRue as any)[structureAdresse.adresse?.complement] || structureAdresse.adresse?.complement;
      const typeVoie = (EnumTypeVoie as any)[structureAdresse.adresse?.typeVoie] || structureAdresse.adresse?.typeVoie;
      return (
        this.getInfoItem(structureAdresse.adresse?.numero, ' ') +
        ' ' +
        this.getInfoItem(qualifRue?.toLowerCase(), '') +
        ' - ' +
        this.getInfoItem(typeVoie?.toLowerCase(), ' ') +
        ' ' +
        this.getInfoItem(structureAdresse.adresse?.voie, ' ') +
        ' - ' +
        this.getInfoItem(structureAdresse.adresse?.cp, ' ') +
        ' - ' +
        this.getInfoItem(structureAdresse.adresse?.ville, ' ') +
        ' - ' +
        this.getInfoItem(structureAdresse.adresse?.pays, ' ')
      );
    } else {
      const voie = structureAdresse.adresse?.voie != null ? `${structureAdresse.adresse.voie} - ` : '';
      const cp = structureAdresse.adresse.cp != null ? `${structureAdresse.adresse.cp} ` : '';
      const ville = structureAdresse.adresse.ville != null ? `- ${structureAdresse.adresse.ville}` : '';
      const pays = structureAdresse.adresse?.pays != null ? ` - ${structureAdresse.adresse.pays} ` : '';

      return voie + cp + ville + pays;
    }
  }

  getInfoItem(infoItem: any, defaultValue: string) {
    return infoItem || defaultValue;
  }

  /*
   * Cette fonction retourne la valeur de l'enum typeStructure
   * */
  getTypeStructure(typeStructure: string): string {
    if (this.structureType[typeStructure]) {
      return ' - ' + EnumTypeStructure.toString(typeStructure as EnumTypeStructure);
    } else {
      return '';
    }
  }

  /*
   * Popup invitation contact
   * */
  inviteContact(): void {
    const dialogRef = this.dialog.open(InvitationContactModalComponent, {
      data: {
        title: 'Inviter un contact',
        isAuthorized: this.isStructureUpdatableByUser,
        aap: this.aap,
        textGoButton: 'Inviter',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe(emailResult => {
      if (emailResult) {
        this.invitation.dateExpiration = new Date();
        this.invitation.dateExpiration.setDate(this.invitation.dateExpiration.getDate() + 7);

        const contact = new Contact();
        contact.email = emailResult;
        contact.roles = [this.AUTRE];
        contact.dateModification = this.structure.dateModification;
        contact.dateExpirationInvitation = this.invitation.dateExpiration;

        const existingContact = this.autresContactsDeleted.find(c => c.email === emailResult);
        if (existingContact) {
          this.showToastrService.error(
            'Un contact avec ce mail existe déjà au niveau de la structure dans la section "Contact(s) supprimé(s)" vous pouvez le réinviter en cliquant sur le contact'
          );
        } else {
          this.subscriptions.push(
            this.structureService.addContactStructure(this.structureId, contact).subscribe({
              next: resp => {
                if (resp) {
                  // status 206 notification n'est pas envoyée
                  if (resp.status === 206) {
                    this.showToastrService.error(
                      "Une erreur s'est produite. L'invitation n'a pas été envoyée. Veuillez renvoyer l'invitation depuis la fiche contact dans la structure."
                    );
                  } else {
                    this.showToastrService.success('Le contact a bien été invité');
                  }
                  this.autresContacts.push(contact);
                  this.structureService.setUniqueStructureListObservable(resp.body, this.allStructures);

                  this.contacts.push(contact);
                  this.projet.dateModification = resp.body.dateModification;
                  this.getProjetIfExist();
                }
              },
              error: (err: HttpErrorResponse) => {
                this.showToastrService.error(err?.error?.message);
              },
            })
          );
        }
      }
    });
  }

  statutInvitation(contact: Contact): string {
    const date = Date.parse(contact.dateExpirationInvitation.toString());
    if (date < Date.now()) {
      return 'Invitation Expirée';
    }
    return 'Invitation En attente';
  }

  /*
   * Permet de desactiver l'upload de document
   * */
  disableDoc(): boolean {
    let budget;
    if (this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT && !this.aap.budgetEstime) {
      budget = this.structure.budgetPreDepot;
    } else if (this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.DEPOT) {
      budget = this.structure.budgetDepot;
    }
    return (
      ((!budget?.montant || !this.structure.typeStructure || !budget.besoin) && !this.checkIsStructureMandataire()) ||
      (!this.structure.typeStructure && this.checkIsStructureMandataire())
    );
  }

  /*
   * Permet d'aller a la page modification d'structure
   * */
  modifyStructure(): void {
    if (this.isStructureUpdatableByUser) {
      this.router.navigate(['projet-consortium-creation', this.structureId], { relativeTo: this.route.parent });
    }
  }

  modifyStructureRD(): void {
    if (this.isStructureUpdatableByUser) {
      this.router.navigate(['projet-consortium-lieu-rd', this.structureId], { relativeTo: this.route.parent });
    }
  }

  absenceSiret(lieuRD?: boolean): string {
    let structureLieuRD;
    if (lieuRD) {
      structureLieuRD = this.structure.lieuRD;
    } else {
      structureLieuRD = this.structure;
    }
    switch (structureLieuRD.raisonSiret) {
      case EnumMotifNonRattachement.IMMATRICULATION_EN_COURS:
        return "Structure en cours d'immatriculation au RCS";
      case EnumMotifNonRattachement.NON_IMMATRICULE:
        return 'Structure non immatriculée au RCS';
      case EnumMotifNonRattachement.EN_COURS_DE_CREATION:
        return 'Structure en cours de création';
    }
  }

  checkFinancement(): boolean {
    if (this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT && !this.aap.budgetEstime) {
      if (this.structure.budgetPreDepot) {
        return this.structure.budgetPreDepot.besoin;
      }
    } else if (this.sharedFunction.getProjectEtapeName(this.projet) !== EnumProjetEtape.PRE_DEPOT) {
      if (this.structure.budgetDepot) {
        return this.structure.budgetDepot.besoin;
      }
    }
    return true;
  }

  getBudget(): string {
    if (this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT && !this.aap.budgetEstime) {
      return this.structure.budgetPreDepot.montant == undefined ? '0' : String(this.structure.budgetPreDepot.montant);
    } else if (this.sharedFunction.getProjectEtapeName(this.projet) !== EnumProjetEtape.PRE_DEPOT) {
      return this.structure.budgetDepot.montant == undefined ? '0' : String(this.structure.budgetDepot.montant);
    }
    return '-';
  }

  absenceSiretRD(): string {
    if (this.structure.lieuRD.raisonSiret === EnumMotifNonRattachement.IMMATRICULATION_EN_COURS) {
      return "Structure en cours d'immatriculation au RCS";
    } else {
      return 'Structure non immatriculée au RCS';
    }
  }

  structureTypeComplete(): boolean {
    return this.structure.typeStructure != null;
  }

  structureBudgetComplete(): boolean {
    return (
      this.checkIsStructureMandataire() ||
      (this.structure.budgetDepot.montant !== null &&
        (this.etapeProjet !== EnumProjetEtape.PRE_DEPOT || (this.etapeProjet === EnumProjetEtape.PRE_DEPOT && !this.aap.budgetEstime)))
    );
  }

  /**
   * boolean qui indique si la structure doit uploader des documents
   */
  needsDocuments(): boolean {
    return (this.checkIsStructureMandataire() || this.structure.budgetDepot?.besoin) && this.listDocAapStructure.length !== 0;
  }

  /**
   * Retourne un Boolean pour afficher le lien de dossier de candidature
   */
  showDossierCandidatureLink(): boolean {
    return this.etapeProjet === EnumProjetEtape.PRE_DEPOT && this.aap.lienDocumentation?.length > 0;
  }

  uploadDocButtonIsValid(scope: EnumScope): boolean {
    let numberOfDocumentsUploadedInEtape;
    if (scope === EnumScope.STRUCTURE) {
      numberOfDocumentsUploadedInEtape = this.listDocumentStructure.filter(
        document =>
          (document.etape === this.sharedFunction.getProjectEtapeName(this.projet).toString() ||
            this.listDocAapStructure.filter(doc => doc.nomDocument === document.type).length > 0) &&
          document.scope === scope
      ).length;
    } else if (scope === EnumScope.STRUCTURE_ACTIONARIAL) {
      numberOfDocumentsUploadedInEtape = this.listDocumentStructure.filter(
        document =>
          (document.etape === this.sharedFunction.getProjectEtapeName(this.projet).toString() ||
            this.listDocAapStructureActio.filter(doc => doc.nomDocument === document.type).length > 0) &&
          document.scope === scope
      ).length;
    }

    return numberOfDocumentsUploadedInEtape > 0;
  }

  /*
   * Vérifie si le document uploadé a passé le test antivirus
   * */
  isValidDocument(document: DocumentProjet): boolean {
    return this.structureValidationFunction.isValidDocument(document);
  }

  isScanedDocument(document: DocumentProjet): boolean {
    return this.structureValidationFunction.isScanedDocument(document);
  }

  downloadRGPD(): void {
    window.open(URL_PUBLIC_DOC + this.aap.programme.fichierRgpd);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }

  toUploadDocModalDocumentModel(documents: DocumentAapModel[]): UploadDocModalDocumentModel[] {
    return documents.map((document: DocumentAapModel) => ({
      nom: document.nomDocument,
      mediaTypes: document.mediaTypes,
      connaissanceClient: document.connaissanceClient,
    }));
  }

  checkPresenceFicheDemandeAide(fiche: FicheDemandeAidePresent, etapeProjet: EnumProjetEtape): boolean {
    return !(
      fiche === null ||
      !fiche?.active ||
      (fiche?.active && etapeProjet === EnumProjetEtape.PRE_DEPOT && fiche?.etape !== EnumProjetEtape.PRE_DEPOT)
    );
  }

  checkSectionsRensigneesForDisplayFicheDemandeAide(): boolean {
    return (
      this.structureValidationFunction.isBudgetSet(this.aap, this.projet, this.structure) &&
      this.structureValidationFunction.checkRepresentantLegal(this.structure) &&
      this.structureValidationFunction.checkInfosSiegeInfosStructure(this.structure) &&
      this.structureValidationFunction.checkLieuDeRealisation(this.structure)
    );
  }

  checkInfosGeneralesNotRenseignees(): boolean {
    return this.projet.nom == null && this.projet.acronyme == null && this.projet.description == null;
  }

  checkIsStructureMandataire(): boolean {
    return this.structure.role === EnumRoleStructure.MANDATAIRE;
  }

  getFicheDemandeAideStatut(): void {
    this.ficheDemandeAideService
      .getStatutFicheDemandeAide(this.structure.id)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: resp => {
          this.ficheDemandeAideStatut = resp.body;
          this.isFicheDemandeAuthorized = true;
        },
        error: (err: HttpErrorResponse) => {
          this.ficheDemandeAideStatut = null;
          if (err?.status === 404) {
            this.isFicheDemandeAuthorized = true;
          } else if (err?.status === 403) {
            this.isFicheDemandeAuthorized = false;
          } else {
            this.showToastrService.checkCodeError(err?.error);
          }
        },
      });
  }

  private checkFicheDemandeAideNeeded(): boolean {
    if (!this.aap?.ficheDemandeAidePresent?.etape) {
      return false;
    }

    if (
      this.aap?.ficheDemandeAidePresent?.etape === EnumProjetEtape.DEPOT &&
      this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT
    ) {
      return false;
    }

    const result =
      this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT
        ? this.structure?.budgetPreDepot?.besoin
        : this.structure?.budgetDepot?.besoin;

    return result ?? true;
  }

  onGoToSignataire(signataire: Signataire): void {
    if (this.isStructureUpdatableByUser) {
      const routeUrl = signataire?.id ? 'projet-consortium-signataire/' + signataire.id : 'projet-consortium-signataire';
      this.router.navigate([routeUrl], { relativeTo: this.route });
    }
  }

  private loadSignataires() {
    this.structureService.getSignataires(this.structureId).subscribe({
      next: response => {
        if (response.body) {
          this.signataires = response.body;
          this.signatairesValide = this.signataires.filter(
            signataire => signataire.statut !== EnumStatutSignataire.INVALIDE || signataire.isFromFicheDemandeAide
          );
          this.signatairesInvalide = this.signataires.filter(
            signataire => signataire.statut === EnumStatutSignataire.INVALIDE && !signataire.isFromFicheDemandeAide
          );
        }
      },
      error: err => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  protected readonly EnumScope = EnumScope;
  protected readonly EnumRoleStructure = EnumRoleStructure;
  protected readonly EnumProjetEtape = EnumProjetEtape;
  protected readonly EnumTypePartenaire = EnumTypePartenaire;
  protected readonly EnumFeatureFlipping = EnumFeatureFlipping;
}
