<!-- Entête de la page -->
<header id="header-structure" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToProjetInfo()">
    <pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></pxl-icon>
    Projet
  </a>
  <h2 class="page-header__title">Dépenses du projet</h2>
  <div class="page-header__action">
    <a (click)="downloadTemplate()">
      <pxl-icon class="icon" icon="icon-action-save-alt" aria-hidden="true"></pxl-icon>
      <span class="text-download">Télécharger le modèle de dépense</span>
    </a>
  </div>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <section class="page-section" *ngIf="expensesForm && isLoaded">
    <div class="card budget">
      <div id="dstab" class="ds-tabs ds-tabs--underline">
        <ul class="ds-tabs-list d-flex justify-content-between" role="tablist" aria-label="nom du système d'onglet">
          <div class="d-flex">
            <li>
              <button
                id="tab_a"
                (click)="selectTab('calendar')"
                class="ds-tabs-list__tab ds-tabs-list__tab--active"
                role="tab"
                [tabindex]="0"
                aria-controls="panela"
                aria-selected="true"
              >
                <pxl-icon
                  class="card__check"
                  [ngClass]="{ valid: expenses?.calendrier?.statut === 'VALIDE' }"
                  icon="icon-action-check-circle"
                  aria-hidden="true"
                ></pxl-icon>
                <span>Calendrier</span>
              </button>
            </li>
            <li>
              <button
                id="tab_b"
                (click)="selectTab('structure')"
                class="ds-tabs-list__tab"
                role="tab"
                [tabindex]="1"
                aria-controls="panelb"
                aria-selected="false"
              >
                <pxl-icon
                  class="card__check"
                  [ngClass]="{ valid: expenses?.structures?.statut === 'VALIDE' }"
                  icon="icon-action-check-circle"
                  aria-hidden="true"
                ></pxl-icon>
                <span>Structure</span>
              </button>
            </li>
            <li>
              <button
                id="tab_c"
                (click)="selectTab('download')"
                class="ds-tabs-list__tab"
                role="tab"
                [tabindex]="2"
                aria-controls="panelc"
                aria-selected="false"
                [disabled]="expenses?.calendrier?.statut !== 'VALIDE' || expenses?.structures?.statut !== 'VALIDE'"
              >
                <pxl-icon
                  class="card__check"
                  [ngClass]="{ valid: documentBdd?.id }"
                  icon="icon-action-check-circle"
                  aria-hidden="true"
                ></pxl-icon>
                <span>Téléchargement des dépenses</span>
              </button>
            </li>
            <li>
              <button
                id="tab_d"
                (click)="selectTab('synthese')"
                class="ds-tabs-list__tab"
                role="tab"
                [tabindex]="3"
                aria-controls="paneld"
                aria-selected="false"
                [hidden]="!documentBdd?.id"
              >
                <pxl-icon
                  class="card__check"
                  [ngClass]="{ valid: expenses?.statut === 'VALIDE' }"
                  icon="icon-action-check-circle"
                  aria-hidden="true"
                ></pxl-icon>
                <span>Synthèse</span>
              </button>
            </li>
          </div>
          <section *ngIf="currentTab === 'synthese'">
            <section class="d-flex justify-content-end">
              <label class="ds-radio-classic" for="onglets">
                <input
                  type="radio"
                  class="ds-radio__input"
                  id="onglets"
                  name="enabled"
                  [checked]="afficherOnglets"
                  (click)="setAfficherOnglets(true)"
                />
                <span class="ds-radio__label"><span>Onglets</span></span>
              </label>

              <label class="ds-radio-classic" for="liste">
                <input
                  type="radio"
                  class="ds-radio__input"
                  id="liste"
                  name="enabled"
                  [checked]="!afficherOnglets"
                  (click)="setAfficherOnglets(false)"
                />
                <span class="ds-radio__label"><span>Liste</span></span>
              </label>
            </section>
          </section>
        </ul>

        <div [tabindex]="0" role="tabpanel" id="panela" class="ds-tabs-panel" aria-labelledby="tab_a">
          <pxl-projet-info-depenses-calendar [calendarForm]="expensesForm.get('calendrier')"></pxl-projet-info-depenses-calendar>
        </div>

        <div [tabindex]="0" role="tabpanel" id="panelb" class="ds-tabs-panel" aria-labelledby="tab_b" hidden="">
          <pxl-projet-info-depenses-structure
            [structureForm]="expensesForm.get('structures')"
            [validated]="validateClicked"
          ></pxl-projet-info-depenses-structure>
        </div>

        <div [tabindex]="0" role="tabpanel" id="panelc" class="ds-tabs-panel" aria-labelledby="tab_c" hidden="">
          <pxl-projet-info-depenses-upload
            (docChanged)="onDocChanged($event)"
            (expensesChanged)="onExpensesChanged($event)"
            [documentBdd]="documentBdd"
            [projet]="projet"
            [expenses]="expenses"
          ></pxl-projet-info-depenses-upload>
        </div>

        <div [tabindex]="0" role="tabpanel" id="paneld" class="ds-tabs-panel" aria-labelledby="tab_d" hidden="">
          <pxl-projet-info-depenses-synthese
            *ngIf="expenses?.lots && currentTab === 'synthese'"
            [depenses]="{ depense: expenses, projet: projet }"
            [afficherOnglets]="afficherOnglets"
            (lotsChanges)="updateFormWithLots($event)"
          ></pxl-projet-info-depenses-synthese>
        </div>
      </div>
    </div>
  </section>
  <div class="buttons-container" *ngIf="currentTab !== 'download'">
    <button id="cancel-btn" *ngIf="!currentTabValid" type="button" class="ds-btn ds-btn--tertiary" (click)="cancelBtn()">Annuler</button>
    <button id="save-btn" type="button" *ngIf="!currentTabValid" class="ds-btn ds-btn--secondary" (click)="saveBtn()">Enregistrer</button>
    <button id="valid-btn" *ngIf="!currentTabValid" class="ds-btn ds-btn--primary" (click)="validBtn()" [disabled]="syntheseNonUpdatable">
      Valider
    </button>
    <button id="modif-btn" *ngIf="currentTabValid" class="ds-btn ds-btn--primary" (click)="modifyBtn()">Modifier</button>
  </div>
</div>
