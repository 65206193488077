import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environments-candidat/environment';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { Feature, ShowToastrService } from '@shared-ui';
import { PxlOidcService } from '@services-candidat/pxl-oidc.service';

@Injectable()
export class AppConfig {
  static features: Feature[];
  constructor(private oidcSecurityService: PxlOidcService, private http: HttpClient, private showToastrService: ShowToastrService) {}
  load(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.oidcSecurityService.checkAuth().subscribe({
        next: ({ isAuthenticated }) => {
          if (isAuthenticated) {
            firstValueFrom(this.getFeatures('CANDIDAT'))
              .then((features: Feature[]) => {
                AppConfig.features = features;
                resolve();
              })
              .catch((error: HttpErrorResponse) => {
                reject(`Could not load features: ${JSON.stringify(error.statusText)}`);
              });
          } else {
            resolve();
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    });
  }

  getFeatures(site: string): Observable<Feature[]> {
    return this.http.get<Feature[]>(`${environment.apiUrl}features?site=${site}`);
  }
}
