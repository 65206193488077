import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CCU_FILE_NAME, DocumentHelper, DocumentProjet, Environment, Projet, SignedUrlResponseModel } from '@shared-ui';
import { Observable } from 'rxjs';

@Injectable()
export class UploadDocumentHttpService {
  constructor(@Inject('environment') private environment: Environment, public httpClient: HttpClient) {}

  getValueForDocModificationDownload(projet: Projet, document: DocumentProjet): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.httpClient.post<SignedUrlResponseModel>(
      this.environment.apiUrl + 'uploads/signed-url',
      {
        typeAction: 'GET',
        typeBucket: 'DOCUMENT_PRIVATE',
        nom: projet.id + '/demande_correction/' + document.id + '/' + document.nom,
      },
      { observe: 'response' }
    );
  }

  getValueForDocDownloadFromPath(path: string): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.httpClient.post<SignedUrlResponseModel>(
      this.environment.apiUrl + 'uploads/signed-url',
      {
        typeAction: 'GET',
        typeBucket: 'DOCUMENT_PRIVATE',
        nom: path,
      },
      { observe: 'response' }
    );
  }

  getValueForDocDownload(document: DocumentProjet): Observable<HttpResponse<{ url: string }>> {
    return this.getValueForDocDownloadFromPath(DocumentHelper.getDocumentS3FilePath(document));
  }

  deleteDocFromS3(
    path: string,
    typeUpload: string,
    documentId: string,
    projetId: string
  ): Observable<HttpResponse<SignedUrlResponseModel>> {
    const typeBucket = DocumentHelper.getDocumentBucketType(typeUpload);
    return this.httpClient.post<SignedUrlResponseModel>(
      this.environment.apiUrl + 'uploads/signed-url',
      {
        typeAction: 'DELETE',
        typeBucket: typeBucket,
        nom: path,
        metadata: {
          type: typeUpload,
          idProjet: projetId,
          idDocument: documentId,
        },
      },
      { observe: 'response' }
    );
  }

  getLogoDocument(idProjet: string): Observable<HttpResponse<DocumentProjet[]>> {
    return this.httpClient.get<DocumentProjet[]>(
      this.environment.apiUrl + 'documents?projetIds=' + idProjet + '&typesDocuments=MEDIA_IMAGE',
      {
        observe: 'response',
      }
    );
  }

  getValueForLogoUploadFicheDeCom(path: string, fileName: string, documentId: string): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.httpClient.post<SignedUrlResponseModel>(
      this.environment.apiUrl + 'uploads/signed-url',
      {
        typeAction: 'PUT',
        typeBucket: 'LOGOS_PUBLIC',
        nom: 'logos/' + path + '/' + fileName,
        metadata: {
          idDocument: documentId,
        },
      },
      { observe: 'response' }
    );
  }

  uploadLogo(url: string, file: File): Observable<any> {
    return this.httpClient.put<any>(url, file);
  }

  getValueForDocLogoDownload(path: string): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.httpClient.post<SignedUrlResponseModel>(
      this.environment.apiUrl + 'uploads/signed-url',
      {
        typeAction: 'GET',
        typeBucket: 'LOGOS_PUBLIC',
        nom: path,
      },
      { observe: 'response' }
    );
  }

  deleteDocLogo(path: string): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.httpClient.post<SignedUrlResponseModel>(
      this.environment.apiUrl + 'uploads/signed-url',
      {
        typeAction: 'DELETE',
        typeBucket: 'LOGOS_PUBLIC',
        nom: 'logos/' + path,
      },
      { observe: 'response' }
    );
  }

  getDocFromGED(documentId: string, isSiteAdmin?: boolean): Observable<HttpResponse<Blob>> {
    const httpHeader = new HttpHeaders({
      Accept: 'application/octet-stream',
    });

    return this.httpClient.get<Blob>(
      `${isSiteAdmin ? this.environment.apiAdminUrl : this.environment.apiUrl}documents/${documentId}/binary`,
      {
        observe: 'response',
        headers: httpHeader,
        responseType: 'blob' as 'json',
      }
    );
  }

  getDocumentCCUsUrl() {
    return `${this.environment.docPublicBaseUrl}${CCU_FILE_NAME}`;
  }

  saveDocument(isSiteAdmin: boolean, document: DocumentProjet) {
    return this.httpClient.post<DocumentProjet>(
      `${isSiteAdmin ? this.environment.apiAdminUrl : this.environment.apiUrl}documents`,
      JSON.stringify(document),
      {
        observe: 'response',
      }
    );
  }

  getValueForDocUpload(
    fileName: string,
    path: string,
    typeUpload: string,
    documentId: string,
    projetId: string,
    typeDocument?: string
  ): Observable<HttpResponse<SignedUrlResponseModel>> {
    const typeBucket = DocumentHelper.getDocumentBucketType(typeUpload);
    return this.httpClient.post<SignedUrlResponseModel>(
      this.environment.apiUrl + 'uploads/signed-url',
      {
        typeAction: 'PUT',
        typeBucket: typeBucket,
        nom: path + '/' + fileName,
        metadata: {
          type: typeUpload,
          idProjet: projetId,
          idDocument: documentId,
          typeDocument: typeDocument ? typeDocument : '',
        },
      },
      { observe: 'response' }
    );
  }

  uploadDoc(
    url: string,
    file: File,
    projetId: string,
    documentId: string,
    typeUpload: string,
    typeDocument?: string
  ): Observable<HttpResponse<any>> {
    const httpHeader = new HttpHeaders({
      'x-amz-meta-type': typeUpload,
      'x-amz-meta-idProjet': projetId,
      'x-amz-meta-idDocument': documentId,
      'x-amz-meta-typeDocument': typeDocument ? typeDocument : '',
    });

    return this.httpClient.put<any>(url, file, { observe: 'response', headers: httpHeader });
  }

  deleteDocument(isSiteAdmin: boolean, idProjet: string, idDocument: string): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append('projetId', idProjet);

    return this.httpClient.delete(`${isSiteAdmin ? this.environment.apiAdminUrl : this.environment.apiUrl}documents/${idDocument}`, {
      params: params,
      observe: 'response',
    });
  }
}
