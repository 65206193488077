import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-candidat/environment';
import { EnumUserGroup, Evaluateur, NpsCreation, ShowToastrService, Utilisateur } from '@shared-ui';
import { BehaviorSubject, Observable } from 'rxjs';
import { PxlOidcService } from './pxl-oidc.service';

@Injectable()
export class UserService {
  constructor(private httpClient: HttpClient, private showToastrService: ShowToastrService, private oidcSecurityService: PxlOidcService) {}

  private userSubject = new BehaviorSubject(null);
  private userSubjectForCGU = new BehaviorSubject(null);

  public setUserObservable(user: any): void {
    this.userSubject.next(user);
  }

  public getUserObservable(): Observable<Utilisateur> {
    return this.userSubject.asObservable();
  }

  public setUserObservableForCGU(user: any): void {
    this.userSubjectForCGU.next(user);
  }

  public getUserObservableForCGU(): Observable<any> {
    return this.userSubjectForCGU.asObservable();
  }

  getUserInfos(): Observable<HttpResponse<Utilisateur>> {
    return this.httpClient.get<Utilisateur>(environment.apiUrl + 'utilisateurs', { observe: 'response' });
  }

  public putCguUser(): Observable<object> {
    return this.httpClient.post<any>(environment.apiUrl + 'utilisateurs/cgu', { observe: 'response' });
  }

  getUtilisateur(): Observable<Utilisateur> {
    this.getUserInfos().subscribe({
      next: response => {
        const finalUserObject = response.body;
        this.oidcSecurityService.getUserData().subscribe(userData => {
          if (userData) {
            finalUserObject.firstName = userData.firstName;
            finalUserObject.lastName = userData.lastName;
          }
          this.setUserObservable(finalUserObject);
          this.setUserObservableForCGU(finalUserObject);
        });
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
    return this.getUserObservable();
  }

  updateEvaluateur(invitId: string, evaluateur: Evaluateur): Observable<HttpResponse<any>> {
    return this.httpClient.put<HttpResponse<any>>(environment.apiUrl + 'evaluateurs?invitation_id=' + invitId, JSON.stringify(evaluateur), {
      observe: 'response',
    });
  }

  createNps(npsCreation: NpsCreation): Observable<HttpResponse<Utilisateur>> {
    return this.httpClient.post<Utilisateur>(environment.apiUrl + 'nps', JSON.stringify(npsCreation), {
      observe: 'response',
    });
  }

  isUserInterne(): boolean {
    const user = this.userSubject.getValue() as Utilisateur;
    return (
      user.groupe === EnumUserGroup[EnumUserGroup.PXL_USERINNO_GROUPE] ||
      user.groupe === EnumUserGroup[EnumUserGroup.PXL_USER_GROUPE] ||
      user.groupe === EnumUserGroup[EnumUserGroup.PXL_ADMIN_GROUPE] ||
      user.groupe === EnumUserGroup[EnumUserGroup.PXL_USERDAR_GROUPE]
    );
  }
}
