import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EvaluateurService } from '@services-candidat/evaluateur.service';
import { ProjetService } from '@services-candidat/projet.service';
import { UserService } from '@services-candidat/user.service';
import { URL_CGU } from '@shared-candidat/utils/constants';
import { EnumRolePartenaire, ShowToastrService, Utilisateur } from '@shared-ui';
import { Subscription } from 'rxjs';

declare const dsLib: any;

@Component({
  selector: 'pxl-projet-rejoindre-partenaire',
  templateUrl: './projet-rejoindre-partenaire.component.html',
  styleUrls: ['./projet-rejoindre-partenaire.component.scss'],
})
export class ProjetRejoindrePartenaireComponent implements OnInit, OnDestroy {
  canCreateEval = false;
  formSubmitted = false;

  partenaireForm: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  listOrganisme = [];
  user: Utilisateur;
  instructeurTelephone = {};

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public projetService: ProjetService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private evaluateurService: EvaluateurService,
    private showToastrService: ShowToastrService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.userService.getUserObservable().subscribe(user => {
        if (user) {
          this.user = user;
          this.evaluateurService.getEvaluateur(user.email).subscribe({
            next: () => {
              this.router.navigate(['/espace']);
            },
            error: (err: HttpErrorResponse) => {
              if (err.status === 404) {
                const mail = user.email.split('@').pop();
                this.evaluateurService.checkRadicalMail(mail).subscribe(response => {
                  if (response.body?.length > 0) {
                    this.listOrganisme = response.body;
                    this.createForm();
                    this.canCreateEval = true;
                  } else {
                    this.router.navigate(['/espace']);
                  }
                });
              } else {
                this.router.navigate(['/espace']);
              }
            },
          });
        }
      })
    );
  }

  createForm(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.instructeurTelephone = new dsLib.InputPhone('instructeurTelephone');

    this.partenaireForm = this.formBuilder.group({
      instructeurNom: [null, Validators.required],
      instructeurPrenom: [null, Validators.required],
      instructeurTelephone: [null, Validators.required],
      email: [this.user.email],
      organismeId: [null, Validators.required],
      siret: [null],
      instructeurRole: [null, Validators.required],
    });

    this.partenaireForm.controls['email'].disable();
    this.partenaireForm.controls['siret'].disable();

    const siret = this.partenaireForm.get('siret');
    this.partenaireForm.get('organismeId').valueChanges.subscribe(id => {
      siret.setValue(this.listOrganisme.filter(organisme => organisme.id === id)[0].siret);

      siret.updateValueAndValidity();
    });

    if (this.listOrganisme.length === 1) {
      this.partenaireForm.patchValue({
        organismeId: this.listOrganisme[0].id,
      });
      this.partenaireForm.controls['organismeId'].disable();
    }
  }

  submitFormPartenaire(formPartenaire?: any): void {
    if (this.partenaireForm.touched && this.partenaireForm.valid) {
      this.evaluateurService.demandeInscription(formPartenaire).subscribe({
        next: response => {
          if (response) {
            this.formSubmitted = true;
          }
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    } else {
      this.partenaireForm.markAllAsTouched();
    }
  }

  onBlur(): void {
    const inputPhone = document.getElementById('input-instructeurTelephone');
    if (inputPhone.classList.contains('ds-input-group--error')) {
      this.partenaireForm.controls.instructeurTelephone.setErrors({ incorrect: true });
    }
  }

  getRgpdFromCgu(): void {
    window.open(URL_CGU + '#rgpd');
  }

  onGoToDashboard(): void {
    this.router.navigate(['/espace']);
  }

  getOrganismeName(): string {
    return this.listOrganisme.filter(organisme => {
      return organisme.id === this.partenaireForm.controls['organismeId'].value;
    })[0]?.nom;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }

  protected readonly EnumRolePartenaire = EnumRolePartenaire;
}
