import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-demande-rectification-modal',
  templateUrl: './demande-rectification-modal.component.html',
  styleUrls: ['./demande-rectification-modal.component.scss'],
})
export class DemandeRectificationModalComponent implements OnInit {
  demandeRectificationForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<DemandeRectificationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly modalData: any,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    const commentaire =
      this.modalData.demandeRectification?.get('demandeTraitee')?.value === true
        ? ''
        : this.modalData.demandeRectification?.get('commentaire')?.value;
    this.demandeRectificationForm = this.formBuilder.group({
      commentaire: [commentaire, Validators.required],
    });
    this.dialogRef.disableClose = true;
  }

  cancelModal(): void {
    if (this.demandeRectificationForm.touched) {
      const cn = confirm('Les modifications apportées ne seront peut-être pas sauvegardées. Etes-vous sûr de vouloir quitter cette page?');
      if (cn) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }

  submitDemandeRectification(): void {
    if (this.demandeRectificationForm.valid) {
      this.dialogRef.close(this.demandeRectificationForm.value);
    } else {
      this.demandeRectificationForm.markAllAsTouched();
    }
  }
}
